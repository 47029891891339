import React, { useState, useEffect } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { Row, Col, Alert, Button } from 'reactstrap';
import { toast } from 'react-toastify';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handleRegister, reset } from './register.reducer';
import { AsfaleiaLogo } from 'app/assets/svg-icon/logo';

export const RegisterPage = () => {
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const handleValidSubmit = ({ username, email, firstPassword }) => {
    dispatch(handleRegister({ login: username, email, password: firstPassword, langKey: currentLocale }));
  };

  const updatePassword = event => setPassword(event.target.value);

  const successMessage = useAppSelector(state => state.register.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  return (
    <Row>
      <Col md={3} sm={12} className="login-sidebar">
        <AsfaleiaLogo />
      </Col>
      <Col md={9} sm={12}>
        <div className="account-form-ui">
          <div className="inner-account-form-wrap">
            <ValidatedForm id="register-form" onSubmit={handleValidSubmit}>
              <div className="form-title">
                <h2>
                  <Translate contentKey="register.title">Registration</Translate>
                </h2>
              </div>
              <ValidatedField
                name="username"
                label={translate('global.form.username.label')}
                placeholder={translate('global.form.username.placeholder')}
                validate={{
                  required: { value: true, message: translate('register.messages.validate.login.required') },
                  pattern: {
                    value: /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/,
                    message: translate('register.messages.validate.login.pattern'),
                  },
                  minLength: { value: 1, message: translate('register.messages.validate.login.minlength') },
                  maxLength: { value: 50, message: translate('register.messages.validate.login.maxlength') },
                }}
                data-cy="username"
              />
              <ValidatedField
                name="email"
                label={translate('global.form.email.label')}
                placeholder={translate('global.form.email.placeholder')}
                type="email"
                validate={{
                  required: { value: true, message: translate('global.messages.validate.email.required') },
                  minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
                  maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
                  validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
                }}
                data-cy="email"
              />
              <ValidatedField
                name="firstPassword"
                label={translate('global.form.newpassword.label')}
                placeholder={translate('global.form.newpassword.placeholder')}
                type="password"
                onChange={updatePassword}
                validate={{
                  required: { value: true, message: translate('global.messages.validate.newpassword.required') },
                  minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
                  maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
                }}
                data-cy="firstPassword"
              />
              <PasswordStrengthBar password={password} />
              <ValidatedField
                name="secondPassword"
                label={translate('global.form.confirmpassword.label')}
                placeholder={translate('global.form.confirmpassword.placeholder')}
                type="password"
                validate={{
                  required: { value: true, message: translate('global.messages.validate.confirmpassword.required') },
                  minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
                  maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
                  validate: v => v === password || translate('global.messages.error.dontmatch'),
                }}
                data-cy="secondPassword"
              />
              <Button id="register-submit" color="primary" type="submit" data-cy="submit">
                <Translate contentKey="register.form.button">Register</Translate>
              </Button>
            </ValidatedForm>
            <Col md={12} className="bottomContent">
              <span>{/* <Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate> */}</span>
              Already Registered User?
              <a href="/login" className="alert-link">
                {/* <Translate contentKey="global.messages.info.authenticated.link"> sign in</Translate> */}
                Click here to login
              </a>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default RegisterPage;
