import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './data-queue.reducer';

export const DataQueueDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const dataQueueEntity = useAppSelector(state => state.dataQueue.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="dataQueueDetailsHeading">
          <Translate contentKey="asfaleiaApp.dataQueue.detail.title">DataQueue</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{dataQueueEntity.id}</dd>
          <dt>
            <span id="contentType">
              <Translate contentKey="asfaleiaApp.dataQueue.contentType">Content Type</Translate>
            </span>
          </dt>
          <dd>{dataQueueEntity.contentType}</dd>
          <dt>
            <span id="dataToLoad">
              <Translate contentKey="asfaleiaApp.dataQueue.dataToLoad">Data To Load</Translate>
            </span>
          </dt>
          <dd>{dataQueueEntity.dataToLoad}</dd>
          <dt>
            <span id="className">
              <Translate contentKey="asfaleiaApp.dataQueue.className">Class Name</Translate>
            </span>
          </dt>
          <dd>{dataQueueEntity.className}</dd>
          <dt>
            <span id="numberOfRowsToRead">
              <Translate contentKey="asfaleiaApp.dataQueue.numberOfRowsToRead">Number Of Rows To Read</Translate>
            </span>
          </dt>
          <dd>{dataQueueEntity.numberOfRowsToRead}</dd>
          <dt>
            <span id="isProcessed">
              <Translate contentKey="asfaleiaApp.dataQueue.isProcessed">Is Processed</Translate>
            </span>
          </dt>
          <dd>{dataQueueEntity.isProcessed ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/data-queue" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/data-queue/${dataQueueEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DataQueueDetail;
