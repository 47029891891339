import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ISmsData } from 'app/shared/model/sms-data.model';
import { ICoachingPerformance } from 'app/shared/model/coaching-performance.model';

const initialState = {
  loading: false,
  errorMessage: null,
  smsData: null, // Store SMS data here
  coachingPerformance: null, // Store coaching performance data here
  entityAuditChanges: null, // Store entity audit changes data here
  updateSuccess: undefined,
};

type dotNumberParams = {
  dotNumber?: string | number;
  dotPin?: string | number;
};

// Actions

export const UpdateSmsData = createAsyncThunk(
  'carrier/create_entity',
  async ({ dotNumber, dotPin }: dotNumberParams) => {
    const requestUrl = `api/smsdata?dotNumber=${dotNumber}&dotPin=${dotPin}`;
    return axios.post<ISmsData>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getCoachingPerformanceForCarrier = createAsyncThunk(
  'coaching-performance/fetch_entity',
  async (dotNumber: string | number) => {
    const requestUrl = `/api/coaching-performance/${dotNumber}`;
    return axios.get<ICoachingPerformance>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityAuditChanges = createAsyncThunk(
  'entity-audit/fetch_changes',
  async (data: any) => {
    const requestUrl = `/api/audits/entity/changes?entityType=${data.entityType}&limit=2&entityId=${data.entityId}`;
    return axios.get<ICoachingPerformance>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Slice

const customReducerSlice = createSlice({
  name: 'customReducer',
  initialState,
  reducers: {
    reset(state) {
      console.log('Reset states customReducerSlice');
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(UpdateSmsData.fulfilled, (state, action) => {
        state.loading = false;
        state.smsData = action.payload.data; // Store SMS data
        state.updateSuccess = true;
      })
      .addCase(getCoachingPerformanceForCarrier.fulfilled, (state, action) => {
        state.loading = false;
        state.coachingPerformance = action.payload.data; // Store coaching performance data
        state.updateSuccess = true;
      })
      .addCase(getEntityAuditChanges.fulfilled, (state, action) => {
        state.loading = false;
        state.entityAuditChanges = action.payload.data; // Store entity audit changes data
        state.updateSuccess = true;
      })
      .addMatcher(
        action => [UpdateSmsData.pending, getCoachingPerformanceForCarrier.pending, getEntityAuditChanges.pending].includes(action.type),
        state => {
          state.errorMessage = null;
          state.loading = true;
        }
      )
      .addMatcher(
        action => [UpdateSmsData.rejected, getCoachingPerformanceForCarrier.rejected].includes(action.type),
        (state, action) => {
          state.loading = false;
          // state.updating = false;
          state.errorMessage = action.error.message;
        }
      );
  },
});

export const { reset } = customReducerSlice.actions;

export default customReducerSlice.reducer;
