import dayjs from 'dayjs';
import { ICrash } from 'app/shared/model/crash.model';
import { IInspection } from 'app/shared/model/inspection.model';

export interface IVehicle {
  id?: number;
  unit?: string | null;
  vehicleIdNumber?: string | null;
  unitType?: string | null;
  licenseState?: string | null;
  licenseNumber?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  crashes?: ICrash[] | null;
  inspections?: IInspection[] | null;
}

export const defaultValue: Readonly<IVehicle> = {};
