import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SmsData from './sms-data';
import SmsDataDetail from './sms-data-detail';
import SmsDataUpdate from './sms-data-update';
import SmsDataDeleteDialog from './sms-data-delete-dialog';

const SmsDataRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SmsData />} />
    <Route path="new" element={<SmsDataUpdate />} />
    <Route path=":id">
      <Route index element={<SmsDataDetail />} />
      <Route path="edit" element={<SmsDataUpdate />} />
      <Route path="delete" element={<SmsDataDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SmsDataRoutes;
