import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISmsData } from 'app/shared/model/sms-data.model';
import { getEntities } from './sms-data.reducer';

export const SmsData = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const smsDataList = useAppSelector(state => state.smsData.entities);
  const loading = useAppSelector(state => state.smsData.loading);
  const totalItems = useAppSelector(state => state.smsData.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="sms-data-heading" data-cy="SmsDataHeading">
        <Translate contentKey="asfaleiaApp.smsData.home.title">Sms Data</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="asfaleiaApp.smsData.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/sms-data/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="asfaleiaApp.smsData.home.createLabel">Create new Sms Data</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {smsDataList && smsDataList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="asfaleiaApp.smsData.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('driverInspTotal')}>
                  <Translate contentKey="asfaleiaApp.smsData.driverInspTotal">Driver Insp Total</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dotNumber')}>
                  <Translate contentKey="asfaleiaApp.smsData.dotNumber">Dot Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehMaintAc')}>
                  <Translate contentKey="asfaleiaApp.smsData.vehMaintAc">Veh Maint Ac</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('drivFitAc')}>
                  <Translate contentKey="asfaleiaApp.smsData.drivFitAc">Driv Fit Ac</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hosDrivMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.hosDrivMeasure">Hos Driv Measure</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('insurancePercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.insurancePercentile">Insurance Percentile</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unsafeDrivInspWViol')}>
                  <Translate contentKey="asfaleiaApp.smsData.unsafeDrivInspWViol">Unsafe Driv Insp W Viol</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unsafeDrivAc')}>
                  <Translate contentKey="asfaleiaApp.smsData.unsafeDrivAc">Unsafe Driv Ac</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehMaintPercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.vehMaintPercentile">Veh Maint Percentile</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('inspTotal')}>
                  <Translate contentKey="asfaleiaApp.smsData.inspTotal">Insp Total</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hmcMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.hmcMeasure">Hmc Measure</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unsafeDrivPercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.unsafeDrivPercentile">Unsafe Driv Percentile</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('drivFitPercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.drivFitPercentile">Driv Fit Percentile</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('crashIndictorMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.crashIndictorMeasure">Crash Indictor Measure</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hosDrivPercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.hosDrivPercentile">Hos Driv Percentile</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hosDrivInspWViol')}>
                  <Translate contentKey="asfaleiaApp.smsData.hosDrivInspWViol">Hos Driv Insp W Viol</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('driverOosInspTotal')}>
                  <Translate contentKey="asfaleiaApp.smsData.driverOosInspTotal">Driver Oos Insp Total</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleInspTotal')}>
                  <Translate contentKey="asfaleiaApp.smsData.vehicleInspTotal">Vehicle Insp Total</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('contrSubstPercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.contrSubstPercentile">Contr Subst Percentile</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('drivFitInspWViol')}>
                  <Translate contentKey="asfaleiaApp.smsData.drivFitInspWViol">Driv Fit Insp W Viol</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unsafeDrivMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.unsafeDrivMeasure">Unsafe Driv Measure</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('insuranceMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.insuranceMeasure">Insurance Measure</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('drivFitMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.drivFitMeasure">Driv Fit Measure</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehMaintInspWViol')}>
                  <Translate contentKey="asfaleiaApp.smsData.vehMaintInspWViol">Veh Maint Insp W Viol</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleOosInspTotal')}>
                  <Translate contentKey="asfaleiaApp.smsData.vehicleOosInspTotal">Vehicle Oos Insp Total</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('contrSubstMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.contrSubstMeasure">Contr Subst Measure</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hmcPercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.hmcPercentile">Hmc Percentile</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('contrSubstSv')}>
                  <Translate contentKey="asfaleiaApp.smsData.contrSubstSv">Contr Subst Sv</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('contrInspWViol')}>
                  <Translate contentKey="asfaleiaApp.smsData.contrInspWViol">Contr Insp W Viol</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehMaintMeasure')}>
                  <Translate contentKey="asfaleiaApp.smsData.vehMaintMeasure">Veh Maint Measure</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('issScore')}>
                  <Translate contentKey="asfaleiaApp.smsData.issScore">Iss Score</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('crashIndictorPercentile')}>
                  <Translate contentKey="asfaleiaApp.smsData.crashIndictorPercentile">Crash Indictor Percentile</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hosComplianceAc')}>
                  <Translate contentKey="asfaleiaApp.smsData.hosComplianceAc">Hos Compliance Ac</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {smsDataList.map((smsData, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/sms-data/${smsData.id}`} color="link" size="sm">
                      {smsData.id}
                    </Button>
                  </td>
                  <td>{smsData.driverInspTotal}</td>
                  <td>{smsData.dotNumber}</td>
                  <td>{smsData.vehMaintAc}</td>
                  <td>{smsData.drivFitAc}</td>
                  <td>{smsData.hosDrivMeasure}</td>
                  <td>{smsData.insurancePercentile}</td>
                  <td>{smsData.unsafeDrivInspWViol}</td>
                  <td>{smsData.unsafeDrivAc}</td>
                  <td>{smsData.vehMaintPercentile}</td>
                  <td>{smsData.inspTotal}</td>
                  <td>{smsData.hmcMeasure}</td>
                  <td>{smsData.unsafeDrivPercentile}</td>
                  <td>{smsData.drivFitPercentile}</td>
                  <td>{smsData.crashIndictorMeasure}</td>
                  <td>{smsData.hosDrivPercentile}</td>
                  <td>{smsData.hosDrivInspWViol}</td>
                  <td>{smsData.driverOosInspTotal}</td>
                  <td>{smsData.vehicleInspTotal}</td>
                  <td>{smsData.contrSubstPercentile}</td>
                  <td>{smsData.drivFitInspWViol}</td>
                  <td>{smsData.unsafeDrivMeasure}</td>
                  <td>{smsData.insuranceMeasure}</td>
                  <td>{smsData.drivFitMeasure}</td>
                  <td>{smsData.vehMaintInspWViol}</td>
                  <td>{smsData.vehicleOosInspTotal}</td>
                  <td>{smsData.contrSubstMeasure}</td>
                  <td>{smsData.hmcPercentile}</td>
                  <td>{smsData.contrSubstSv}</td>
                  <td>{smsData.contrInspWViol}</td>
                  <td>{smsData.vehMaintMeasure}</td>
                  <td>{smsData.issScore}</td>
                  <td>{smsData.crashIndictorPercentile}</td>
                  <td>{smsData.hosComplianceAc}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/sms-data/${smsData.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/sms-data/${smsData.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/sms-data/${smsData.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="asfaleiaApp.smsData.home.notFound">No Sms Data found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={smsDataList && smsDataList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SmsData;
