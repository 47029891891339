import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IInspection } from 'app/shared/model/inspection.model';
import { getEntities } from './inspection.reducer';

export const Inspection = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const inspectionList = useAppSelector(state => state.inspection.entities);
  const loading = useAppSelector(state => state.inspection.loading);
  const totalItems = useAppSelector(state => state.inspection.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="inspection-heading" data-cy="InspectionHeading">
        <Translate contentKey="asfaleiaApp.inspection.home.title">Inspections</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="asfaleiaApp.inspection.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/inspection/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="asfaleiaApp.inspection.home.createLabel">Create new Inspection</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {inspectionList && inspectionList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="asfaleiaApp.inspection.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('drFitnessViol')}>
                  <Translate contentKey="asfaleiaApp.inspection.drFitnessViol">Dr Fitness Viol</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('timeWeight')}>
                  <Translate contentKey="asfaleiaApp.inspection.timeWeight">Time Weight</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitMake')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitMake">Unit Make</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hmViol')}>
                  <Translate contentKey="asfaleiaApp.inspection.hmViol">Hm Viol</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('uniqueId')}>
                  <Translate contentKey="asfaleiaApp.inspection.uniqueId">Unique Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hmInsp')}>
                  <Translate contentKey="asfaleiaApp.inspection.hmInsp">Hm Insp</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fatiguedViol')}>
                  <Translate contentKey="asfaleiaApp.inspection.fatiguedViol">Fatigued Viol</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('inspLevelId')}>
                  <Translate contentKey="asfaleiaApp.inspection.inspLevelId">Insp Level Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dotNumber')}>
                  <Translate contentKey="asfaleiaApp.inspection.dotNumber">Dot Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('oosTotal')}>
                  <Translate contentKey="asfaleiaApp.inspection.oosTotal">Oos Total</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('basicViol')}>
                  <Translate contentKey="asfaleiaApp.inspection.basicViol">Basic Viol</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('countyCodeState')}>
                  <Translate contentKey="asfaleiaApp.inspection.countyCodeState">County Code State</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vhMaintViol')}>
                  <Translate contentKey="asfaleiaApp.inspection.vhMaintViol">Vh Maint Viol</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitMake2')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitMake2">Unit Make 2</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('subtAlcoholInsp')}>
                  <Translate contentKey="asfaleiaApp.inspection.subtAlcoholInsp">Subt Alcohol Insp</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('inspDate')}>
                  <Translate contentKey="asfaleiaApp.inspection.inspDate">Insp Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unsafeInsp')}>
                  <Translate contentKey="asfaleiaApp.inspection.unsafeInsp">Unsafe Insp</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitLicense')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitLicense">Unit License</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitDecalNumber')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitDecalNumber">Unit Decal Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('drFitnessInsp')}>
                  <Translate contentKey="asfaleiaApp.inspection.drFitnessInsp">Dr Fitness Insp</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vin')}>
                  <Translate contentKey="asfaleiaApp.inspection.vin">Vin</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vin2')}>
                  <Translate contentKey="asfaleiaApp.inspection.vin2">Vin 2</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitLicenseState2')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitLicenseState2">Unit License State 2</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('subtAlcoholViol')}>
                  <Translate contentKey="asfaleiaApp.inspection.subtAlcoholViol">Subt Alcohol Viol</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reportNumber')}>
                  <Translate contentKey="asfaleiaApp.inspection.reportNumber">Report Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitDecalNumber2')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitDecalNumber2">Unit Decal Number 2</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitTypeDesc2')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitTypeDesc2">Unit Type Desc 2</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('driverOosTotal')}>
                  <Translate contentKey="asfaleiaApp.inspection.driverOosTotal">Driver Oos Total</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('totalHazmatSent')}>
                  <Translate contentKey="asfaleiaApp.inspection.totalHazmatSent">Total Hazmat Sent</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reportState')}>
                  <Translate contentKey="asfaleiaApp.inspection.reportState">Report State</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitLicense2')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitLicense2">Unit License 2</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hazmatOosTotal')}>
                  <Translate contentKey="asfaleiaApp.inspection.hazmatOosTotal">Hazmat Oos Total</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vhMaintInsp')}>
                  <Translate contentKey="asfaleiaApp.inspection.vhMaintInsp">Vh Maint Insp</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hazmatPlacardReq')}>
                  <Translate contentKey="asfaleiaApp.inspection.hazmatPlacardReq">Hazmat Placard Req</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitTypeDesc')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitTypeDesc">Unit Type Desc</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unsafeViol')}>
                  <Translate contentKey="asfaleiaApp.inspection.unsafeViol">Unsafe Viol</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleOosTotal')}>
                  <Translate contentKey="asfaleiaApp.inspection.vehicleOosTotal">Vehicle Oos Total</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unitLicenseState')}>
                  <Translate contentKey="asfaleiaApp.inspection.unitLicenseState">Unit License State</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fatiguedInsp')}>
                  <Translate contentKey="asfaleiaApp.inspection.fatiguedInsp">Fatigued Insp</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="asfaleiaApp.inspection.carrier">Carrier</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {inspectionList.map((inspection, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/inspection/${inspection.id}`} color="link" size="sm">
                      {inspection.id}
                    </Button>
                  </td>
                  <td>{inspection.drFitnessViol}</td>
                  <td>{inspection.timeWeight}</td>
                  <td>{inspection.unitMake}</td>
                  <td>{inspection.hmViol}</td>
                  <td>{inspection.uniqueId}</td>
                  <td>{inspection.hmInsp}</td>
                  <td>{inspection.fatiguedViol}</td>
                  <td>{inspection.inspLevelId}</td>
                  <td>{inspection.dotNumber}</td>
                  <td>{inspection.oosTotal}</td>
                  <td>{inspection.basicViol}</td>
                  <td>{inspection.countyCodeState}</td>
                  <td>{inspection.vhMaintViol}</td>
                  <td>{inspection.unitMake2}</td>
                  <td>{inspection.subtAlcoholInsp}</td>
                  <td>{inspection.inspDate}</td>
                  <td>{inspection.unsafeInsp}</td>
                  <td>{inspection.unitLicense}</td>
                  <td>{inspection.unitDecalNumber}</td>
                  <td>{inspection.drFitnessInsp}</td>
                  <td>{inspection.vin}</td>
                  <td>{inspection.vin2}</td>
                  <td>{inspection.unitLicenseState2}</td>
                  <td>{inspection.subtAlcoholViol}</td>
                  <td>{inspection.reportNumber}</td>
                  <td>{inspection.unitDecalNumber2}</td>
                  <td>{inspection.unitTypeDesc2}</td>
                  <td>{inspection.driverOosTotal}</td>
                  <td>{inspection.totalHazmatSent}</td>
                  <td>{inspection.reportState}</td>
                  <td>{inspection.unitLicense2}</td>
                  <td>{inspection.hazmatOosTotal}</td>
                  <td>{inspection.vhMaintInsp}</td>
                  <td>{inspection.hazmatPlacardReq}</td>
                  <td>{inspection.unitTypeDesc}</td>
                  <td>{inspection.unsafeViol}</td>
                  <td>{inspection.vehicleOosTotal}</td>
                  <td>{inspection.unitLicenseState}</td>
                  <td>{inspection.fatiguedInsp}</td>
                  <td>{inspection.carrier ? <Link to={`/carrier/${inspection.carrier.id}`}>{inspection.carrier.dotNumber}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/inspection/${inspection.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/inspection/${inspection.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/inspection/${inspection.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="asfaleiaApp.inspection.home.notFound">No Inspections found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={inspectionList && inspectionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Inspection;
