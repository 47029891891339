import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Table, Card, CardBody, CardText, Row, Col, CardHeader } from 'reactstrap';
import '../../../assets/theme-scss/popup.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSortState } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntities as getLastUpdated } from '../../../entities/sms-data-update/sms-data-update.reducer';
import { getEntities as getCarrierByDotNumber } from 'app/entities/carrier/carrier.reducer';
import { DESC } from 'app/shared/util/pagination.constants';
import { useParams } from 'react-router-dom';

export const InspectionDescDialog = ({ isCoachingEnabled, inspection, closeInspDescModal }) => {
  const dispatch = useAppDispatch();
  const { dotNumber } = useParams<'dotNumber'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 1, 'lastUpdatedOn', DESC), location.search)
  );
  const smsDataUpdate = useAppSelector(state => state.smsDataUpdate.entities);
  const carrier = useAppSelector(state => state.carrier.entities);

  useEffect(() => {
    dispatch(
      getLastUpdated({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
    dispatch(
      getCarrierByDotNumber({
        query: `dotNumber.equals=${dotNumber}`,
      })
    );
  }, []);

  const handleClose = () => {
    closeInspDescModal(false);
  };

  return (
    <Modal isOpen toggle={handleClose} centered size="xl" className="view-insp">
      <ModalHeader toggle={handleClose}>
        Detailed Inspection Report as of {smsDataUpdate && smsDataUpdate.length == 1 && smsDataUpdate[0].lastUpdatedOn}
      </ModalHeader>
      <ModalBody>
        <Row className="mb-4 header_bg">
          <Col sm="6">
            <Card className="inspection-report">
              <CardHeader>CARRIER INFORMATION</CardHeader>
              <CardBody>
                <CardText>
                  <ul>
                    <li>
                      <b>Carrier Name:</b> <span>{carrier[0]?.legalName}</span>
                    </li>
                    <li>
                      <b>U.S. DOT#:</b> <span>{carrier[0]?.dotNumber}</span>
                    </li>
                    <li>
                      <b>Carrier Address:</b>{' '}
                      <span>
                        {[carrier?.[0]?.phyStreet, carrier?.[0]?.phyCity, carrier?.[0]?.phyState, carrier?.[0]?.phyZip]
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    </li>
                  </ul>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card className="inspection-report">
              <CardHeader>TIME/LOCATION</CardHeader>
              <CardBody>
                <CardText>
                  <ul className="width-gap">
                    <li>
                      <b>Report #:</b> <span>{inspection.reportNumber}</span>
                    </li>
                    <li>
                      <b> Report State: </b>
                      <span>{inspection.reportState}</span>
                    </li>
                    <li>
                      <b> State: </b>
                      <span>{carrier.phyState}</span>
                    </li>
                    <li>
                      <b> Date: </b>
                      <span>{inspection.inspDate}</span>
                    </li>
                    <li>
                      <b> Start Time: </b>
                      <span>{'-'}</span>
                    </li>
                    <li>
                      <b> End Time: </b>
                      <span>{'-'}</span>
                    </li>
                    <li>
                      <b>Level: </b>{' '}
                      <span>
                        {inspection.inspLevelId && inspection.inspLevelId === '1' ? 'I' : inspection.inspLevelId === '2' ? 'II' : 'III'}
                      </span>
                    </li>
                    <li>
                      <b>Facility: </b> <span>{'-'}</span>
                    </li>
                    <li>
                      <b> Post Crash Inspection: </b> <span>{'-'}</span>
                    </li>
                    <li>
                      <b> Hazmat Placard Required: </b> <span>{inspection.hazmatPlacardReq}</span>
                    </li>
                  </ul>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm="12">
            <Card className="inspection-report">
              <CardHeader>VEHICLE INFORMATION</CardHeader>
              <CardBody>
                <CardText>
                  <div className="dashboarContent">
                    <div className="main-dashbord-table-content">
                      <div className="table-responsive radius-0">
                        <Table className="full-table ">
                          <thead className="bg-opacity-10  text-capitalize">
                            <tr>
                              <th style={{ padding: '10px' }}>Unit</th>
                              <th style={{ padding: '10px' }}>Type</th>
                              <th style={{ padding: '10px' }}>Make</th>
                              <th style={{ padding: '10px' }}>Plate State</th>
                              <th style={{ padding: '10px' }}>Plate Number</th>
                              <th style={{ padding: '10px' }}>Vin</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inspection &&
                              inspection.vehicles &&
                              inspection.vehicles.map((v, i) => (
                                <tr key={`vehicle-${i}`}>
                                  <td>{v.unit}</td>
                                  <td>{v.unitType}</td>
                                  <td>{'-'}</td>
                                  <td>{v.licenseState}</td>
                                  <td>{v.licenseNumber}</td>
                                  <td>{v.vehicleIdNumber}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm="12">
            <Card className="inspection-report">
              <CardHeader>CARRIER VIOLATIONS</CardHeader>
              <CardBody>
                <CardText>
                  <div className="dashboarContent">
                    <div className="main-dashbord-table-content">
                      <div className="table-responsive radius-0">
                        <Table className="full-table">
                          <thead className="bg-opacity-10  text-capitalize">
                            <tr>
                              <th style={{ padding: '10px' }}>Viol Code</th>
                              <th style={{ padding: '10px' }}>Section</th>
                              <th style={{ padding: '10px' }}>Unit</th>
                              <th style={{ padding: '10px' }}>Oos</th>
                              <th style={{ padding: '10px', width: '280px' }}>Description</th>
                              <th style={{ padding: '10px' }}>Included In SMS</th>
                              <th style={{ padding: '10px' }}>Basic</th>
                              <th style={{ padding: '10px' }}>Reason Not Included In SMS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inspection &&
                              inspection.violations &&
                              inspection.violations.map((v, i) => (
                                <tr key={`violation-${i}`}>
                                  <td>{v.violCode}</td>
                                  <td>{v.sectionDesc}</td>
                                  <td>{v.violUnit}</td>
                                  <td>{v.oosIndicator && v.oosIndicator === 'Y' ? 'Yes' : 'No'}</td>
                                  <td>{v.groupDesc}</td>
                                  <td>{'-'}</td>
                                  <td>{v.basicDesc}</td>
                                  <td>{'-'}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default InspectionDescDialog;
