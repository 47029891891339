import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './inspection.reducer';

export const InspectionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inspectionEntity = useAppSelector(state => state.inspection.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="inspectionDetailsHeading">
          <Translate contentKey="asfaleiaApp.inspection.detail.title">Inspection</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.id}</dd>
          <dt>
            <span id="drFitnessViol">
              <Translate contentKey="asfaleiaApp.inspection.drFitnessViol">Dr Fitness Viol</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.drFitnessViol}</dd>
          <dt>
            <span id="timeWeight">
              <Translate contentKey="asfaleiaApp.inspection.timeWeight">Time Weight</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.timeWeight}</dd>
          <dt>
            <span id="unitMake">
              <Translate contentKey="asfaleiaApp.inspection.unitMake">Unit Make</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitMake}</dd>
          <dt>
            <span id="hmViol">
              <Translate contentKey="asfaleiaApp.inspection.hmViol">Hm Viol</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.hmViol}</dd>
          <dt>
            <span id="uniqueId">
              <Translate contentKey="asfaleiaApp.inspection.uniqueId">Unique Id</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.uniqueId}</dd>
          <dt>
            <span id="hmInsp">
              <Translate contentKey="asfaleiaApp.inspection.hmInsp">Hm Insp</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.hmInsp}</dd>
          <dt>
            <span id="fatiguedViol">
              <Translate contentKey="asfaleiaApp.inspection.fatiguedViol">Fatigued Viol</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.fatiguedViol}</dd>
          <dt>
            <span id="inspLevelId">
              <Translate contentKey="asfaleiaApp.inspection.inspLevelId">Insp Level Id</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.inspLevelId}</dd>
          <dt>
            <span id="dotNumber">
              <Translate contentKey="asfaleiaApp.inspection.dotNumber">Dot Number</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.dotNumber}</dd>
          <dt>
            <span id="oosTotal">
              <Translate contentKey="asfaleiaApp.inspection.oosTotal">Oos Total</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.oosTotal}</dd>
          <dt>
            <span id="basicViol">
              <Translate contentKey="asfaleiaApp.inspection.basicViol">Basic Viol</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.basicViol}</dd>
          <dt>
            <span id="countyCodeState">
              <Translate contentKey="asfaleiaApp.inspection.countyCodeState">County Code State</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.countyCodeState}</dd>
          <dt>
            <span id="vhMaintViol">
              <Translate contentKey="asfaleiaApp.inspection.vhMaintViol">Vh Maint Viol</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.vhMaintViol}</dd>
          <dt>
            <span id="unitMake2">
              <Translate contentKey="asfaleiaApp.inspection.unitMake2">Unit Make 2</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitMake2}</dd>
          <dt>
            <span id="subtAlcoholInsp">
              <Translate contentKey="asfaleiaApp.inspection.subtAlcoholInsp">Subt Alcohol Insp</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.subtAlcoholInsp}</dd>
          <dt>
            <span id="inspDate">
              <Translate contentKey="asfaleiaApp.inspection.inspDate">Insp Date</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.inspDate}</dd>
          <dt>
            <span id="unsafeInsp">
              <Translate contentKey="asfaleiaApp.inspection.unsafeInsp">Unsafe Insp</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unsafeInsp}</dd>
          <dt>
            <span id="unitLicense">
              <Translate contentKey="asfaleiaApp.inspection.unitLicense">Unit License</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitLicense}</dd>
          <dt>
            <span id="unitDecalNumber">
              <Translate contentKey="asfaleiaApp.inspection.unitDecalNumber">Unit Decal Number</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitDecalNumber}</dd>
          <dt>
            <span id="drFitnessInsp">
              <Translate contentKey="asfaleiaApp.inspection.drFitnessInsp">Dr Fitness Insp</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.drFitnessInsp}</dd>
          <dt>
            <span id="vin">
              <Translate contentKey="asfaleiaApp.inspection.vin">Vin</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.vin}</dd>
          <dt>
            <span id="vin2">
              <Translate contentKey="asfaleiaApp.inspection.vin2">Vin 2</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.vin2}</dd>
          <dt>
            <span id="unitLicenseState2">
              <Translate contentKey="asfaleiaApp.inspection.unitLicenseState2">Unit License State 2</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitLicenseState2}</dd>
          <dt>
            <span id="subtAlcoholViol">
              <Translate contentKey="asfaleiaApp.inspection.subtAlcoholViol">Subt Alcohol Viol</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.subtAlcoholViol}</dd>
          <dt>
            <span id="reportNumber">
              <Translate contentKey="asfaleiaApp.inspection.reportNumber">Report Number</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.reportNumber}</dd>
          <dt>
            <span id="unitDecalNumber2">
              <Translate contentKey="asfaleiaApp.inspection.unitDecalNumber2">Unit Decal Number 2</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitDecalNumber2}</dd>
          <dt>
            <span id="unitTypeDesc2">
              <Translate contentKey="asfaleiaApp.inspection.unitTypeDesc2">Unit Type Desc 2</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitTypeDesc2}</dd>
          <dt>
            <span id="driverOosTotal">
              <Translate contentKey="asfaleiaApp.inspection.driverOosTotal">Driver Oos Total</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.driverOosTotal}</dd>
          <dt>
            <span id="totalHazmatSent">
              <Translate contentKey="asfaleiaApp.inspection.totalHazmatSent">Total Hazmat Sent</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.totalHazmatSent}</dd>
          <dt>
            <span id="reportState">
              <Translate contentKey="asfaleiaApp.inspection.reportState">Report State</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.reportState}</dd>
          <dt>
            <span id="unitLicense2">
              <Translate contentKey="asfaleiaApp.inspection.unitLicense2">Unit License 2</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitLicense2}</dd>
          <dt>
            <span id="hazmatOosTotal">
              <Translate contentKey="asfaleiaApp.inspection.hazmatOosTotal">Hazmat Oos Total</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.hazmatOosTotal}</dd>
          <dt>
            <span id="vhMaintInsp">
              <Translate contentKey="asfaleiaApp.inspection.vhMaintInsp">Vh Maint Insp</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.vhMaintInsp}</dd>
          <dt>
            <span id="hazmatPlacardReq">
              <Translate contentKey="asfaleiaApp.inspection.hazmatPlacardReq">Hazmat Placard Req</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.hazmatPlacardReq}</dd>
          <dt>
            <span id="unitTypeDesc">
              <Translate contentKey="asfaleiaApp.inspection.unitTypeDesc">Unit Type Desc</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitTypeDesc}</dd>
          <dt>
            <span id="unsafeViol">
              <Translate contentKey="asfaleiaApp.inspection.unsafeViol">Unsafe Viol</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unsafeViol}</dd>
          <dt>
            <span id="vehicleOosTotal">
              <Translate contentKey="asfaleiaApp.inspection.vehicleOosTotal">Vehicle Oos Total</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.vehicleOosTotal}</dd>
          <dt>
            <span id="unitLicenseState">
              <Translate contentKey="asfaleiaApp.inspection.unitLicenseState">Unit License State</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.unitLicenseState}</dd>
          <dt>
            <span id="fatiguedInsp">
              <Translate contentKey="asfaleiaApp.inspection.fatiguedInsp">Fatigued Insp</Translate>
            </span>
          </dt>
          <dd>{inspectionEntity.fatiguedInsp}</dd>
          <dt>
            <Translate contentKey="asfaleiaApp.inspection.driver">Driver</Translate>
          </dt>
          <dd>
            {inspectionEntity.drivers
              ? inspectionEntity.drivers.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {inspectionEntity.drivers && i === inspectionEntity.drivers.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="asfaleiaApp.inspection.vehicle">Vehicle</Translate>
          </dt>
          <dd>
            {inspectionEntity.vehicles
              ? inspectionEntity.vehicles.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {inspectionEntity.vehicles && i === inspectionEntity.vehicles.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="asfaleiaApp.inspection.carrier">Carrier</Translate>
          </dt>
          <dd>{inspectionEntity.carrier ? inspectionEntity.carrier.dotNumber : ''}</dd>
        </dl>
        <Button tag={Link} to="/inspection" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/inspection/${inspectionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InspectionDetail;
