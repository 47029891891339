import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/coaching-item">
        <Translate contentKey="global.menu.entities.coachingItem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/carrier">
        <Translate contentKey="global.menu.entities.carrier" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/violation">
        <Translate contentKey="global.menu.entities.violation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/crash">
        <Translate contentKey="global.menu.entities.crash" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/sms-data">
        <Translate contentKey="global.menu.entities.smsData" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/vehicle">
        <Translate contentKey="global.menu.entities.vehicle" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/inspection">
        <Translate contentKey="global.menu.entities.inspection" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/driver">
        <Translate contentKey="global.menu.entities.driver" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-profile">
        <Translate contentKey="global.menu.entities.userProfile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/data-queue">
        <Translate contentKey="global.menu.entities.dataQueue" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/sms-data-update">
        <Translate contentKey="global.menu.entities.smsDataUpdate" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
