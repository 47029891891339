import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './driver.reducer';

export const DriverDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const driverEntity = useAppSelector(state => state.driver.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="driverDetailsHeading">
          <Translate contentKey="asfaleiaApp.driver.detail.title">Driver</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{driverEntity.id}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="asfaleiaApp.driver.state">State</Translate>
            </span>
          </dt>
          <dd>{driverEntity.state}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="asfaleiaApp.driver.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{driverEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="asfaleiaApp.driver.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{driverEntity.lastName}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="asfaleiaApp.driver.email">Email</Translate>
            </span>
          </dt>
          <dd>{driverEntity.email}</dd>
          <dt>
            <span id="dateOfBirth">
              <Translate contentKey="asfaleiaApp.driver.dateOfBirth">Date Of Birth</Translate>
            </span>
          </dt>
          <dd>{driverEntity.dateOfBirth}</dd>
          <dt>
            <span id="issScore">
              <Translate contentKey="asfaleiaApp.driver.issScore">Iss Score</Translate>
            </span>
          </dt>
          <dd>{driverEntity.issScore}</dd>
          <dt>
            <span id="issScoreChange">
              <Translate contentKey="asfaleiaApp.driver.issScoreChange">Iss Score Change</Translate>
            </span>
          </dt>
          <dd>{driverEntity.issScoreChange}</dd>
          <dt>
            <span id="dotNumber">
              <Translate contentKey="asfaleiaApp.driver.dotNumber">Dot Number</Translate>
            </span>
          </dt>
          <dd>{driverEntity.dotNumber}</dd>
          <dt>
            <span id="driverType">
              <Translate contentKey="asfaleiaApp.driver.driverType">Driver Type</Translate>
            </span>
          </dt>
          <dd>{driverEntity.driverType}</dd>
          <dt>
            <span id="licenseState">
              <Translate contentKey="asfaleiaApp.driver.licenseState">License State</Translate>
            </span>
          </dt>
          <dd>{driverEntity.licenseState}</dd>
          <dt>
            <span id="licenseNumber">
              <Translate contentKey="asfaleiaApp.driver.licenseNumber">License Number</Translate>
            </span>
          </dt>
          <dd>{driverEntity.licenseNumber}</dd>
        </dl>
        <Button tag={Link} to="/driver" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/driver/${driverEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DriverDetail;
