import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IInspection } from 'app/shared/model/inspection.model';
import { getEntities as getInspections } from 'app/entities/inspection/inspection.reducer';
import { IViolation } from 'app/shared/model/violation.model';
import { getEntity, updateEntity, createEntity, reset } from './violation.reducer';

export const ViolationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const inspections = useAppSelector(state => state.inspection.entities);
  const violationEntity = useAppSelector(state => state.violation.entity);
  const loading = useAppSelector(state => state.violation.loading);
  const updating = useAppSelector(state => state.violation.updating);
  const updateSuccess = useAppSelector(state => state.violation.updateSuccess);

  const handleClose = () => {
    navigate('/violation' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getInspections({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...violationEntity,
      ...values,
      inspection: inspections.find(it => it.id.toString() === values.inspection.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...violationEntity,
          createdDate: convertDateTimeFromServer(violationEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(violationEntity.lastModifiedDate),
          inspection: violationEntity?.inspection?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="asfaleiaApp.violation.home.createOrEditLabel" data-cy="ViolationCreateUpdateHeading">
            <Translate contentKey="asfaleiaApp.violation.home.createOrEditLabel">Create or edit a Violation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="violation-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('asfaleiaApp.violation.uniqueId')}
                id="violation-uniqueId"
                name="uniqueId"
                data-cy="uniqueId"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.severityWeight')}
                id="violation-severityWeight"
                name="severityWeight"
                data-cy="severityWeight"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.inspDate')}
                id="violation-inspDate"
                name="inspDate"
                data-cy="inspDate"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.dotNumber')}
                id="violation-dotNumber"
                name="dotNumber"
                data-cy="dotNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.violCode')}
                id="violation-violCode"
                name="violCode"
                data-cy="violCode"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.oosIndicator')}
                id="violation-oosIndicator"
                name="oosIndicator"
                data-cy="oosIndicator"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.violUnit')}
                id="violation-violUnit"
                name="violUnit"
                data-cy="violUnit"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.convictedOfDifCharge')}
                id="violation-convictedOfDifCharge"
                name="convictedOfDifCharge"
                data-cy="convictedOfDifCharge"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.oosWeight')}
                id="violation-oosWeight"
                name="oosWeight"
                data-cy="oosWeight"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.timeWeight')}
                id="violation-timeWeight"
                name="timeWeight"
                data-cy="timeWeight"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.sectionDesc')}
                id="violation-sectionDesc"
                name="sectionDesc"
                data-cy="sectionDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.groupDesc')}
                id="violation-groupDesc"
                name="groupDesc"
                data-cy="groupDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.totalSeverityWght')}
                id="violation-totalSeverityWght"
                name="totalSeverityWght"
                data-cy="totalSeverityWght"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.violValue')}
                id="violation-violValue"
                name="violValue"
                data-cy="violValue"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.basicDesc')}
                id="violation-basicDesc"
                name="basicDesc"
                data-cy="basicDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.createdBy')}
                id="violation-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.createdDate')}
                id="violation-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.lastModifiedBy')}
                id="violation-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.violation.lastModifiedDate')}
                id="violation-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="violation-inspection"
                name="inspection"
                data-cy="inspection"
                label={translate('asfaleiaApp.violation.inspection')}
                type="select"
              >
                <option value="" key="0" />
                {inspections
                  ? inspections.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/violation" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ViolationUpdate;
