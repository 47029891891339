import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';

export const FmcsaClearingHouseDialog = ({ closeModal, pdfUrl }) => {
  const handleClose = () => {
    closeModal(false);
  };

  return (
    <Modal isOpen toggle={handleClose} centered size="xl" scrollable>
      <ModalHeader toggle={handleClose}></ModalHeader>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <iframe
          src={pdfUrl ? pdfUrl : '../../../content/pdfs/CSAGRSVisorS.pdf'}
          title="PDF Viewer"
          style={{ width: '100%', height: '70vh' }}
        />
      </div>
    </Modal>
  );
};

export default FmcsaClearingHouseDialog;
