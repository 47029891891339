import './home.scss';

import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert, Button } from 'reactstrap';

import { useAppSelector } from 'app/config/store';
import Login from '../login/login';

export const Home = () => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  if (!!Object.keys(account).length) return <Navigate to="/dashboard" replace={true} />;

  return (
    <Row>
      {account?.login ? (
        <div>
          <Alert color="success">
            <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
              You are logged in as user {account.login}.
            </Translate>
          </Alert>
        </div>
      ) : (
        <div className="Login-page">
          <Login />
        </div>
      )}
      {account?.login && (
        <>
          <Button onClick={() => navigate('dashboard')}>Dashboard</Button>
        </>
      )}
    </Row>
  );
};

export default Home;
