import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './violation.reducer';

export const ViolationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const violationEntity = useAppSelector(state => state.violation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="violationDetailsHeading">
          <Translate contentKey="asfaleiaApp.violation.detail.title">Violation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{violationEntity.id}</dd>
          <dt>
            <span id="uniqueId">
              <Translate contentKey="asfaleiaApp.violation.uniqueId">Unique Id</Translate>
            </span>
          </dt>
          <dd>{violationEntity.uniqueId}</dd>
          <dt>
            <span id="severityWeight">
              <Translate contentKey="asfaleiaApp.violation.severityWeight">Severity Weight</Translate>
            </span>
          </dt>
          <dd>{violationEntity.severityWeight}</dd>
          <dt>
            <span id="inspDate">
              <Translate contentKey="asfaleiaApp.violation.inspDate">Insp Date</Translate>
            </span>
          </dt>
          <dd>{violationEntity.inspDate}</dd>
          <dt>
            <span id="dotNumber">
              <Translate contentKey="asfaleiaApp.violation.dotNumber">Dot Number</Translate>
            </span>
          </dt>
          <dd>{violationEntity.dotNumber}</dd>
          <dt>
            <span id="violCode">
              <Translate contentKey="asfaleiaApp.violation.violCode">Viol Code</Translate>
            </span>
          </dt>
          <dd>{violationEntity.violCode}</dd>
          <dt>
            <span id="oosIndicator">
              <Translate contentKey="asfaleiaApp.violation.oosIndicator">Oos Indicator</Translate>
            </span>
          </dt>
          <dd>{violationEntity.oosIndicator}</dd>
          <dt>
            <span id="violUnit">
              <Translate contentKey="asfaleiaApp.violation.violUnit">Viol Unit</Translate>
            </span>
          </dt>
          <dd>{violationEntity.violUnit}</dd>
          <dt>
            <span id="convictedOfDifCharge">
              <Translate contentKey="asfaleiaApp.violation.convictedOfDifCharge">Convicted Of Dif Charge</Translate>
            </span>
          </dt>
          <dd>{violationEntity.convictedOfDifCharge}</dd>
          <dt>
            <span id="oosWeight">
              <Translate contentKey="asfaleiaApp.violation.oosWeight">Oos Weight</Translate>
            </span>
          </dt>
          <dd>{violationEntity.oosWeight}</dd>
          <dt>
            <span id="timeWeight">
              <Translate contentKey="asfaleiaApp.violation.timeWeight">Time Weight</Translate>
            </span>
          </dt>
          <dd>{violationEntity.timeWeight}</dd>
          <dt>
            <span id="sectionDesc">
              <Translate contentKey="asfaleiaApp.violation.sectionDesc">Section Desc</Translate>
            </span>
          </dt>
          <dd>{violationEntity.sectionDesc}</dd>
          <dt>
            <span id="groupDesc">
              <Translate contentKey="asfaleiaApp.violation.groupDesc">Group Desc</Translate>
            </span>
          </dt>
          <dd>{violationEntity.groupDesc}</dd>
          <dt>
            <span id="totalSeverityWght">
              <Translate contentKey="asfaleiaApp.violation.totalSeverityWght">Total Severity Wght</Translate>
            </span>
          </dt>
          <dd>{violationEntity.totalSeverityWght}</dd>
          <dt>
            <span id="violValue">
              <Translate contentKey="asfaleiaApp.violation.violValue">Viol Value</Translate>
            </span>
          </dt>
          <dd>{violationEntity.violValue}</dd>
          <dt>
            <span id="basicDesc">
              <Translate contentKey="asfaleiaApp.violation.basicDesc">Basic Desc</Translate>
            </span>
          </dt>
          <dd>{violationEntity.basicDesc}</dd>
          <dt>
            <Translate contentKey="asfaleiaApp.violation.inspection">Inspection</Translate>
          </dt>
          <dd>{violationEntity.inspection ? violationEntity.inspection.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/violation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/violation/${violationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ViolationDetail;
