import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../../../assets/theme-scss/popup.scss';
import { ContSubstances, CrasIndicator, UnsafeDrivin, HazardMaterials, Driver, UnsafeDriving1, VehicMaintenance } from 'app/config/Icons';
import ViewReportGraph from '../ViewReportGraph';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSortState } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntities as getViolations } from 'app/entities/violation/violation.reducer';
import { DESC } from 'app/shared/util/pagination.constants';

export const ViewReportDialog = ({ isCoachingEnabled, closeViewReportModal, item, dotNumber, smsDataUpdate, smsData }) => {
  const dispatch = useAppDispatch();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 1, 'inspection.inspDate', DESC), location.search)
  );
  const violations = useAppSelector(state => state.violation.entities);

  useEffect(() => {
    dispatch(
      getViolations({
        // page: paginationState.activePage - 1,
        // size: 10,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `dotNumber.equals=${dotNumber}&basicDesc.contains=${item.title && item.title.substring(0, item.title.indexOf(' '))}`,
      })
    );
  }, []);

  const handleClose = () => {
    closeViewReportModal(false);
  };

  const getIcon = title => {
    if (title === 'Unsafe Driving') return <UnsafeDrivin />;
    else if (title === 'HOS Compliance') return <UnsafeDriving1 />;
    else if (title === 'Driver Fitness') return <Driver />;
    else if (title === 'Cont. Substances') return <ContSubstances />;
    else if (title === 'Vehicle Maintenance') return <VehicMaintenance />;
    else if (title === 'Hazardous Materials') return <HazardMaterials />;
    else if (title === 'Crash Indicator') return <CrasIndicator />;
    return null;
  };

  return (
    <Modal isOpen toggle={handleClose} centered size="lg" className="view-report">
      <ModalHeader toggle={handleClose}>
        {getIcon(item.title)}
        {item.title} Violations
      </ModalHeader>
      <ModalBody>
        <ViewReportGraph violationType={item.title} isCoachingEnabled={isCoachingEnabled} type="CARRIER" violations={violations} />
      </ModalBody>
    </Modal>
  );
};

export default ViewReportDialog;
