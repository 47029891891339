import dayjs from 'dayjs';
import { IInspection } from 'app/shared/model/inspection.model';

export interface IViolation {
  id?: number;
  severityWeight?: string | null;
  uniqueId?: string | null;
  inspDate?: string | null;
  dotNumber?: string | null;
  basicDesc?: string | null;
  totalSeverityWght?: string | null;
  violValue?: string | null;
  violCode?: string | null;
  oosIndicator?: string | null;
  groupDesc?: string | null;
  violUnit?: string | null;
  convictedOfDifCharge?: string | null;
  oosWeight?: string | null;
  timeWeight?: string | null;
  sectionDesc?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  inspection?: IInspection | null;
}

export const defaultValue: Readonly<IViolation> = {};
