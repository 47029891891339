import dayjs from 'dayjs';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IInspection } from 'app/shared/model/inspection.model';
import { ICrash } from 'app/shared/model/crash.model';

export interface ICarrier {
  id?: number;
  dbaName?: string | null;
  legalName?: string | null;
  mailingZip?: string | null;
  dotNumber?: string | null;
  privateOnly?: string | null;
  logo?: string | null;
  phyCountry?: string | null;
  telephone?: string | null;
  recentMileage?: string | null;
  mailingCity?: string | null;
  mailingState?: string | null;
  opOther?: string | null;
  recentMileageYear?: string | null;
  phyCity?: string | null;
  mcs150MileageYear?: string | null;
  fax?: string | null;
  federalGovernment?: string | null;
  oicState?: string | null;
  dotPin?: string | null;
  usMail?: string | null;
  privatePassengerBusiness?: string | null;
  migrant?: string | null;
  exemptForHire?: string | null;
  mailingStreet?: string | null;
  nbrPowerUnit?: string | null;
  stateGovernment?: string | null;
  mcs150Mileage?: string | null;
  mcs150Date?: string | null;
  addDate?: string | null;
  indianTribe?: string | null;
  privateProperty?: string | null;
  carrierOperation?: string | null;
  privatePassengerNonbusiness?: string | null;
  phyZip?: string | null;
  mailingCountry?: string | null;
  phyState?: string | null;
  driverTotal?: string | null;
  phyStreet?: string | null;
  hmFlag?: string | null;
  pcFlag?: string | null;
  localGovernment?: string | null;
  vmtSourceId?: string | null;
  authorizedForHire?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  userProfiles?: IUserProfile[] | null;
  inspections?: IInspection[] | null;
  crashes?: ICrash[] | null;
}

export const defaultValue: Readonly<ICarrier> = {};
