import dayjs from 'dayjs';
import { ICrash } from 'app/shared/model/crash.model';
import { IInspection } from 'app/shared/model/inspection.model';

export interface IDriver {
  id?: number;
  firstName?: string | null;
  state?: string | null;
  lastName?: string | null;
  email?: string | null;
  dateOfBirth?: string | null;
  issScore?: number | null;
  issScoreChange?: number | null;
  dotNumber?: string | null;
  driverType?: string | null;
  profileImage?: string | null;
  licenseState?: string | null;
  licenseNumber?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  crashes?: ICrash[] | null;
  inspections?: IInspection[] | null;
}

export const defaultValue: Readonly<IDriver> = {};
