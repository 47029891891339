import React, { useEffect, useState } from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as fetchUserProfile } from 'app/entities/user-profile/user-profile.reducer';
import Dashboard from './Dashboard';
import DashboardForm from './DashboardForm';
import { Spinner } from 'reactstrap';
import InspectionList from '../inspections/InspectionList';

const DashboardRoutes = () => {
  const loggedInUser = useAppSelector(state => state.authentication.account);
  const userProfile = useAppSelector(state => state.userProfile);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [initial, setIntial] = useState(true);

  useEffect(() => {
    (async () => {
      if (loggedInUser.authorities.every(item => item === 'ROLE_USER')) {
        if (!userProfile.entities.length) {
          dispatch(fetchUserProfile({ query: `internalUserId.equals=${loggedInUser.id}` }));
        }
      }
    })();
  }, []);

  useEffect(() => {
    !!userProfile.loading && setIntial(false);
    let paths: string[] = pathname.split('/');
    let id = paths[paths.length - 1];
    if (!Number(id) && !id.includes('inspection') && loggedInUser.authorities.includes('ROLE_ADMIN')) {
      navigate('/dashboard/form');
    } else {
      if (userProfile && userProfile.entities && userProfile.entities.length > 0) {
        navigate(`/dashboard/${userProfile.entities[0].dotNumber}`);
      } else {
        if (initial && !userProfile.entities.length) return;
        navigate('/dashboard/form');
      }
    }
  }, [userProfile.loading]);

  const props = {
    isAdmin: loggedInUser && loggedInUser.authorities && loggedInUser.authorities.includes('ROLE_ADMIN') ? true : false,
  };

  if (userProfile.loading)
    return (
      <>
        <div className="spinner-overlay">
          <Spinner animation="border" />
        </div>
      </>
    );
  return (
    <ErrorBoundaryRoutes>
      <Route index element={<>loading...</>} />
      <Route
        path="form"
        element={
          <div className="DotNumberFrom">
            <div className="DotFromWrap">
              <h5>Let's Begin</h5>
              <DashboardForm {...props} />
            </div>
          </div>
        }
      />
      <Route path=":dotNumber">
        <Route index element={<Dashboard />} />
        <Route path="inspections" element={<InspectionList />} />
      </Route>
      {/* <Route path=":dotNumber" element={<Dashboard />} />
      <Route path=":dotNumber/inspections" element={<InspectionListView />} /> */}
    </ErrorBoundaryRoutes>
  );
};

export default DashboardRoutes;
