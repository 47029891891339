import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DataQueue from './data-queue';
import DataQueueDetail from './data-queue-detail';
import DataQueueUpdate from './data-queue-update';
import DataQueueDeleteDialog from './data-queue-delete-dialog';

const DataQueueRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DataQueue />} />
    <Route path="new" element={<DataQueueUpdate />} />
    <Route path=":id">
      <Route index element={<DataQueueDetail />} />
      <Route path="edit" element={<DataQueueUpdate />} />
      <Route path="delete" element={<DataQueueDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DataQueueRoutes;
