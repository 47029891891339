import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDriver } from 'app/shared/model/driver.model';
import { getEntities as getDrivers } from 'app/entities/driver/driver.reducer';
import { IVehicle } from 'app/shared/model/vehicle.model';
import { getEntities as getVehicles } from 'app/entities/vehicle/vehicle.reducer';
import { ICarrier } from 'app/shared/model/carrier.model';
import { getEntities as getCarriers } from 'app/entities/carrier/carrier.reducer';
import { ICrash } from 'app/shared/model/crash.model';
import { getEntity, updateEntity, createEntity, reset } from './crash.reducer';

export const CrashUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const drivers = useAppSelector(state => state.driver.entities);
  const vehicles = useAppSelector(state => state.vehicle.entities);
  const carriers = useAppSelector(state => state.carrier.entities);
  const crashEntity = useAppSelector(state => state.crash.entity);
  const loading = useAppSelector(state => state.crash.loading);
  const updating = useAppSelector(state => state.crash.updating);
  const updateSuccess = useAppSelector(state => state.crash.updateSuccess);

  const handleClose = () => {
    navigate('/crash' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDrivers({}));
    dispatch(getVehicles({}));
    dispatch(getCarriers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...crashEntity,
      ...values,
      driver: drivers.find(it => it.id.toString() === values.driver.toString()),
      vehicle: vehicles.find(it => it.id.toString() === values.vehicle.toString()),
      carrier: carriers.find(it => it.id.toString() === values.carrier.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...crashEntity,
          createdDate: convertDateTimeFromServer(crashEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(crashEntity.lastModifiedDate),
          driver: crashEntity?.driver?.id,
          vehicle: crashEntity?.vehicle?.id,
          carrier: crashEntity?.carrier?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="asfaleiaApp.crash.home.createOrEditLabel" data-cy="CrashCreateUpdateHeading">
            <Translate contentKey="asfaleiaApp.crash.home.createOrEditLabel">Create or edit a Crash</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="crash-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('asfaleiaApp.crash.dotNumber')}
                id="crash-dotNumber"
                name="dotNumber"
                data-cy="dotNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.hazmatReleased')}
                id="crash-hazmatReleased"
                name="hazmatReleased"
                data-cy="hazmatReleased"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.reportNumber')}
                id="crash-reportNumber"
                name="reportNumber"
                data-cy="reportNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.vehicleLicenseSate')}
                id="crash-vehicleLicenseSate"
                name="vehicleLicenseSate"
                data-cy="vehicleLicenseSate"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.vehicleIdNumber')}
                id="crash-vehicleIdNumber"
                name="vehicleIdNumber"
                data-cy="vehicleIdNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.notPreventable')}
                id="crash-notPreventable"
                name="notPreventable"
                data-cy="notPreventable"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.lightConditionDesc')}
                id="crash-lightConditionDesc"
                name="lightConditionDesc"
                data-cy="lightConditionDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.timeWeight')}
                id="crash-timeWeight"
                name="timeWeight"
                data-cy="timeWeight"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.severityWeight')}
                id="crash-severityWeight"
                name="severityWeight"
                data-cy="severityWeight"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.towAway')}
                id="crash-towAway"
                name="towAway"
                data-cy="towAway"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.reportDate')}
                id="crash-reportDate"
                name="reportDate"
                data-cy="reportDate"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.roadSurfaceConditionDesc')}
                id="crash-roadSurfaceConditionDesc"
                name="roadSurfaceConditionDesc"
                data-cy="roadSurfaceConditionDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.reportState')}
                id="crash-reportState"
                name="reportState"
                data-cy="reportState"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.weatherConditionDesc')}
                id="crash-weatherConditionDesc"
                name="weatherConditionDesc"
                data-cy="weatherConditionDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.fatalities')}
                id="crash-fatalities"
                name="fatalities"
                data-cy="fatalities"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.vehicleLicenseNumber')}
                id="crash-vehicleLicenseNumber"
                name="vehicleLicenseNumber"
                data-cy="vehicleLicenseNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.severityTimeWeight')}
                id="crash-severityTimeWeight"
                name="severityTimeWeight"
                data-cy="severityTimeWeight"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.citationIssuedDesc')}
                id="crash-citationIssuedDesc"
                name="citationIssuedDesc"
                data-cy="citationIssuedDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.injuries')}
                id="crash-injuries"
                name="injuries"
                data-cy="injuries"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.accessControlDesc')}
                id="crash-accessControlDesc"
                name="accessControlDesc"
                data-cy="accessControlDesc"
                type="text"
              />
              <ValidatedField label={translate('asfaleiaApp.crash.seqNum')} id="crash-seqNum" name="seqNum" data-cy="seqNum" type="text" />
              <ValidatedField
                label={translate('asfaleiaApp.crash.trafficwayDesc')}
                id="crash-trafficwayDesc"
                name="trafficwayDesc"
                data-cy="trafficwayDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.createdBy')}
                id="crash-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.createdDate')}
                id="crash-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.lastModifiedBy')}
                id="crash-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.crash.lastModifiedDate')}
                id="crash-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField id="crash-driver" name="driver" data-cy="driver" label={translate('asfaleiaApp.crash.driver')} type="select">
                <option value="" key="0" />
                {drivers
                  ? drivers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="crash-vehicle"
                name="vehicle"
                data-cy="vehicle"
                label={translate('asfaleiaApp.crash.vehicle')}
                type="select"
              >
                <option value="" key="0" />
                {vehicles
                  ? vehicles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="crash-carrier"
                name="carrier"
                data-cy="carrier"
                label={translate('asfaleiaApp.crash.carrier')}
                type="select"
              >
                <option value="" key="0" />
                {carriers
                  ? carriers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.dotNumber}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/crash" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CrashUpdate;
