import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './crash.reducer';

export const CrashDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const crashEntity = useAppSelector(state => state.crash.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="crashDetailsHeading">
          <Translate contentKey="asfaleiaApp.crash.detail.title">Crash</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{crashEntity.id}</dd>
          <dt>
            <span id="dotNumber">
              <Translate contentKey="asfaleiaApp.crash.dotNumber">Dot Number</Translate>
            </span>
          </dt>
          <dd>{crashEntity.dotNumber}</dd>
          <dt>
            <span id="hazmatReleased">
              <Translate contentKey="asfaleiaApp.crash.hazmatReleased">Hazmat Released</Translate>
            </span>
          </dt>
          <dd>{crashEntity.hazmatReleased}</dd>
          <dt>
            <span id="reportNumber">
              <Translate contentKey="asfaleiaApp.crash.reportNumber">Report Number</Translate>
            </span>
          </dt>
          <dd>{crashEntity.reportNumber}</dd>
          <dt>
            <span id="vehicleLicenseSate">
              <Translate contentKey="asfaleiaApp.crash.vehicleLicenseSate">Vehicle License Sate</Translate>
            </span>
          </dt>
          <dd>{crashEntity.vehicleLicenseSate}</dd>
          <dt>
            <span id="vehicleIdNumber">
              <Translate contentKey="asfaleiaApp.crash.vehicleIdNumber">Vehicle Id Number</Translate>
            </span>
          </dt>
          <dd>{crashEntity.vehicleIdNumber}</dd>
          <dt>
            <span id="notPreventable">
              <Translate contentKey="asfaleiaApp.crash.notPreventable">Not Preventable</Translate>
            </span>
          </dt>
          <dd>{crashEntity.notPreventable}</dd>
          <dt>
            <span id="lightConditionDesc">
              <Translate contentKey="asfaleiaApp.crash.lightConditionDesc">Light Condition Desc</Translate>
            </span>
          </dt>
          <dd>{crashEntity.lightConditionDesc}</dd>
          <dt>
            <span id="timeWeight">
              <Translate contentKey="asfaleiaApp.crash.timeWeight">Time Weight</Translate>
            </span>
          </dt>
          <dd>{crashEntity.timeWeight}</dd>
          <dt>
            <span id="severityWeight">
              <Translate contentKey="asfaleiaApp.crash.severityWeight">Severity Weight</Translate>
            </span>
          </dt>
          <dd>{crashEntity.severityWeight}</dd>
          <dt>
            <span id="towAway">
              <Translate contentKey="asfaleiaApp.crash.towAway">Tow Away</Translate>
            </span>
          </dt>
          <dd>{crashEntity.towAway}</dd>
          <dt>
            <span id="reportDate">
              <Translate contentKey="asfaleiaApp.crash.reportDate">Report Date</Translate>
            </span>
          </dt>
          <dd>{crashEntity.reportDate}</dd>
          <dt>
            <span id="roadSurfaceConditionDesc">
              <Translate contentKey="asfaleiaApp.crash.roadSurfaceConditionDesc">Road Surface Condition Desc</Translate>
            </span>
          </dt>
          <dd>{crashEntity.roadSurfaceConditionDesc}</dd>
          <dt>
            <span id="reportState">
              <Translate contentKey="asfaleiaApp.crash.reportState">Report State</Translate>
            </span>
          </dt>
          <dd>{crashEntity.reportState}</dd>
          <dt>
            <span id="weatherConditionDesc">
              <Translate contentKey="asfaleiaApp.crash.weatherConditionDesc">Weather Condition Desc</Translate>
            </span>
          </dt>
          <dd>{crashEntity.weatherConditionDesc}</dd>
          <dt>
            <span id="fatalities">
              <Translate contentKey="asfaleiaApp.crash.fatalities">Fatalities</Translate>
            </span>
          </dt>
          <dd>{crashEntity.fatalities}</dd>
          <dt>
            <span id="vehicleLicenseNumber">
              <Translate contentKey="asfaleiaApp.crash.vehicleLicenseNumber">Vehicle License Number</Translate>
            </span>
          </dt>
          <dd>{crashEntity.vehicleLicenseNumber}</dd>
          <dt>
            <span id="severityTimeWeight">
              <Translate contentKey="asfaleiaApp.crash.severityTimeWeight">Severity Time Weight</Translate>
            </span>
          </dt>
          <dd>{crashEntity.severityTimeWeight}</dd>
          <dt>
            <span id="citationIssuedDesc">
              <Translate contentKey="asfaleiaApp.crash.citationIssuedDesc">Citation Issued Desc</Translate>
            </span>
          </dt>
          <dd>{crashEntity.citationIssuedDesc}</dd>
          <dt>
            <span id="injuries">
              <Translate contentKey="asfaleiaApp.crash.injuries">Injuries</Translate>
            </span>
          </dt>
          <dd>{crashEntity.injuries}</dd>
          <dt>
            <span id="accessControlDesc">
              <Translate contentKey="asfaleiaApp.crash.accessControlDesc">Access Control Desc</Translate>
            </span>
          </dt>
          <dd>{crashEntity.accessControlDesc}</dd>
          <dt>
            <span id="seqNum">
              <Translate contentKey="asfaleiaApp.crash.seqNum">Seq Num</Translate>
            </span>
          </dt>
          <dd>{crashEntity.seqNum}</dd>
          <dt>
            <span id="trafficwayDesc">
              <Translate contentKey="asfaleiaApp.crash.trafficwayDesc">Trafficway Desc</Translate>
            </span>
          </dt>
          <dd>{crashEntity.trafficwayDesc}</dd>
          <dt>
            <Translate contentKey="asfaleiaApp.crash.driver">Driver</Translate>
          </dt>
          <dd>{crashEntity.driver ? crashEntity.driver.id : ''}</dd>
          <dt>
            <Translate contentKey="asfaleiaApp.crash.vehicle">Vehicle</Translate>
          </dt>
          <dd>{crashEntity.vehicle ? crashEntity.vehicle.id : ''}</dd>
          <dt>
            <Translate contentKey="asfaleiaApp.crash.carrier">Carrier</Translate>
          </dt>
          <dd>{crashEntity.carrier ? crashEntity.carrier.dotNumber : ''}</dd>
        </dl>
        <Button tag={Link} to="/crash" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/crash/${crashEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CrashDetail;
