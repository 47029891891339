import { getEntities } from 'app/entities/coaching-item/coaching-item.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import { getColor, getMonths, getNumbers } from 'app/shared/util/app-utils';
import InformationBadge from '../components/InformationBadge';

interface Props {}
export const CoachingItemListView = ({ isCoachingEnabled, smsDataUpdate, smsData }) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const { dotNumber } = useParams<'dotNumber'>();
  const dataList = useAppSelector(state => state.coachingItem.entities);
  const loading = useAppSelector(state => state.coachingItem.loading);
  const totalItems = useAppSelector(state => state.coachingItem.totalItems);
  const issScoreNumbers = smsData && smsData.issScore && getNumbers(smsData.issScore, 7);

  // const getAllEntities = () => {
  //   dispatch(
  //     getEntities({
  //       page: paginationState.activePage - 1,
  //       size: 7,
  //       sort: `${paginationState.sort},${paginationState.order}`,
  //       query: dotNumber ? `dotNumber.equals=${dotNumber}` : '',
  //     })
  //   );
  // };
  // const updatedSmsData = useAppSelector(state => state.customReducer.loading);

  // const sortEntities = () => {
  //   getAllEntities();
  // };

  // useEffect(() => {
  //   sortEntities();
  // }, [paginationState.activePage, paginationState.order, dotNumber, updatedSmsData]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  return (
    <div className="table-responsive">
      <div className="table-heading">
        <h3>
          Coaching Road Map
          <span>{isCoachingEnabled && <InformationBadge title="coachingRoadMap" />}</span>
        </h3>
        {/* <span>Monthly Improvement in ISS Score after coaching</span> */}
      </div>
      {dataList ? (
        <Table>
          {/* {dataList.length > 0 && (
            <>
              <thead>
                <tr>
                  {Object.keys(dataList[0])
                    .filter(item => ['month', 'completed'].includes(item))
                    .map(item => {
                      return <th key={`${item}`}>{item}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {dataList.map((data, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    {Object.entries(data).map(([key, value]) => {
                      if (!['month', 'completed'].includes(key)) return;
                      if (key === 'completed')
                        return (
                          <td key={`${key} ${value}`}>
                            <div className="colorBar">
                              <span
                                style={{ width: `${value}%`, backgroundColor: value > 74 ? '#D80000' : value > 49 ? '#FFC703' : '#01C520' }}
                              ></span>
                            </div>
                            <span>{value as string}</span>
                          </td>
                        );
                      return <td key={`${key} ${value}`}> {value as string}</td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </>
          )} */}
          <thead>
            <tr>
              <th style={{ width: '20%' }} key="month">
                Month
              </th>
              <th style={{ width: '40%' }} key="progressBar"></th>
              <th style={{ width: '30%' }} key="issScore">
                Iss Score
              </th>
            </tr>
          </thead>
          <tbody>
            {smsDataUpdate[0] &&
              smsDataUpdate[0].lastUpdatedOn &&
              getMonths(smsDataUpdate[0].lastUpdatedOn).map((month, i) => (
                <tr key={`month-${i}`}>
                  <td style={{ width: '20%' }} key={month}>
                    <span>{month}</span>
                  </td>
                  <td style={{ width: '40%' }}>
                    <div className="colorBar coaching-bar">
                      <span style={{ width: `${issScoreNumbers[i]}%`, backgroundColor: getColor(issScoreNumbers[i]) }}></span>
                    </div>
                  </td>
                  <td style={{ width: '30%' }} key={month + i}>
                    <span>{issScoreNumbers[i]}</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="conductorApp.product.home.notFound">No Products found</Translate>
          </div>
        )
      )}
    </div>
  );
};

export default CoachingItemListView;
