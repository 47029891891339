import { coachingInfo } from 'app/shared/constants/information';
import React from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';

export const InformationBadge = ({ title }) => {
  return (
    <>
      <Badge className="info-badge" id={`tooltip-${title}`} style={{ cursor: 'pointer' }}>
        i
      </Badge>
      <UncontrolledTooltip className="tooltip_design" placement="auto" target={`tooltip-${title}`}>
        {coachingInfo[title] &&
          coachingInfo[title].map(i => {
            return (
              <>
                <span>{i}</span>
                <br></br>
                <br></br>
              </>
            );
          })}
      </UncontrolledTooltip>
    </>
  );
};

export default InformationBadge;
