import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './carrier.reducer';

export const CarrierDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const carrierEntity = useAppSelector(state => state.carrier.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="carrierDetailsHeading">
          <Translate contentKey="asfaleiaApp.carrier.detail.title">Carrier</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.id}</dd>
          <dt>
            <span id="legalName">
              <Translate contentKey="asfaleiaApp.carrier.legalName">Legal Name</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.legalName}</dd>
          <dt>
            <span id="dotNumber">
              <Translate contentKey="asfaleiaApp.carrier.dotNumber">Dot Number</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.dotNumber}</dd>
          <dt>
            <span id="dbaName">
              <Translate contentKey="asfaleiaApp.carrier.dbaName">Dba Name</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.dbaName}</dd>
          <dt>
            <span id="telephone">
              <Translate contentKey="asfaleiaApp.carrier.telephone">Telephone</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.telephone}</dd>
          <dt>
            <span id="recentMileage">
              <Translate contentKey="asfaleiaApp.carrier.recentMileage">Recent Mileage</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.recentMileage}</dd>
          <dt>
            <span id="mailingCity">
              <Translate contentKey="asfaleiaApp.carrier.mailingCity">Mailing City</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mailingCity}</dd>
          <dt>
            <span id="mailingState">
              <Translate contentKey="asfaleiaApp.carrier.mailingState">Mailing State</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mailingState}</dd>
          <dt>
            <span id="federalGovernment">
              <Translate contentKey="asfaleiaApp.carrier.federalGovernment">Federal Government</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.federalGovernment}</dd>
          <dt>
            <span id="carrierOperation">
              <Translate contentKey="asfaleiaApp.carrier.carrierOperation">Carrier Operation</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.carrierOperation}</dd>
          <dt>
            <span id="hmFlag">
              <Translate contentKey="asfaleiaApp.carrier.hmFlag">Hm Flag</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.hmFlag}</dd>
          <dt>
            <span id="vmtSourceId">
              <Translate contentKey="asfaleiaApp.carrier.vmtSourceId">Vmt Source Id</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.vmtSourceId}</dd>
          <dt>
            <span id="mailingStreet">
              <Translate contentKey="asfaleiaApp.carrier.mailingStreet">Mailing Street</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mailingStreet}</dd>
          <dt>
            <span id="mailingCountry">
              <Translate contentKey="asfaleiaApp.carrier.mailingCountry">Mailing Country</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mailingCountry}</dd>
          <dt>
            <span id="privateProperty">
              <Translate contentKey="asfaleiaApp.carrier.privateProperty">Private Property</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.privateProperty}</dd>
          <dt>
            <span id="mcs150Mileage">
              <Translate contentKey="asfaleiaApp.carrier.mcs150Mileage">Mcs 150 Mileage</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mcs150Mileage}</dd>
          <dt>
            <span id="privateOnly">
              <Translate contentKey="asfaleiaApp.carrier.privateOnly">Private Only</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.privateOnly}</dd>
          <dt>
            <span id="mcs150MileageYear">
              <Translate contentKey="asfaleiaApp.carrier.mcs150MileageYear">Mcs 150 Mileage Year</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mcs150MileageYear}</dd>
          <dt>
            <span id="indianTribe">
              <Translate contentKey="asfaleiaApp.carrier.indianTribe">Indian Tribe</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.indianTribe}</dd>
          <dt>
            <span id="privatePassengerNonbusiness">
              <Translate contentKey="asfaleiaApp.carrier.privatePassengerNonbusiness">Private Passenger Nonbusiness</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.privatePassengerNonbusiness}</dd>
          <dt>
            <span id="oicState">
              <Translate contentKey="asfaleiaApp.carrier.oicState">Oic State</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.oicState}</dd>
          <dt>
            <span id="opOther">
              <Translate contentKey="asfaleiaApp.carrier.opOther">Op Other</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.opOther}</dd>
          <dt>
            <span id="mailingZip">
              <Translate contentKey="asfaleiaApp.carrier.mailingZip">Mailing Zip</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mailingZip}</dd>
          <dt>
            <span id="phyState">
              <Translate contentKey="asfaleiaApp.carrier.phyState">Phy State</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.phyState}</dd>
          <dt>
            <span id="addDate">
              <Translate contentKey="asfaleiaApp.carrier.addDate">Add Date</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.addDate}</dd>
          <dt>
            <span id="mcs150Date">
              <Translate contentKey="asfaleiaApp.carrier.mcs150Date">Mcs 150 Date</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.mcs150Date}</dd>
          <dt>
            <span id="localGovernment">
              <Translate contentKey="asfaleiaApp.carrier.localGovernment">Local Government</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.localGovernment}</dd>
          <dt>
            <span id="phyCountry">
              <Translate contentKey="asfaleiaApp.carrier.phyCountry">Phy Country</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.phyCountry}</dd>
          <dt>
            <span id="dotPin">
              <Translate contentKey="asfaleiaApp.carrier.dotPin">Dot Pin</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.dotPin}</dd>
          <dt>
            <span id="phyZip">
              <Translate contentKey="asfaleiaApp.carrier.phyZip">Phy Zip</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.phyZip}</dd>
          <dt>
            <span id="migrant">
              <Translate contentKey="asfaleiaApp.carrier.migrant">Migrant</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.migrant}</dd>
          <dt>
            <span id="authorizedForHire">
              <Translate contentKey="asfaleiaApp.carrier.authorizedForHire">Authorized For Hire</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.authorizedForHire}</dd>
          <dt>
            <span id="pcFlag">
              <Translate contentKey="asfaleiaApp.carrier.pcFlag">Pc Flag</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.pcFlag}</dd>
          <dt>
            <span id="usMail">
              <Translate contentKey="asfaleiaApp.carrier.usMail">Us Mail</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.usMail}</dd>
          <dt>
            <span id="privatePassengerBusiness">
              <Translate contentKey="asfaleiaApp.carrier.privatePassengerBusiness">Private Passenger Business</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.privatePassengerBusiness}</dd>
          <dt>
            <span id="stateGovernment">
              <Translate contentKey="asfaleiaApp.carrier.stateGovernment">State Government</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.stateGovernment}</dd>
          <dt>
            <span id="recentMileageYear">
              <Translate contentKey="asfaleiaApp.carrier.recentMileageYear">Recent Mileage Year</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.recentMileageYear}</dd>
          <dt>
            <span id="phyStreet">
              <Translate contentKey="asfaleiaApp.carrier.phyStreet">Phy Street</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.phyStreet}</dd>
          <dt>
            <span id="fax">
              <Translate contentKey="asfaleiaApp.carrier.fax">Fax</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.fax}</dd>
          <dt>
            <span id="exemptForHire">
              <Translate contentKey="asfaleiaApp.carrier.exemptForHire">Exempt For Hire</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.exemptForHire}</dd>
          <dt>
            <span id="driverTotal">
              <Translate contentKey="asfaleiaApp.carrier.driverTotal">Driver Total</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.driverTotal}</dd>
          <dt>
            <span id="phyCity">
              <Translate contentKey="asfaleiaApp.carrier.phyCity">Phy City</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.phyCity}</dd>
          <dt>
            <span id="nbrPowerUnit">
              <Translate contentKey="asfaleiaApp.carrier.nbrPowerUnit">Nbr Power Unit</Translate>
            </span>
          </dt>
          <dd>{carrierEntity.nbrPowerUnit}</dd>
        </dl>
        <Button tag={Link} to="/carrier" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/carrier/${carrierEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CarrierDetail;
