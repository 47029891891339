import { getEntities as ViolationEntities } from 'app/entities/violation/violation.reducer';

import { getEntities as DriverEntities } from 'app/entities/driver/driver.reducer';

import { getEntitiesByFilter } from 'app/entities/inspection/inspection.reducer';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table, Button, Col, Row, UncontrolledTooltip, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getSession } from 'app/shared/reducers/authentication';
import { AUTHORITIES } from 'app/config/constants';
import ViolationDescDialog from '../components/modals/ViolationDescDialog';
import { FlagIcon } from 'app/assets/svg-icon/icon';
import {
  SubstancesDynamic,
  IndicatorDynamic,
  HazardMaterials,
  UnsafeDriveDynamic,
  FitnessDynamic,
  UnsafeDriving1,
  VehicMaintenance,
} from 'app/config/Icons';
import { getColorClass } from 'app/shared/util/app-utils';
import InspectionDescDialog from '../components/modals/InspectionDescDialog';
import InformationBadge from '../components/InformationBadge';

interface Props {}
export const InspectionListView = (props: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const state = location.state as { isCoachingEnabled: boolean };
  // console.log('isCoachingEnabled: ', JSON.parse(localStorage.getItem('isCoachingEnabled')));

  const isCoachingEnabled = JSON.parse(localStorage.getItem('isCoachingEnabled'));

  const navigate = useNavigate();
  const { dotNumber } = useParams<'dotNumber'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'inspDate', DESC), location.search)
  );
  const [driverId, setDriverId] = useState(undefined);
  const [dataList, setDataList] = useState([]);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  const Violation = useAppSelector(state => state.violation.entities);

  const Driver = useAppSelector(state => state.driver.entities);
  const updating = useAppSelector(state => state.inspection.updating);
  const inspection = useAppSelector(state => state.inspection.entities);
  const loading = useAppSelector(state => state.inspection.loading);
  const totalItems = useAppSelector(state => state.inspection.totalItems);
  const [inspections, setInspections] = useState([]);
  const [showViolDescModal, setShowViolDescModal] = useState(false);
  const [showInspDescModal, setShowInspDescModal] = useState(false);
  const [filterData, setFilterData] = useState({ startDate: '', endDate: '', driverName: '', dotNumber: dotNumber });
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalViolationPoint, setTotalViolationPoint] = useState(0);
  const [violLogoMap, setViolLogoMap] = useState(undefined);
  const [selectedInsp, setSelectedInsp] = useState(undefined);

  const queryParams = {
    page: paginationState.activePage - 1,
    size: paginationState.itemsPerPage,
    sort: `${paginationState.sort},${paginationState.order}`,
  };

  const getAllEntities = () => {
    dispatch(getSession());
    dispatch(getEntitiesByFilter({ queryParams, filterData }));
  };

  useEffect(() => {
    if (!showViolDescModal) {
      var inspections = [];
      var totViolPoint = 0;
      let violLogoMapTemp = new Map();
      if (inspection && inspection.length > 0) {
        inspection.map(data => {
          var severityWeight = 0;
          var timeWeight = 0;
          if (data.violations && data.violations.length > 0) {
            data.violations.map(viol => {
              if (viol.severityWeight != null) {
                let sw = Number(viol.severityWeight);
                severityWeight = severityWeight + sw;

                // to calculate viol logo and point
                if (violLogoMapTemp.has(data.id)) {
                  let valueArr = violLogoMapTemp.get(data.id);
                  let foundObj = valueArr.find(obj => viol.basicDesc in obj);
                  if (foundObj) {
                    foundObj[viol.basicDesc] += sw;
                  } else {
                    valueArr.push({ [viol.basicDesc]: sw });
                  }
                } else {
                  violLogoMapTemp.set(data.id, [{ [viol.basicDesc]: sw }]);
                }
              }
            });
          }
          if (data.timeWeight != null) timeWeight = Number(data.timeWeight);
          const modData = {
            ...data,
            violationPoint: severityWeight * timeWeight,
          };
          inspections.push(modData);
          totViolPoint += modData.violationPoint;
        });
      }
      setInspections(inspections);
      setTotalViolationPoint(totViolPoint);
      setViolLogoMap(violLogoMapTemp);
    }
  }, [inspection]);

  const relationshipDataAdd = () => {
    var entityField = ['inspLevelId', 'inspDate', 'reportNumber', 'reportState', 'firstName', 'lastName', 'driverType'];

    var merged = [];

    for (let i = 0; i < inspection.length; i++) {
      merged.push({
        ...Violation.find(itmInner => itmInner && itmInner.inspection && itmInner.inspection.id === inspection[i].id),

        ...Driver.find(itmInner => itmInner && itmInner.inspection && itmInner.inspection.id === inspection[i].id),

        ...inspection[i],
      });
    }

    const newMerge = merged.map(result =>
      entityField.reduce((obj, key) => {
        obj[key] = result[key] || '';

        return obj;
      }, {})
    );
    setDataList(newMerge);
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  useEffect(() => {
    if (inspection.length > 0 && Violation.length > 0 && Driver.length > 0) {
      relationshipDataAdd();
    }
  }, [inspection, Violation, Driver]);

  let entityFields = ['inspLevelId', 'inspDate', 'reportNumber', 'reportState', 'Driver Name', 'Violation Point'];

  const closeViolDescModal = val => {
    setShowViolDescModal(val);
  };

  const closeInspDescModal = val => {
    setShowInspDescModal(val);
  };

  const handleViolDescDisplay = val => {
    setDriverId(val);
    setShowViolDescModal(true);
  };

  const handleInspDescDisplay = val => {
    setSelectedInsp(val);
    setShowInspDescModal(true);
  };

  const onstartDateChange = e => {
    var start = e.target.value;
    if (!startDateBeforeEndDate(e.target.value, endDate)) {
      start = '';
    }
    setstartDate(start);
    setFilterData({
      ...filterData,
      startDate: start,
    });
  };

  const onEndDateChange = e => {
    var end = e.target.value;
    if (!startDateBeforeEndDate(startDate, e.target.value)) {
      end = '';
    }
    setEndDate(end);
    setFilterData({
      ...filterData,
      endDate: end,
    });
  };

  const startDateBeforeEndDate = (s, e) => {
    const start = new Date(s);
    const end = new Date(e);
    if (start && end && start >= end) {
      toast.error('Start date must be before end date');
      return false;
    }
    return true;
  };

  const handleSubmit = values => {
    var filterData = {
      startDate: values.startDate,
      endDate: values.endDate,
      driverName: values.driverName,
      dotNumber: dotNumber,
    };
    setFilterData(filterData);
    dispatch(getEntitiesByFilter({ queryParams, filterData }));
  };

  const getIcon = title => {
    if (title.toLowerCase().includes('unsafe driving')) return <UnsafeDriveDynamic />;
    else if (title.toLowerCase().includes('hos compliance')) return <UnsafeDriving1 />;
    else if (title.toLowerCase().includes('driver fitness')) return <FitnessDynamic />;
    else if (title === 'Cont. Substances') return <SubstancesDynamic />;
    else if (title.toLowerCase().includes('vehicle maint.')) return <VehicMaintenance />;
    else if (title.includes('hazardous')) return <HazardMaterials />;
    else if (title.toLowerCase().includes('crash')) return <IndicatorDynamic />;
    return null;
  };

  // console.log(dotNumber , typeof(dotNumber));
  const goBack = () => {
    return `/dashboard/${dotNumber}`;
  };

  return (
    <div className="main-dashboar-chart row inspectionsScreen">
      <h3 className="text-capitalize mb-3">
        Inspections
        <span>{isCoachingEnabled && <InformationBadge title="allInspections" />}</span>
        <Button className="end btn-design mx-5 px-5" type="submit" color="warning" href={goBack()}>
          Back
        </Button>
      </h3>

      {inspections && inspections ? (
        <>
          <Row>
            <div className="d-flex">
              <ValidatedForm defaultValues={filterData} className="dotForm filter-form" onSubmit={handleSubmit}>
                <ValidatedField id="start-date" name="startDate" label="Start Date" type="date" onChange={onstartDateChange} />
                <ValidatedField id="end-date" name="endDate" label="End Date" type="date" onChange={onEndDateChange} />
                <ValidatedField id="driver-name" name="driverName" label="Driver Name" type="text" />
                <Button className="btn-design" color="primary" type="submit" disabled={updating}>
                  Submit
                </Button>
              </ValidatedForm>
            </div>
          </Row>

          <div className="main-dashbord-table-content row">
            <div className="table-responsive">
              {loading && (
                <div className="spinner-overlay">
                  <Spinner animation="border" />
                </div>
              )}
              <span>{isCoachingEnabled && <InformationBadge title="allInspectionsTable" />}</span>
              <Table
                hover
                className="align-baseline table table-group-divider "
                style={{ borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }}
              >
                {inspections.length > 0 ? (
                  <>
                    <thead className="bg-opacity-10 bg-primary shadow-sm text-capitalize">
                      <tr>
                        <th className="hand">
                          <span onClick={sort('violationPoint')} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon="sort" />
                          </span>
                        </th>
                        {Object.keys(inspections[0])
                          .filter(items => entityFields.includes(items))
                          .map(item => {
                            return (
                              <th style={{ padding: '10px' }} className="fw-semibold">
                                {item === 'inspLevelId' ? (
                                  <span onClick={sort(item)} style={{ cursor: 'pointer' }}>
                                    Insp. Level <FontAwesomeIcon icon="sort" />
                                  </span>
                                ) : item === 'inspDate' ? (
                                  <span onClick={sort(item)} style={{ cursor: 'pointer' }}>
                                    Date <FontAwesomeIcon icon="sort" />
                                  </span>
                                ) : item === 'reportNumber' ? (
                                  <span onClick={sort(item)} style={{ cursor: 'pointer' }}>
                                    Number <FontAwesomeIcon icon="sort" />
                                  </span>
                                ) : item === 'reportState' ? (
                                  <span onClick={sort(item)} style={{ cursor: 'pointer' }}>
                                    State <FontAwesomeIcon icon="sort" />
                                  </span>
                                ) : (
                                  item
                                )}{' '}
                              </th>
                            );
                          })}
                        <th style={{ padding: '10px', cursor: 'pointer' }} className="fw-semibold" onClick={sort('firstName')}>
                          Driver Name <FontAwesomeIcon icon="sort" />
                        </th>
                        <th style={{ padding: '10px' }} className="fw-semibold table_width">
                          Violations
                        </th>
                        <th
                          style={{ padding: '10px', cursor: 'pointer' }}
                          className="fw-semibold maxtable_width"
                          onClick={sort('violationPoint')}
                        >
                          Violation Point <FontAwesomeIcon icon="sort" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {inspections.map((product, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable">
                          <td>
                            <FlagIcon
                              fill={product.violationPoint === 0 ? '#01C520' : product.violationPoint > 10 ? '#D80000' : '#FFC703'}
                            />
                          </td>
                          {Object.entries(product).map(([key, value]) => {
                            if (entityFields.includes(key)) {
                              if (key === 'inspLevelId') {
                                return <td>{value && (value === '1' ? 'I' : value === '2' ? 'II' : 'III')} </td>;
                              } else if (key === 'reportNumber') {
                                return (
                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      handleInspDescDisplay(product);
                                    }}
                                  >
                                    {value as string}
                                  </td>
                                );
                              } else {
                                return <td>{value as string} </td>;
                              }
                            }
                          })}
                          <td
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleViolDescDisplay(
                                product.drivers &&
                                  product.drivers.filter(d => d.driverType && d.driverType === 'Primary Driver').map(d => d.id)
                              );
                            }}
                          >
                            {product.drivers &&
                              product.drivers
                                .filter(d => d.driverType && d.driverType === 'Primary Driver')
                                .map(d => d.firstName + ' ' + d.lastName)}
                          </td>
                          <td>
                            {violLogoMap &&
                              violLogoMap.get(product.id)?.map((v, index) => {
                                const targetId = `tooltip-${product.id}-${index}`;
                                return (
                                  <div className="score_top1" key={index}>
                                    <div id={targetId} className="score_top">
                                      <div className={getColorClass(v[Object.keys(v)[0]] * product.timeWeight)}>
                                        {getIcon(Object.keys(v)[0])}
                                      </div>
                                      <span>{v[Object.keys(v)[0]] * product.timeWeight}</span>
                                    </div>
                                    <UncontrolledTooltip className="tooltip_design" placement="top" target={targetId}>
                                      <span>{Object.keys(v)[0]}</span>
                                    </UncontrolledTooltip>
                                  </div>
                                );
                              })}
                          </td>
                          <td>{product.violationPoint}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={7}></td>
                        <td>
                          <span className="viol-total">
                            <b>Total: {totalViolationPoint}</b>{' '}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  !loading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="asfaleiaApp.inspection.home.notFound">No Inspections found</Translate>
                    </div>
                  )
                )}
              </Table>
            </div>
          </div>
        </>
      ) : (
        !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="conductorApp.product.home.notFound">No Inspections found</Translate>
          </div>
        )
      )}
      {totalItems ? (
        <div className={inspections && inspections.length > 0 ? '' : 'd-none'}>
          <div className="cetr-pagination">
            <div className="justify-content-end d-flex mt-2">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-end d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {showViolDescModal && (
        <ViolationDescDialog isCoachingEnabled={isCoachingEnabled} driver={driverId} closeViolDescModal={closeViolDescModal} />
      )}
      {showInspDescModal && (
        <InspectionDescDialog isCoachingEnabled={isCoachingEnabled} inspection={selectedInsp} closeInspDescModal={closeInspDescModal} />
      )}
    </div>
  );
};

export default InspectionListView;
