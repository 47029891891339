import { generateRandomColor } from 'app/shared/util/app-utils';
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Button } from 'reactstrap';
import '../../assets/theme-scss/popup.scss';
import InformationBadge from './InformationBadge';
import ScoreGauge from './ScoreGauge';

export const ViewReportGraph = ({ violationType, isCoachingEnabled, type, violations }) => {
  const [labels, setLabels] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [viewPerformance, setViewPerformance] = useState(false);

  useEffect(() => {
    if (violations?.length > 0) {
      const sortedData = [...violations].sort(
        (a, b) => new Date(a.inspection?.inspDate).getTime() - new Date(b.inspection?.inspDate).getTime()
      );

      var labelsTemp = new Set();
      var dataSetsTemp = [];
      var lablesMap: Record<string, number[]> = {};

      sortedData.map(v => {
        labelsTemp.add(v.inspection?.inspDate);
        lablesMap[v.inspection?.inspDate] = [];
      });
      setLabels(Array.from(labelsTemp));

      Array.from(labelsTemp).map((l: string) => {
        const filteredViolations = sortedData.filter(v => v.inspection?.inspDate === l);
        filteredViolations?.map(fv => {
          lablesMap[l]?.push(Number(fv.severityWeight));
        });
      });

      for (var i = 0; i < getMaxValueLengthInMap(lablesMap); i++) {
        dataSetsTemp.push(Array(labelsTemp.size).fill(0));
      }

      Object.values(lablesMap).forEach((values, index) => {
        values.map((v, i) => {
          dataSetsTemp[i][index] = v;
        });
      });
      setDataSets(dataSetsTemp);
    }

    return () => {
      console.log('Unmounting View Report Graph');
      setLabels([]);
      setDataSets([]);
    };
  }, [violations]);

  function getMaxValueLengthInMap(map: Record<string, number[]>): number {
    let maxLength = 0;
    for (const key in map) {
      if (map.hasOwnProperty(key)) {
        const currentLength = map[key].length;
        if (currentLength > maxLength) {
          maxLength = currentLength;
        }
      }
    }
    return maxLength;
  }

  const colors = dataSets.map(() => generateRandomColor());

  const data = {
    labels: labels,
    datasets: dataSets.map((dataset, index) => ({
      label: `SW${index + 1}`,
      data: dataset,
      backgroundColor: colors[index],
      borderColor: colors[index],
      borderWidth: 1,
    })),
  };

  const options = {
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        beginAtZero: true,
        ticks: {
          precision: 0,
          max: 100,
        },
        title: {
          display: true,
          text: 'Severity Weight',
          font: {
            size: 14,
            //         weight: 'bold',
          },
        },
      },
    },
  };

  function mergeAndAddArrays(arrays: number[][]): number[] {
    return arrays.reduce((mergedArray, currentArray) => {
      return mergedArray.map((value, index) => value + (currentArray[index] || 0));
    }, new Array(arrays[0].length).fill(0));
  }

  const lineData = {
    labels: labels,
    datasets: [
      {
        label: 'Severity Weight',
        data: dataSets && dataSets.length > 0 ? mergeAndAddArrays(dataSets) : [],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
        pointRadius: 6,
        pointHoverRadius: 8,
        fill: true,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          max: 100,
        },
        title: {
          display: true,
          text: 'Severity Weight',
          font: {
            size: 14,
            // weight: 'bold',
          },
        },
      },
    },
  };

  return (
    <div style={{ width: '500px', height: `${viewPerformance && type === 'CARRIER' ? '530' : '320'}px` }}>
      <div className="white-button">
        <Button color="primary" onClick={() => setViewPerformance(!viewPerformance)}>
          {viewPerformance ? 'View Report' : 'View Performance'}
        </Button>
      </div>
      {viewPerformance ? (
        <>
          {viewPerformance && type === 'CARRIER' && (
            <ScoreGauge
              fieldName={violationType && violationType}
              type={type}
              driverSmsData={undefined}
              previousDriverSmsData={undefined}
            />
          )}
          <div className="height-fix">
            {isCoachingEnabled && <InformationBadge title="viewPerformanceGraph" />}
            <Line data={lineData} options={lineOptions} />
          </div>
        </>
      ) : (
        <>
          {isCoachingEnabled && <InformationBadge title={type === 'CARRIER' ? 'viewReportGraph' : 'violationReportGraph'} />}
          <Bar data={data} options={options} />
        </>
      )}
    </div>
  );
};

export default ViewReportGraph;
