import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICarrier, defaultValue } from 'app/shared/model/carrier.model';

const initialState: EntityState<ICarrier> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/carriers';

// Actions

export const getEntities = createAsyncThunk('carrier/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}${
    query ? `&${query}` : ''
  }`;
  return axios.get<ICarrier[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'carrier/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICarrier>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'carrier/create_entity',
  async (entity: ICarrier, thunkAPI) => {
    const result = await axios.post<ICarrier>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'carrier/update_entity',
  async (entity: ICarrier, thunkAPI) => {
    const result = await axios.put<ICarrier>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'carrier/partial_update_entity',
  async (entity: ICarrier, thunkAPI) => {
    const result = await axios.patch<ICarrier>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'carrier/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICarrier>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const uploadLogo = createAsyncThunk(
  'carrier/upload-logo',
  async (data: any, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', data.file);
    console.log(formData);
    // return;
    const result = await axios.post<ICarrier>(apiUrl + '/logo/upload/' + data.carrierId, formData);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const uploadInspectionReportXML = createAsyncThunk(
  'api/upload',
  async (data: any, thunkAPI) => {
    const formData = new FormData();
    console.log('>>>>@@@ ', data.file);
    formData.append('file', data.file);
    console.log(formData);
    // return;
    const result = await axios.post<ICarrier>('/api/upload/', formData);
    console.log(result);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getlogoImage = createAsyncThunk(
  'carrier/fetch-logo',
  async (fileName: string) => {
    const requestUrl = `${apiUrl}/logo/${fileName}`;
    try {
      const response: AxiosResponse<any> = await axios.get<any>(requestUrl, {
        responseType: 'arraybuffer',
      });

      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        return url;
      } else {
        throw new Error('No data received from the server');
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      throw error;
    }
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CarrierSlice = createEntitySlice({
  name: 'carrier',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getlogoImage.fulfilled, (state, action) => {
        state.loading = false;
        state.entity.logo = action.payload;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, uploadLogo, uploadInspectionReportXML), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getlogoImage), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, uploadLogo, uploadInspectionReportXML),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = CarrierSlice.actions;

// Reducer
export default CarrierSlice.reducer;
