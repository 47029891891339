export const coachingInfo = {
  red: [
    'Inspect with a score ranging from 75-100 is the most serious category a company and its drivers can be in. Being in this red category means you have a lot of violations that need to be looked into and your drivers are more likely to be pulled over and companies also look at this score to see if they should use you to ship their products.. This will impact delivery time, will result in loss of revenue for your company and it makes our roads unsafe.',
  ],
  yellow: [
    'Optional with a score ranging from 50-74 is a category that is medium risk for your company and its drivers. Taking action in this category is very important as it can either trend you to good standing if proper action is taken or trend you to the red category if action is not properly taken.',
  ],
  green: [
    'Pass with a score ranging from 1-49 is a category that is in excellent standing. This means your company and drivers are abiding by rules and checks. It is highly recommended that if the score in this category starts coming closer to 49, action be taken to avoid going into the optional medium risk category.',
    'Being in the green pass category will help your company win more contracts from companies looking to ship products as they see your company as a safe way to ship. It will also help drivers being pulled over more as they are safe drivers which also means they are keeping our roads safe and earning more revenue for the company and themselves as they get to stay on the road longer rather than waiting on the side of the road for violations.',
  ],
  wheel: [
    'This wheel provides scores based on category. This will allow you to see which categories you need to concentrate on more. For example if a category such as Unsafe driving is red you should target that as soon as possible to bring the score down.',
    'Next you should target the yellow categories followed by green categories. Although green categories mean your standing is in excellent condition it is still important to make sure the numbers are low so you don’t end up going into the yellow. For example if Unsafe driving is at 40 you need to look into it asap as it is getting close to a yellow category which will cause it to go into medium risk.',
    'The goal is to keep our roads safe so having ZERO violations is the target you should set for your company and your drivers.',
  ],
  nextUpdates: [
    'If you hover over the next updates it will show when the score will update next. If you are staying on top of all recommendations provided by Asfaleia this score should trend downwards, however failing to follow Asfaleia recommendations will cause this score to trend upwards.',
  ],
  highRiskDrivers: [
    'This shows how many of your drivers are at high risk. These drivers are the ones having the most impact on your score and you need to look into them asap. When you click on “View All” under recent inspection you should sort the violations points from greatest to least. This will show you which drivers are performing the worst first.',
    'You will then have to see what categories they are performing badly in and coach them by seeing what they are doing wrong and monitoring them after coaching. This will help your company and drivers achieve better rankings.',
    'These high risk drivers are also more likely to get pulled over and cause loss of revenue to your company.',
  ],
  mediumRiskDrivers: [
    'This shows how many of your drivers are at medium risk. These drivers are having a moderate impact on your company and themselves which should be looked into to help them improve. To do this under recent inspections click “View All” and sort by points from least to greatest. This is the fastest way of finding medium risk drivers as you are searching for drivers with scores 50+.',
    'You will then have to see what categories they are performing badly in and coach them by seeing what they are doing wrong and monitoring them after coaching. This will help your company and drivers achieve better rankings.',
    'Taking action for medium risk drivers can quickly help you trend them into the green good standing zone vs high risk drivers where it will take much more coaching and much longer to get them there.',
  ],
  isViolationsIncreased: [
    "This shows the trend in which your company's drivers are heading in. An increasing trend is concerning as that means they are racking up violations and this needs to be looked into by following advice provided by Asfaleia.",
    'You need to sort drivers from most violations to least under recent inspections by clicking “View All”  Here you can click on a drivers name and then click view performance to see if the trend is heading down or up. If it is heading up you will need to coach them asap on what they can do to improve by seeing which categories they are getting violations in.',
    'If your company trend is decreasing that means you are following steps as a company by coaching drivers and staying on top of them to make sure they are not racking up points. Keep up the good work as this will help your company and our roads.',
  ],
  issScoreIncreased: [],
  highRiskViolations: [],
  mediumRiskViolations: [],
  risingViolations: [],
  threshold: [
    'This area shows the FMCSA top threshold for the specific category and the number needs to be kept below this to be in a good standing.',
    'It also displays FMCSA current threshold which is the current percentage you have for a specific category, remember this number should be in green below the FMCSA Threshold.',
    'The last one is FMCA Threshold with Coaching. This is what your score could be on the next update if you follow all action plans provided by Asfaleia and stay on top of drivers and violations they rack up. It is the responsibility of both the company and drivers to achieve this.',
  ],
  thresholdGraph: [
    'The percentile graphs are a forecast of how your score will change month to month if you address violations with drivers based on taking concrete steps provided by Asfaleia.',
  ],
  viewReportGraph: [
    'This graph here shows the violations that were racked up by day and the severity weight of them. You can get multiple violations in a single day if so they will be shown as SW1 (which is the 1st violation), SW2 (which is the 2nd violation), SW3 (which is 3rd violation) etc… for the specific category of violation you are viewing.',
  ],
  viewPerformanceGraph: [
    'When clicking view performance this graph here will show you are trending day by day over several months on violations for the specific category you are viewing. This will help give you an overview if you are trending up in the wrong direction or down in the right direction.',
    'Some helpful tips are if you see a spike or an update anywhere for the specific category you should investigate what driver(s) lead to that spike and coach them one-on-one to help them avoid these violations.',
  ],
  coachingRoadMap: [
    'The Coaching Road Map area will give you a month to month view of your ISS score on how it can decrease following suggestions from Asfaleia Coaching. You will see gradual progress each month as violations take time to decrease.',
    'ISS score is based on weight and time.',
    'Within 6 months the severity of violation is x3',
    'Within 6 months to 1 year the severity of violation is x2',
    'Within 1 year to 2 years the severity of violation is x1.',
    'After 2 years the violation falls off the record. This is why it’s important to follow best practices as any violations racked up by drivers will have an effect on the company for 2 years and it starts with the company first to help teach their drivers about the importance of this, and this is where Asfaleia comes in and helps companies achieve this mission.',
  ],
  recentInspections: [
    'The Recent Inspections area will showcase the latest inspections/violations received. These inspections show the inspection level, date it happened on, a unique number that shows additional on the carrier, the location it happened in, the driver who got the inspection and the violation score of that inspection if there was a violation found.',
    'The inspection levels are nominated by I, II and III along with green, yellow and red flag.',
    'The “I” will have a green flag as that inspection level falls under the pass category of 1-49 points.',
    'The “II” will have a yellow flag as that inspection level falls under the optional category of 50-74 points.',
    'The “III” will have a red flag as that inspection level falls under the Inspect category of 75-100 points.',
    'To view all inspections simply click “View All” this will let you see all inspections.',
  ],
  topRankDrivers: [
    'The Top Rank Driver area will showcase drivers from best to worse by assigning them a score. The higher the score the better the driver is performing. It is vital to see which drivers are doing good as they can help your company achieve better performance and the company can return the favor by providing more deliveries to better drivers.',
  ],
  inspectionsVisualization: [
    'The roadside inspections visualization tool is provided by the United States Department of Transportation. This tool shows a lot of information for the overall trucking industry. You will be able to see how many inspections and violations happen.',
    'At Asfaleia our goal is to help decrease overall violations by providing visible information to companies and drivers along with helping by coaching. This will not only help companies increase revenue and gain more contracts by having low violations but also helps keep everyone on the roads safe which is our number 1 priority at Asfaleia.',
  ],
  allInspections: [
    "The Inspections area showcases all inspections on record. Here you can view all inspections, filter inspections by start and end date, or filter inspection by a specific driver by simply entering their name. Note: You can also filter a specific driver's inspection by certain dates as well.",
  ],
  allInspectionsTable: [
    'The Inspections area will showcase all inspections/violations received. These inspections show the inspection level, date it happened on, a unique number that shows additional on the carrier, the location it happened in, the driver who got the inspection and the violation score of that inspection if there was a violation found.',
    'To make things easier Asfaleia also shows under violations column what violation they received and how many points they have for that violation. This makes viewing information very quick.',
    'You can also sort each column as well by ascending or descending order. At the bottom on the list you will also be able to see the total number of violation points as well. Note: If you filter data the total violation points will change accordingly.',
  ],
  violationDescPopup: [
    "When clicking on a driver's name you are greeted with all of the drivers violations records with informative information to help your company make decisions and help drivers become better.",
    'The ISS score wheel showcases each category of violations for the specific driver which are: Driver Fitness, Insurance, Unsafe Driving, HOS compliance, Cont. Substances, Crash Indicator, Hazardous Materials and Vehicle Maintenance.',
    'This wheel provides scores based on category. This will allow you to see which categories you need to concentrate on more. For example if a category such as Unsafe driving is red you should target that as soon as possible to bring the score down.',
    'Next you should target the yellow categories followed by green categories. Although green categories mean your standing is in excellent condition it is still important to make sure the numbers are low so you don’t end up going into the yellow. For example if Unsafe driving is at 40 you need to look into it asap as it is getting close to a yellow category which will cause it to go into medium risk.',
    'The goal is to keep our roads safe so having ZERO violations is the target you should set for your company and your drivers.',
    "Asfaleia gives you the opportunity to see how the scores would be if a violation did not occur. Simply uncheck a violation and the wheel will update in realtime. This will help you know which violations to tackle and what the impact will be when they disappear off your company's records.",
  ],
  violationDropDown: [
    'The drop down menu will allow you to select the violation category and display information based on that specific category.',
  ],
  violationReportGraph: [
    'When picking the category you want to view it will show you the graph with the violations that were racked up by day and the severity weight of them. You can get multiple violations in a single day if so they will be shown as SW1 (which is the 1st violation), SW2 (which is the 2nd violation), SW3 (which is 3rd violation) etc… for the specific category of violation you are viewing.',
  ],
  violationsList: [
    'Here you will be able to view a list of all violations that the driver has received and the total violation points of all violations combined.',
    "Asfaleia gives you the opportunity to see how the scores would be if a violation did not occur. Simply uncheck a violation and the wheel will update in realtime. This will help you know which violations to tackle and what the impact will be when they disappear off your company's records.",
    'Another great insight that Asfaleia provides you is that it lists dates on when the points will decrease next and what the points will be on that date. This will help give drivers an idea on how their record will look like after a given period. It is important for drivers not to have violations, as the more violations they have that lead to a bad ISS score, the more likely they are to be pulled over for further inspections.',
  ],
};
