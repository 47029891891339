import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/sms-data/sms-data.reducer';
import React, { useEffect, useState } from 'react';
import {
  ContrSubstMeasure,
  CrashIndicator,
  DriverFitness,
  HazardousMaterials,
  HosCompliance,
  Insurance,
  UnsafeDriving,
  VehicleMaintaince,
} from 'app/config/Icons';
import { useParams } from 'react-router-dom';
import { getEntities as getCarriers, getlogoImage } from 'app/entities/carrier/carrier.reducer';
import { Col, UncontrolledTooltip } from 'reactstrap';
import { getEntities as getLastUpdated } from './../../entities/sms-data-update/sms-data-update.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getSortState } from 'react-jhipster';
import { DESC } from 'app/shared/util/pagination.constants';
import { ThresholdDialog } from '../../modules/components/modals/ThresholdDialog';
import { getColor } from 'app/shared/util/app-utils';
import ViewReportDialog from '../components/modals/ViewReportDialog';
import InformationBadge from '../components/InformationBadge';

type Props = {};

const AnalysisCharts = ({ isCoachingEnabled, type, driverSmsData, modifyPoints }) => {
  const dispatch = useAppDispatch();
  const { dotNumber } = useParams<'dotNumber'>();
  const loading = useAppSelector(state => state.customReducer.loading);
  const [smsDataEntity] = useAppSelector(state => state.smsData.entities);
  const [carrierInfo] = useAppSelector(state => state.carrier.entities);
  const logoUrl = useAppSelector(state => state.carrier.entity.logo);
  const updateSuccess = useAppSelector(state => state.carrier.updateSuccess);
  const smsDataUpdate = useAppSelector(state => state.smsDataUpdate.entities);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 1, 'lastUpdatedOn', DESC), location.search)
  );
  const [smsData, setSmsData] = useState(undefined);
  const [showThresholdDialogModal, setShowThresholdDialogModal] = useState(false);
  const [showViewReportModal, setShowViewReportModal] = useState(false);
  const [item, setItem] = useState({});
  const [isSet, setIsSet] = useState(true);

  useEffect(() => {
    if (loading) return;
    if (type === 'CARRIER') {
      dispatch(getEntities({ query: `dotNumber.equals=${dotNumber}` }));
      dispatch(getCarriers({ query: `dotNumber.equals=${dotNumber}` }));
    } else {
      setSmsData(driverSmsData);
      setIsSet(!isSet);
    }
  }, [loading, dotNumber, updateSuccess, driverSmsData]);

  useEffect(() => {
    dispatch(
      getLastUpdated({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  }, []);

  useEffect(() => {
    if (type === 'CARRIER' && carrierInfo && carrierInfo.logo) {
      dispatch(getlogoImage(carrierInfo.logo));
    }
  }, [carrierInfo]);

  useEffect(() => {
    if (type === 'DRIVER') {
      setSmsData(prevSmsData => {
        const smsDataTemp = prevSmsData ? { ...prevSmsData } : { ...driverSmsData };
        const v = { ...modifyPoints };

        if (v.type && v.type.toLowerCase().includes('hos compliance')) {
          if (v.operation === 'add') smsDataTemp.hosDrivPercentile += v.point;
          else smsDataTemp.hosDrivPercentile -= v.point;
        } else if (v.type && v.type.toLowerCase().includes('driver fitness')) {
          if (v.operation === 'add') smsDataTemp.drivFitPercentile += v.point;
          else smsDataTemp.drivFitPercentile -= v.point;
        } else if (v.type && v.type.toLowerCase().includes('unsafe driving')) {
          if (v.operation === 'add') smsDataTemp.unsafeDrivPercentile += v.point;
          else smsDataTemp.unsafeDrivPercentile -= v.point;
        } else if (v.type && v.type.toLowerCase().includes('vehicle maint.')) {
          if (v.operation === 'add') smsDataTemp.vehMaintPercentile += v.point;
          else smsDataTemp.vehMaintPercentile -= v.point;
        } else if (v.type && v.type.toLowerCase().includes('crash')) {
          if (v.operation === 'add') smsDataTemp.crashIndictorPercentile += v.point;
          else smsDataTemp.crashIndictorPercentile -= v.point;
        } else if (v.type && v.type.includes('hazardous')) {
          if (v.operation === 'add') smsDataTemp.hmcPercentile += v.point;
          else smsDataTemp.hmcPercentile -= v.point;
        }
        return smsDataTemp;
      });
    } else {
      setSmsData(smsDataEntity);
    }
  }, [modifyPoints, smsDataEntity, driverSmsData]);

  const closeThresholdDialogModal = val => {
    setShowThresholdDialogModal(val);
  };

  const closeViewReportModal = val => {
    setShowViewReportModal(val);
  };

  const createBoxesContent = () => {
    const boxes = [
      {
        title: 'Insurance',
        icons: <Insurance />,
        score: smsData && smsData?.insurancePercentile,
      },
      {
        title: 'Unsafe Driving',
        icons: <UnsafeDriving />,
        score: smsData && smsData?.unsafeDrivPercentile,
      },
      {
        title: 'HOS Compliance',
        icons: <HosCompliance />,
        score: smsData && smsData?.hosDrivPercentile,
      },
      {
        title: 'Driver Fitness',
        icons: <DriverFitness />,
        score: smsData && smsData?.drivFitPercentile,
      },
      {
        title: 'ISS Score',
        score: smsData && smsData?.issScore,
      },
      {
        title: 'Cont. Substances',
        icons: <ContrSubstMeasure />,
        score: smsData && smsData?.contrSubstPercentile,
      },
      {
        title: 'Vehicle Maintenance',
        icons: <VehicleMaintaince />,
        score: smsData && smsData?.vehMaintPercentile,
      },
      {
        title: 'Hazardous Materials',
        icons: <HazardousMaterials />,
        score: smsData && smsData?.hmcPercentile,
      },
      {
        title: 'Crash Indicator',
        icons: <CrashIndicator />,
        score: smsData && smsData?.crashIndictorPercentile,
      },
    ];

    return boxes.map(item => {
      return (
        <>
          <li className="itemChartContent" key={`${item.title}${item.score}`}>
            <div
              className="chartData"
              onClick={() => {
                closeThresholdDialogModal(type === 'CARRIER' && item.title !== 'ISS Score' && item.title !== 'Insurance' ? true : false);
                setItem(item);
              }}
            >
              <div className="itemIcon">{item.icons}</div>
              {item.title === 'ISS Score' ? (
                <div className="itemScore" style={{ color: getColor(item.score) }}>
                  {item.score}
                </div>
              ) : (
                <div className="itemScore">{item.score}</div>
              )}
              <div className="itemTitle">{item.title}</div>
              {item.title === 'ISS Score' && (
                <div className="smsUpdate">
                  {smsDataUpdate && smsDataUpdate.length == 1 && (
                    <Col>
                      {/* <div>Carrier: </div> */}
                      <span>Current: {smsDataUpdate[0].lastUpdatedOn}</span>
                      <span id="next-updates">Next Updates</span>
                      <UncontrolledTooltip className="tooltip_design" placement="top" target="next-updates">
                        <ul>
                          {smsDataUpdate[0].nextUpdatesOn &&
                            smsDataUpdate[0].nextUpdatesOn.split(', ').map((date, index) => <li key={index}>{date}</li>)}
                        </ul>
                      </UncontrolledTooltip>
                      <span>{isCoachingEnabled && <InformationBadge title="nextUpdates" />}</span>
                    </Col>
                  )}
                </div>
              )}
            </div>
          </li>
        </>
      );
    });
  };

  return (
    <>
      {type === 'CARRIER' && (
        <>
          {logoUrl && (
            <div className="logo-img">
              <img src={logoUrl}></img>
            </div>
          )}
          <h3
            className="chart-heading md bg-warning rounded px-3 py-2 fs-5"
            style={{ textAlign: 'center', marginBottom: '50px', letterSpacing: '1px' }}
          >
            {carrierInfo && carrierInfo.legalName}
          </h3>
        </>
      )}
      {isCoachingEnabled && <InformationBadge title={type === 'CARRIER' ? 'wheel' : 'violationDescPopup'} />}
      <div className="pieChart" style={{ height: '480px', width: '480px', margin: 'auto' }}>
        <ul className="chartRowContent">{createBoxesContent()}</ul>
        <ul className="chartRowBox">
          <CreateBoxes smsDataEntity={smsData} />
        </ul>
      </div>
      {showThresholdDialogModal && (
        <ThresholdDialog
          closeThresholdDialogModal={closeThresholdDialogModal}
          closeViewReportModal={closeViewReportModal}
          item={item}
          smsDataUpdate={smsDataUpdate && smsDataUpdate}
          isCoachingEnabled={isCoachingEnabled}
        />
      )}
      {showViewReportModal && (
        <ViewReportDialog
          isCoachingEnabled={isCoachingEnabled}
          closeViewReportModal={closeViewReportModal}
          item={item}
          dotNumber={dotNumber}
          smsDataUpdate={smsDataUpdate && smsDataUpdate}
          smsData={smsData}
        />
      )}
    </>
  );
};

const CreateBoxes = ({ smsDataEntity }) => {
  const boxes = [
    {
      score: smsDataEntity?.insurancePercentile,
    },
    {
      score: smsDataEntity?.unsafeDrivPercentile,
    },
    {
      score: smsDataEntity?.hosDrivPercentile,
    },
    {
      score: smsDataEntity?.drivFitPercentile,
    },
    { score: smsDataEntity?.issScore },
    {
      score: smsDataEntity?.contrSubstPercentile,
    },
    {
      score: smsDataEntity?.vehMaintPercentile,
    },
    {
      score: smsDataEntity?.hmcPercentile,
    },
    {
      score: smsDataEntity?.crashIndictorPercentile,
    },
  ];

  return (
    <>
      {boxes.map((item, idx) => {
        return (
          <li className="itemChart" key={`${Math.random()}`}>
            <div className="chartDataInnerBox" style={{ backgroundColor: getColor(item.score) }}></div>
          </li>
        );
      })}
    </>
  );
};

export default AnalysisCharts;
