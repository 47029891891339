import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDriver } from 'app/shared/model/driver.model';
import { getEntities as getDrivers } from 'app/entities/driver/driver.reducer';
import { IVehicle } from 'app/shared/model/vehicle.model';
import { getEntities as getVehicles } from 'app/entities/vehicle/vehicle.reducer';
import { ICarrier } from 'app/shared/model/carrier.model';
import { getEntities as getCarriers } from 'app/entities/carrier/carrier.reducer';
import { IInspection } from 'app/shared/model/inspection.model';
import { getEntity, updateEntity, createEntity, reset } from './inspection.reducer';

export const InspectionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const drivers = useAppSelector(state => state.driver.entities);
  const vehicles = useAppSelector(state => state.vehicle.entities);
  const carriers = useAppSelector(state => state.carrier.entities);
  const inspectionEntity = useAppSelector(state => state.inspection.entity);
  const loading = useAppSelector(state => state.inspection.loading);
  const updating = useAppSelector(state => state.inspection.updating);
  const updateSuccess = useAppSelector(state => state.inspection.updateSuccess);

  const handleClose = () => {
    navigate('/inspection' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDrivers({}));
    dispatch(getVehicles({}));
    dispatch(getCarriers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...inspectionEntity,
      ...values,
      drivers: mapIdList(values.drivers),
      vehicles: mapIdList(values.vehicles),
      carrier: carriers.find(it => it.id.toString() === values.carrier.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...inspectionEntity,
          createdDate: convertDateTimeFromServer(inspectionEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(inspectionEntity.lastModifiedDate),
          drivers: inspectionEntity?.drivers?.map(e => e.id.toString()),
          vehicles: inspectionEntity?.vehicles?.map(e => e.id.toString()),
          carrier: inspectionEntity?.carrier?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="asfaleiaApp.inspection.home.createOrEditLabel" data-cy="InspectionCreateUpdateHeading">
            <Translate contentKey="asfaleiaApp.inspection.home.createOrEditLabel">Create or edit a Inspection</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="inspection-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('asfaleiaApp.inspection.drFitnessViol')}
                id="inspection-drFitnessViol"
                name="drFitnessViol"
                data-cy="drFitnessViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.timeWeight')}
                id="inspection-timeWeight"
                name="timeWeight"
                data-cy="timeWeight"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitMake')}
                id="inspection-unitMake"
                name="unitMake"
                data-cy="unitMake"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.hmViol')}
                id="inspection-hmViol"
                name="hmViol"
                data-cy="hmViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.uniqueId')}
                id="inspection-uniqueId"
                name="uniqueId"
                data-cy="uniqueId"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.hmInsp')}
                id="inspection-hmInsp"
                name="hmInsp"
                data-cy="hmInsp"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.fatiguedViol')}
                id="inspection-fatiguedViol"
                name="fatiguedViol"
                data-cy="fatiguedViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.inspLevelId')}
                id="inspection-inspLevelId"
                name="inspLevelId"
                data-cy="inspLevelId"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.dotNumber')}
                id="inspection-dotNumber"
                name="dotNumber"
                data-cy="dotNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.oosTotal')}
                id="inspection-oosTotal"
                name="oosTotal"
                data-cy="oosTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.basicViol')}
                id="inspection-basicViol"
                name="basicViol"
                data-cy="basicViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.countyCodeState')}
                id="inspection-countyCodeState"
                name="countyCodeState"
                data-cy="countyCodeState"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.vhMaintViol')}
                id="inspection-vhMaintViol"
                name="vhMaintViol"
                data-cy="vhMaintViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitMake2')}
                id="inspection-unitMake2"
                name="unitMake2"
                data-cy="unitMake2"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.subtAlcoholInsp')}
                id="inspection-subtAlcoholInsp"
                name="subtAlcoholInsp"
                data-cy="subtAlcoholInsp"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.inspDate')}
                id="inspection-inspDate"
                name="inspDate"
                data-cy="inspDate"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unsafeInsp')}
                id="inspection-unsafeInsp"
                name="unsafeInsp"
                data-cy="unsafeInsp"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitLicense')}
                id="inspection-unitLicense"
                name="unitLicense"
                data-cy="unitLicense"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitDecalNumber')}
                id="inspection-unitDecalNumber"
                name="unitDecalNumber"
                data-cy="unitDecalNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.drFitnessInsp')}
                id="inspection-drFitnessInsp"
                name="drFitnessInsp"
                data-cy="drFitnessInsp"
                type="text"
              />
              <ValidatedField label={translate('asfaleiaApp.inspection.vin')} id="inspection-vin" name="vin" data-cy="vin" type="text" />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.vin2')}
                id="inspection-vin2"
                name="vin2"
                data-cy="vin2"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitLicenseState2')}
                id="inspection-unitLicenseState2"
                name="unitLicenseState2"
                data-cy="unitLicenseState2"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.subtAlcoholViol')}
                id="inspection-subtAlcoholViol"
                name="subtAlcoholViol"
                data-cy="subtAlcoholViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.reportNumber')}
                id="inspection-reportNumber"
                name="reportNumber"
                data-cy="reportNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitDecalNumber2')}
                id="inspection-unitDecalNumber2"
                name="unitDecalNumber2"
                data-cy="unitDecalNumber2"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitTypeDesc2')}
                id="inspection-unitTypeDesc2"
                name="unitTypeDesc2"
                data-cy="unitTypeDesc2"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.driverOosTotal')}
                id="inspection-driverOosTotal"
                name="driverOosTotal"
                data-cy="driverOosTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.totalHazmatSent')}
                id="inspection-totalHazmatSent"
                name="totalHazmatSent"
                data-cy="totalHazmatSent"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.reportState')}
                id="inspection-reportState"
                name="reportState"
                data-cy="reportState"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitLicense2')}
                id="inspection-unitLicense2"
                name="unitLicense2"
                data-cy="unitLicense2"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.hazmatOosTotal')}
                id="inspection-hazmatOosTotal"
                name="hazmatOosTotal"
                data-cy="hazmatOosTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.vhMaintInsp')}
                id="inspection-vhMaintInsp"
                name="vhMaintInsp"
                data-cy="vhMaintInsp"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.hazmatPlacardReq')}
                id="inspection-hazmatPlacardReq"
                name="hazmatPlacardReq"
                data-cy="hazmatPlacardReq"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitTypeDesc')}
                id="inspection-unitTypeDesc"
                name="unitTypeDesc"
                data-cy="unitTypeDesc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unsafeViol')}
                id="inspection-unsafeViol"
                name="unsafeViol"
                data-cy="unsafeViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.vehicleOosTotal')}
                id="inspection-vehicleOosTotal"
                name="vehicleOosTotal"
                data-cy="vehicleOosTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.unitLicenseState')}
                id="inspection-unitLicenseState"
                name="unitLicenseState"
                data-cy="unitLicenseState"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.fatiguedInsp')}
                id="inspection-fatiguedInsp"
                name="fatiguedInsp"
                data-cy="fatiguedInsp"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.createdBy')}
                id="inspection-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.createdDate')}
                id="inspection-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.lastModifiedBy')}
                id="inspection-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.lastModifiedDate')}
                id="inspection-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('asfaleiaApp.inspection.driver')}
                id="inspection-driver"
                data-cy="driver"
                type="select"
                multiple
                name="drivers"
              >
                <option value="" key="0" />
                {drivers
                  ? drivers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('asfaleiaApp.inspection.vehicle')}
                id="inspection-vehicle"
                data-cy="vehicle"
                type="select"
                multiple
                name="vehicles"
              >
                <option value="" key="0" />
                {vehicles
                  ? vehicles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="inspection-carrier"
                name="carrier"
                data-cy="carrier"
                label={translate('asfaleiaApp.inspection.carrier')}
                type="select"
              >
                <option value="" key="0" />
                {carriers
                  ? carriers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.dotNumber}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/inspection" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InspectionUpdate;
