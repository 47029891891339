import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCoachingPerformanceForCarrier } from 'app/shared/reducers/custom-reducer';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import InformationBadge from '../components/InformationBadge';
import { DownIcon, UpIcon } from 'app/assets/svg-icon/icon';

const CoachingPerformanceOverview = ({ isCoachingEnabled }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dotNumber } = useParams<'dotNumber'>();
  const violations = useAppSelector(state => state.violation.entities);
  const overview = useAppSelector(state => state.customReducer.coachingPerformance);

  useEffect(() => {
    dispatch(getCoachingPerformanceForCarrier(dotNumber));
  }, []);

  useEffect(() => {
    if (violations && violations.length > 0) {
      var violTemp = {};
      violations.map((viol, i) => {
        if (viol.basicDesc) {
          if (violTemp[viol.basicDesc]) {
            violTemp[viol.basicDesc] = violTemp[viol.basicDesc] + Number(viol.severityWeight) * Number(viol.inspection?.timeWeight);
          } else {
            violTemp[viol.basicDesc] = Number(viol.severityWeight) * Number(viol.inspection?.timeWeight);
          }
        }
      });
    }
  }, [violations]);

  return (
    <div className="coaching-overview">
      <h5>Coaching Performance Overview</h5>
      <span className="heading">Following require your attention:</span>
      <ul>
        {overview && (
          <>
            {overview.highRiskDrivers != 0 && (
              <span>
                <li key="highRiskDrivers">
                  <b>{overview.highRiskDrivers}</b> of your {overview.highRiskDrivers == 1 ? 'driver is' : 'drivers are'} high risk.{' '}
                  {isCoachingEnabled && <InformationBadge title="highRiskDrivers" />}
                </li>
              </span>
            )}
            {overview.mediumRiskDrivers != 0 && (
              <span>
                <li key="mediumRiskDrivers">
                  <b>{overview.mediumRiskDrivers}</b> of your {overview.mediumRiskDrivers == 1 ? 'driver is' : 'drivers are'} medium risk.{' '}
                  {isCoachingEnabled && <InformationBadge title="mediumRiskDrivers" />}
                </li>
              </span>
            )}
            <span>
              <li className={overview.isViolationsIncreased == true ? 'uparrow' : 'downarrow'} key="isViolationsIncreased">
                Your companies driver violations trend is {overview.isViolationsIncreased == true ? <UpIcon /> : <DownIcon />}.{' '}
                {isCoachingEnabled && <InformationBadge title="isViolationsIncreased" />}
              </li>
            </span>
            <span>
              <li className={overview.issScoreIncreased == true ? 'uparrow' : 'downarrow'} key="issScoreIncreased">
                Your company ISS score trend {overview.issScoreIncreased == true ? <UpIcon /> : <DownIcon />}.{' '}
                {isCoachingEnabled && <InformationBadge title="issScoreIncreased" />}
              </li>
            </span>
            {overview.highRiskViolations &&
              overview.highRiskViolations.map((viol, i) => {
                return (
                  <span>
                    <li key={'highRiskViolations' + i}>
                      Your company has a high risk of <b>{viol.basicDesc}</b> violations.{' '}
                      {isCoachingEnabled && <InformationBadge title="highRiskViolations" />}
                    </li>
                  </span>
                );
              })}
            {overview.mediumRiskViolations &&
              overview.mediumRiskViolations.map((viol, i) => {
                return (
                  <span>
                    <li key={'mediumRiskViolations' + i}>
                      Your company has a medium risk of <b>{viol.basicDesc}</b> violations.{' '}
                      {isCoachingEnabled && <InformationBadge title="mediumRiskViolations" />}
                    </li>
                  </span>
                );
              })}
            {overview.risingViolations &&
              overview.risingViolations.map((viol, i) => {
                return (
                  <span>
                    <li key={'risingViolations' + i}>
                      Your <b>{viol.basicDesc}</b> violations are rising.{' '}
                      {isCoachingEnabled && <InformationBadge title="issScoreIncreased" />}
                    </li>
                  </span>
                );
              })}
          </>
        )}
      </ul>
      <Button className="btn-yellow inspections-btn" color="primary">
        Click For Coaching
      </Button>
    </div>
  );
};

export default CoachingPerformanceOverview;
