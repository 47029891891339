import dayjs from 'dayjs';

export interface ISmsData {
  id?: number;
  vehMaintAc?: string | null;
  driverInspTotal?: string | null;
  dotNumber?: string | null;
  hosDrivInspWViol?: string | null;
  issScore?: number | null;
  vehMaintMeasure?: number | null;
  drivFitMeasure?: number | null;
  drivFitAc?: string | null;
  hosDrivMeasure?: number | null;
  insurancePercentile?: number | null;
  crashIndictorMeasure?: number | null;
  crashIndictorPercentile?: number | null;
  contrSubstMeasure?: number | null;
  unsafeDrivInspWViol?: string | null;
  drivFitInspWViol?: string | null;
  unsafeDrivAc?: string | null;
  drivFitPercentile?: number | null;
  hosDrivPercentile?: number | null;
  driverOosInspTotal?: string | null;
  contrInspWViol?: string | null;
  contrSubstSv?: string | null;
  vehicleInspTotal?: string | null;
  contrSubstPercentile?: number | null;
  vehMaintPercentile?: number | null;
  hosComplianceAc?: string | null;
  inspTotal?: string | null;
  unsafeDrivPercentile?: number | null;
  unsafeDrivMeasure?: number | null;
  vehMaintInspWViol?: string | null;
  insuranceMeasure?: number | null;
  vehicleOosInspTotal?: string | null;
  hmcPercentile?: number | null;
  hmcMeasure?: number | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export const defaultValue: Readonly<ISmsData> = {};
