import dayjs from 'dayjs';
import { IDriver } from 'app/shared/model/driver.model';
import { IVehicle } from 'app/shared/model/vehicle.model';
import { ICarrier } from 'app/shared/model/carrier.model';

export interface ICrash {
  id?: number;
  hazmatReleased?: string | null;
  dotNumber?: string | null;
  reportNumber?: string | null;
  severityTimeWeight?: string | null;
  vehicleLicenseSate?: string | null;
  vehicleIdNumber?: string | null;
  accessControlDesc?: string | null;
  trafficwayDesc?: string | null;
  vehicleLicenseNumber?: string | null;
  notPreventable?: string | null;
  lightConditionDesc?: string | null;
  fatalities?: string | null;
  citationIssuedDesc?: string | null;
  timeWeight?: string | null;
  roadSurfaceConditionDesc?: string | null;
  severityWeight?: string | null;
  weatherConditionDesc?: string | null;
  seqNum?: string | null;
  towAway?: string | null;
  injuries?: string | null;
  reportDate?: string | null;
  reportState?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  driver?: IDriver | null;
  vehicle?: IVehicle | null;
  carrier?: ICarrier | null;
}

export const defaultValue: Readonly<ICrash> = {};
