import dayjs from 'dayjs';

export interface ISmsDataUpdate {
  id?: number;
  lastUpdatedOn?: string;
  nextUpdatesOn?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export const defaultValue: Readonly<ISmsDataUpdate> = {};
