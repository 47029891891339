import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISmsData } from 'app/shared/model/sms-data.model';
import { getEntity, updateEntity, createEntity, reset } from './sms-data.reducer';

export const SmsDataUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const smsDataEntity = useAppSelector(state => state.smsData.entity);
  const loading = useAppSelector(state => state.smsData.loading);
  const updating = useAppSelector(state => state.smsData.updating);
  const updateSuccess = useAppSelector(state => state.smsData.updateSuccess);

  const handleClose = () => {
    navigate('/sms-data' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...smsDataEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...smsDataEntity,
          createdDate: convertDateTimeFromServer(smsDataEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(smsDataEntity.lastModifiedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="asfaleiaApp.smsData.home.createOrEditLabel" data-cy="SmsDataCreateUpdateHeading">
            <Translate contentKey="asfaleiaApp.smsData.home.createOrEditLabel">Create or edit a SmsData</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="sms-data-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('asfaleiaApp.smsData.driverInspTotal')}
                id="sms-data-driverInspTotal"
                name="driverInspTotal"
                data-cy="driverInspTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.dotNumber')}
                id="sms-data-dotNumber"
                name="dotNumber"
                data-cy="dotNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.vehMaintAc')}
                id="sms-data-vehMaintAc"
                name="vehMaintAc"
                data-cy="vehMaintAc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.drivFitAc')}
                id="sms-data-drivFitAc"
                name="drivFitAc"
                data-cy="drivFitAc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.hosDrivMeasure')}
                id="sms-data-hosDrivMeasure"
                name="hosDrivMeasure"
                data-cy="hosDrivMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.insurancePercentile')}
                id="sms-data-insurancePercentile"
                name="insurancePercentile"
                data-cy="insurancePercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.unsafeDrivInspWViol')}
                id="sms-data-unsafeDrivInspWViol"
                name="unsafeDrivInspWViol"
                data-cy="unsafeDrivInspWViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.unsafeDrivAc')}
                id="sms-data-unsafeDrivAc"
                name="unsafeDrivAc"
                data-cy="unsafeDrivAc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.vehMaintPercentile')}
                id="sms-data-vehMaintPercentile"
                name="vehMaintPercentile"
                data-cy="vehMaintPercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.inspTotal')}
                id="sms-data-inspTotal"
                name="inspTotal"
                data-cy="inspTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.hmcMeasure')}
                id="sms-data-hmcMeasure"
                name="hmcMeasure"
                data-cy="hmcMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.unsafeDrivPercentile')}
                id="sms-data-unsafeDrivPercentile"
                name="unsafeDrivPercentile"
                data-cy="unsafeDrivPercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.drivFitPercentile')}
                id="sms-data-drivFitPercentile"
                name="drivFitPercentile"
                data-cy="drivFitPercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.crashIndictorMeasure')}
                id="sms-data-crashIndictorMeasure"
                name="crashIndictorMeasure"
                data-cy="crashIndictorMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.hosDrivPercentile')}
                id="sms-data-hosDrivPercentile"
                name="hosDrivPercentile"
                data-cy="hosDrivPercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.hosDrivInspWViol')}
                id="sms-data-hosDrivInspWViol"
                name="hosDrivInspWViol"
                data-cy="hosDrivInspWViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.driverOosInspTotal')}
                id="sms-data-driverOosInspTotal"
                name="driverOosInspTotal"
                data-cy="driverOosInspTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.vehicleInspTotal')}
                id="sms-data-vehicleInspTotal"
                name="vehicleInspTotal"
                data-cy="vehicleInspTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.contrSubstPercentile')}
                id="sms-data-contrSubstPercentile"
                name="contrSubstPercentile"
                data-cy="contrSubstPercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.drivFitInspWViol')}
                id="sms-data-drivFitInspWViol"
                name="drivFitInspWViol"
                data-cy="drivFitInspWViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.unsafeDrivMeasure')}
                id="sms-data-unsafeDrivMeasure"
                name="unsafeDrivMeasure"
                data-cy="unsafeDrivMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.insuranceMeasure')}
                id="sms-data-insuranceMeasure"
                name="insuranceMeasure"
                data-cy="insuranceMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.drivFitMeasure')}
                id="sms-data-drivFitMeasure"
                name="drivFitMeasure"
                data-cy="drivFitMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.vehMaintInspWViol')}
                id="sms-data-vehMaintInspWViol"
                name="vehMaintInspWViol"
                data-cy="vehMaintInspWViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.vehicleOosInspTotal')}
                id="sms-data-vehicleOosInspTotal"
                name="vehicleOosInspTotal"
                data-cy="vehicleOosInspTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.contrSubstMeasure')}
                id="sms-data-contrSubstMeasure"
                name="contrSubstMeasure"
                data-cy="contrSubstMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.hmcPercentile')}
                id="sms-data-hmcPercentile"
                name="hmcPercentile"
                data-cy="hmcPercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.contrSubstSv')}
                id="sms-data-contrSubstSv"
                name="contrSubstSv"
                data-cy="contrSubstSv"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.contrInspWViol')}
                id="sms-data-contrInspWViol"
                name="contrInspWViol"
                data-cy="contrInspWViol"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.vehMaintMeasure')}
                id="sms-data-vehMaintMeasure"
                name="vehMaintMeasure"
                data-cy="vehMaintMeasure"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.issScore')}
                id="sms-data-issScore"
                name="issScore"
                data-cy="issScore"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.crashIndictorPercentile')}
                id="sms-data-crashIndictorPercentile"
                name="crashIndictorPercentile"
                data-cy="crashIndictorPercentile"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.hosComplianceAc')}
                id="sms-data-hosComplianceAc"
                name="hosComplianceAc"
                data-cy="hosComplianceAc"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.createdBy')}
                id="sms-data-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.createdDate')}
                id="sms-data-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.lastModifiedBy')}
                id="sms-data-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.smsData.lastModifiedDate')}
                id="sms-data-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/sms-data" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SmsDataUpdate;
