import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../../../assets/theme-scss/popup.scss';

export const ComingSoonDialog = ({ closeComingSoonModal }) => {
  const handleClose = () => {
    closeComingSoonModal(false);
  };
  return (
    <Modal isOpen toggle={handleClose} centered size="md" className="coming-popup">
      <ModalHeader toggle={handleClose}></ModalHeader>
      <ModalBody>
        <div className="modal-design">
          <img src="content/images/commingsoon.gif" alt="Logo" />
          <p>Stay Connected! We are implementing this in our New App.</p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ComingSoonDialog;
