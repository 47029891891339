import coachingItem from 'app/entities/coaching-item/coaching-item.reducer';
import carrier from 'app/entities/carrier/carrier.reducer';
import violation from 'app/entities/violation/violation.reducer';
import crash from 'app/entities/crash/crash.reducer';
import smsData from 'app/entities/sms-data/sms-data.reducer';
import vehicle from 'app/entities/vehicle/vehicle.reducer';
import inspection from 'app/entities/inspection/inspection.reducer';
import driver from 'app/entities/driver/driver.reducer';
import userProfile from 'app/entities/user-profile/user-profile.reducer';
import dataQueue from 'app/entities/data-queue/data-queue.reducer';
import smsDataUpdate from 'app/entities/sms-data-update/sms-data-update.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  coachingItem,
  carrier,
  violation,
  crash,
  smsData,
  vehicle,
  inspection,
  driver,
  userProfile,
  dataQueue,
  smsDataUpdate,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
