import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './sms-data-update.reducer';

export const SmsDataUpdateDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const smsDataUpdateEntity = useAppSelector(state => state.smsDataUpdate.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="smsDataUpdateDetailsHeading">
          <Translate contentKey="asfaleiaApp.smsDataUpdate.detail.title">SmsDataUpdate</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{smsDataUpdateEntity.id}</dd>
          <dt>
            <span id="lastUpdatedOn">
              <Translate contentKey="asfaleiaApp.smsDataUpdate.lastUpdatedOn">Last Updated On</Translate>
            </span>
          </dt>
          <dd>
            {smsDataUpdateEntity.lastUpdatedOn ? (
              <TextFormat value={smsDataUpdateEntity.lastUpdatedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="nextUpdatesOn">
              <Translate contentKey="asfaleiaApp.smsDataUpdate.nextUpdatesOn">Next Updates On</Translate>
            </span>
          </dt>
          <dd>{smsDataUpdateEntity.nextUpdatesOn}</dd>
        </dl>
        <Button tag={Link} to="/sms-data-update" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/sms-data-update/${smsDataUpdateEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SmsDataUpdateDetail;
