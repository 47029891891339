import Dashboard from 'app/modules/dashboard';

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { AUTHORITIES } from 'app/config/constants';
import PrivateRoute from 'app/shared/auth/private-route';
import PageNotFound from 'app/shared/error/page-not-found';
import LayoutMode from '../layout/Layout';
// import DashboardForm from '../dashboard/dashboardForm';

const CustomRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route element={<LayoutMode />}>
      <Route
        path="/*"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CustomRoutes;
