import { DownIcon, UpIcon } from 'app/assets/svg-icon/icon';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntityAuditChanges } from 'app/shared/reducers/custom-reducer';
import React, { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart';

export const ScoreGauge = ({ fieldName, type, driverSmsData, previousDriverSmsData }) => {
  const dispatch = useAppDispatch();
  const [carrierSmsData] = useAppSelector(state => state.smsData.entities);
  const [smsData, setSmsData] = useState(undefined);
  const smsDataUpdate = useAppSelector(state => state.smsDataUpdate.entities);
  const smsDataAudit = useAppSelector(state => state.customReducer.entityAuditChanges);
  const [previousSmsData, setPreviousSmsData] = useState(undefined);
  const [changeValue, setChangeValue] = useState(0);
  const [changeDirection, setChangeDirection] = useState('DOWN');
  const [field, setField] = useState('');

  useEffect(() => {
    setSmsData(type === 'DRIVER' ? driverSmsData : carrierSmsData);
    setPreviousSmsData(
      type === 'DRIVER'
        ? previousDriverSmsData
        : smsDataAudit && smsDataAudit[1] && smsDataAudit[1].entityValue && JSON.parse(smsDataAudit[1].entityValue)
    );
  }, [carrierSmsData, driverSmsData]);

  useEffect(() => {
    if (type !== 'DRIVER') {
      dispatch(
        getEntityAuditChanges({
          entityType: 'dev.ac.ms.domain.SmsData',
          entityId: smsData?.id,
        })
      );
    }
  }, [smsData]);

  useEffect(() => {
    setPreviousSmsData(
      type === 'DRIVER'
        ? previousDriverSmsData
        : smsDataAudit && smsDataAudit[1] && smsDataAudit[1].entityValue && JSON.parse(smsDataAudit[1].entityValue)
    );
  }, [smsDataAudit]);

  useEffect(() => {
    if (fieldName) {
      if (fieldName === 'issScore') setField('issScore');
      else if (fieldName === 'Unsafe Driving') setField('unsafeDrivPercentile');
      else if (fieldName === 'HOS Compliance') setField('hosDrivPercentile');
      else if (fieldName === 'Cont. Substances') setField('contrSubstPercentile');
      else if (fieldName === 'Crash Indicator') setField('crashIndictorPercentile');
      else if (fieldName === 'Hazardous Materials') setField('hmcPercentile');
      else if (fieldName === 'Vehicle Maint.') setField('vehMaintPercentile');
      else if (fieldName === 'Driver Fitness') setField('drivFitPercentile');
      else if (fieldName === 'Insurance') setField('insurancePercentile');
    }

    if (smsData && previousSmsData) {
      var current = smsData[field];
      var previous = previousSmsData[field];
      setChangeValue(previous > current ? previous - current : current - previous);
      setChangeDirection(previous > current ? 'DOWN' : 'up');
    }
  }, [previousSmsData, smsData, fieldName]);

  return (
    <div className="scoreGauge">
      <div className="title">Current: {smsDataUpdate[0]?.lastUpdatedOn}</div>
      <GaugeChart
        id={fieldName + '_gauge'}
        nrOfLevels={3}
        arcsLength={[0.5, 0.25, 0.25]}
        // colors={["#2d74da", "#1f57a4", "#25467a"]}
        colors={['#5cc625', '#ffd400', '#d8362f']}
        arcPadding={0.02}
        percent={smsData && (smsData[field] === 0 ? 0 : smsData[field] / 100)}
        textColor={'#000000'}
        needleColor={'#bfbfbf'}
        formatTextValue={value => value}
      />
      <div className="title">
        {changeValue > 0 && (
          <span className={changeDirection === 'DOWN' ? 'downarrow' : 'uparrow'}>
            {fieldName.replace(/([A-Z])/g, ' $1').replace(/^\w/, c => c.toUpperCase())}{' '}
            {changeDirection === 'DOWN' ? <DownIcon /> : <UpIcon />} {changeValue}
          </span>
        )}
      </div>
    </div>
  );
};

export default ScoreGauge;
