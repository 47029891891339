import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Crash from './crash';
import CrashDetail from './crash-detail';
import CrashUpdate from './crash-update';
import CrashDeleteDialog from './crash-delete-dialog';

const CrashRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Crash />} />
    <Route path="new" element={<CrashUpdate />} />
    <Route path=":id">
      <Route index element={<CrashDetail />} />
      <Route path="edit" element={<CrashUpdate />} />
      <Route path="delete" element={<CrashDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CrashRoutes;
