import dayjs from 'dayjs';
import { IViolation } from 'app/shared/model/violation.model';
import { IDriver } from 'app/shared/model/driver.model';
import { IVehicle } from 'app/shared/model/vehicle.model';
import { ICarrier } from 'app/shared/model/carrier.model';

export interface IInspection {
  id?: number;
  unitMake?: string | null;
  drFitnessViol?: string | null;
  timeWeight?: string | null;
  unsafeInsp?: string | null;
  vhMaintViol?: string | null;
  hazmatOosTotal?: string | null;
  vin2?: string | null;
  reportState?: string | null;
  subtAlcoholViol?: string | null;
  hmViol?: string | null;
  subtAlcoholInsp?: string | null;
  unitLicenseState2?: string | null;
  fatiguedInsp?: string | null;
  inspDate?: string | null;
  uniqueId?: string | null;
  driverOosTotal?: string | null;
  drFitnessInsp?: string | null;
  vin?: string | null;
  hmInsp?: string | null;
  unitLicenseState?: string | null;
  reportNumber?: string | null;
  totalHazmatSent?: string | null;
  hazmatPlacardReq?: string | null;
  unitLicense2?: string | null;
  vehicleOosTotal?: string | null;
  fatiguedViol?: string | null;
  inspLevelId?: string | null;
  unitTypeDesc2?: string | null;
  unsafeViol?: string | null;
  unitDecalNumber2?: string | null;
  unitMake2?: string | null;
  dotNumber?: string | null;
  countyCodeState?: string | null;
  unitLicense?: string | null;
  oosTotal?: string | null;
  basicViol?: string | null;
  unitTypeDesc?: string | null;
  unitDecalNumber?: string | null;
  vhMaintInsp?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  violations?: IViolation[] | null;
  drivers?: IDriver[] | null;
  vehicles?: IVehicle[] | null;
  carrier?: ICarrier | null;
}

export const defaultValue: Readonly<IInspection> = {};
