import dayjs from 'dayjs';

export interface IDataQueue {
  id?: number;
  contentType?: string | null;
  dataToLoad?: string | null;
  className?: string | null;
  numberOfRowsToRead?: number | null;
  isProcessed?: boolean | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export const defaultValue: Readonly<IDataQueue> = {
  isProcessed: false,
};
