import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './sms-data.reducer';

export const SmsDataDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const smsDataEntity = useAppSelector(state => state.smsData.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="smsDataDetailsHeading">
          <Translate contentKey="asfaleiaApp.smsData.detail.title">SmsData</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.id}</dd>
          <dt>
            <span id="driverInspTotal">
              <Translate contentKey="asfaleiaApp.smsData.driverInspTotal">Driver Insp Total</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.driverInspTotal}</dd>
          <dt>
            <span id="dotNumber">
              <Translate contentKey="asfaleiaApp.smsData.dotNumber">Dot Number</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.dotNumber}</dd>
          <dt>
            <span id="vehMaintAc">
              <Translate contentKey="asfaleiaApp.smsData.vehMaintAc">Veh Maint Ac</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.vehMaintAc}</dd>
          <dt>
            <span id="drivFitAc">
              <Translate contentKey="asfaleiaApp.smsData.drivFitAc">Driv Fit Ac</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.drivFitAc}</dd>
          <dt>
            <span id="hosDrivMeasure">
              <Translate contentKey="asfaleiaApp.smsData.hosDrivMeasure">Hos Driv Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.hosDrivMeasure}</dd>
          <dt>
            <span id="insurancePercentile">
              <Translate contentKey="asfaleiaApp.smsData.insurancePercentile">Insurance Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.insurancePercentile}</dd>
          <dt>
            <span id="unsafeDrivInspWViol">
              <Translate contentKey="asfaleiaApp.smsData.unsafeDrivInspWViol">Unsafe Driv Insp W Viol</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.unsafeDrivInspWViol}</dd>
          <dt>
            <span id="unsafeDrivAc">
              <Translate contentKey="asfaleiaApp.smsData.unsafeDrivAc">Unsafe Driv Ac</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.unsafeDrivAc}</dd>
          <dt>
            <span id="vehMaintPercentile">
              <Translate contentKey="asfaleiaApp.smsData.vehMaintPercentile">Veh Maint Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.vehMaintPercentile}</dd>
          <dt>
            <span id="inspTotal">
              <Translate contentKey="asfaleiaApp.smsData.inspTotal">Insp Total</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.inspTotal}</dd>
          <dt>
            <span id="hmcMeasure">
              <Translate contentKey="asfaleiaApp.smsData.hmcMeasure">Hmc Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.hmcMeasure}</dd>
          <dt>
            <span id="unsafeDrivPercentile">
              <Translate contentKey="asfaleiaApp.smsData.unsafeDrivPercentile">Unsafe Driv Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.unsafeDrivPercentile}</dd>
          <dt>
            <span id="drivFitPercentile">
              <Translate contentKey="asfaleiaApp.smsData.drivFitPercentile">Driv Fit Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.drivFitPercentile}</dd>
          <dt>
            <span id="crashIndictorMeasure">
              <Translate contentKey="asfaleiaApp.smsData.crashIndictorMeasure">Crash Indictor Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.crashIndictorMeasure}</dd>
          <dt>
            <span id="hosDrivPercentile">
              <Translate contentKey="asfaleiaApp.smsData.hosDrivPercentile">Hos Driv Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.hosDrivPercentile}</dd>
          <dt>
            <span id="hosDrivInspWViol">
              <Translate contentKey="asfaleiaApp.smsData.hosDrivInspWViol">Hos Driv Insp W Viol</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.hosDrivInspWViol}</dd>
          <dt>
            <span id="driverOosInspTotal">
              <Translate contentKey="asfaleiaApp.smsData.driverOosInspTotal">Driver Oos Insp Total</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.driverOosInspTotal}</dd>
          <dt>
            <span id="vehicleInspTotal">
              <Translate contentKey="asfaleiaApp.smsData.vehicleInspTotal">Vehicle Insp Total</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.vehicleInspTotal}</dd>
          <dt>
            <span id="contrSubstPercentile">
              <Translate contentKey="asfaleiaApp.smsData.contrSubstPercentile">Contr Subst Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.contrSubstPercentile}</dd>
          <dt>
            <span id="drivFitInspWViol">
              <Translate contentKey="asfaleiaApp.smsData.drivFitInspWViol">Driv Fit Insp W Viol</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.drivFitInspWViol}</dd>
          <dt>
            <span id="unsafeDrivMeasure">
              <Translate contentKey="asfaleiaApp.smsData.unsafeDrivMeasure">Unsafe Driv Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.unsafeDrivMeasure}</dd>
          <dt>
            <span id="insuranceMeasure">
              <Translate contentKey="asfaleiaApp.smsData.insuranceMeasure">Insurance Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.insuranceMeasure}</dd>
          <dt>
            <span id="drivFitMeasure">
              <Translate contentKey="asfaleiaApp.smsData.drivFitMeasure">Driv Fit Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.drivFitMeasure}</dd>
          <dt>
            <span id="vehMaintInspWViol">
              <Translate contentKey="asfaleiaApp.smsData.vehMaintInspWViol">Veh Maint Insp W Viol</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.vehMaintInspWViol}</dd>
          <dt>
            <span id="vehicleOosInspTotal">
              <Translate contentKey="asfaleiaApp.smsData.vehicleOosInspTotal">Vehicle Oos Insp Total</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.vehicleOosInspTotal}</dd>
          <dt>
            <span id="contrSubstMeasure">
              <Translate contentKey="asfaleiaApp.smsData.contrSubstMeasure">Contr Subst Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.contrSubstMeasure}</dd>
          <dt>
            <span id="hmcPercentile">
              <Translate contentKey="asfaleiaApp.smsData.hmcPercentile">Hmc Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.hmcPercentile}</dd>
          <dt>
            <span id="contrSubstSv">
              <Translate contentKey="asfaleiaApp.smsData.contrSubstSv">Contr Subst Sv</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.contrSubstSv}</dd>
          <dt>
            <span id="contrInspWViol">
              <Translate contentKey="asfaleiaApp.smsData.contrInspWViol">Contr Insp W Viol</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.contrInspWViol}</dd>
          <dt>
            <span id="vehMaintMeasure">
              <Translate contentKey="asfaleiaApp.smsData.vehMaintMeasure">Veh Maint Measure</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.vehMaintMeasure}</dd>
          <dt>
            <span id="issScore">
              <Translate contentKey="asfaleiaApp.smsData.issScore">Iss Score</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.issScore}</dd>
          <dt>
            <span id="crashIndictorPercentile">
              <Translate contentKey="asfaleiaApp.smsData.crashIndictorPercentile">Crash Indictor Percentile</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.crashIndictorPercentile}</dd>
          <dt>
            <span id="hosComplianceAc">
              <Translate contentKey="asfaleiaApp.smsData.hosComplianceAc">Hos Compliance Ac</Translate>
            </span>
          </dt>
          <dd>{smsDataEntity.hosComplianceAc}</dd>
        </dl>
        <Button tag={Link} to="/sms-data" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/sms-data/${smsDataEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SmsDataDetail;
