import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { LinearScale, CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { formatDate, getColor, getMonths, getNumbers, getThreshold } from 'app/shared/util/app-utils';
import { ContSubstances, CrasIndicator, UnsafeDrivin, HazardMaterials, Driver, UnsafeDriving1, VehicMaintenance } from 'app/config/Icons';
import InformationBadge from '../InformationBadge';

Chart.register(LinearScale);
Chart.register(CategoryScale);

export const ThresholdDialog = ({ closeThresholdDialogModal, closeViewReportModal, item, smsDataUpdate, isCoachingEnabled }) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = event => {
    setSelectedYear(parseInt(event.target.value));
  };

  useEffect(() => {}, []);

  const handleClose = () => {
    closeThresholdDialogModal(false);
  };

  const getIcon = title => {
    if (title === 'Unsafe Driving') return <UnsafeDrivin />;
    else if (title === 'HOS Compliance') return <UnsafeDriving1 />;
    else if (title === 'Driver Fitness') return <Driver />;
    else if (title === 'Cont. Substances') return <ContSubstances />;
    else if (title === 'Vehicle Maintenance') return <VehicMaintenance />;
    else if (title === 'Hazardous Materials') return <HazardMaterials />;
    else if (title === 'Crash Indicator') return <CrasIndicator />;
    return null;
  };

  const chartData = {
    labels: getMonths(smsDataUpdate[0] ? smsDataUpdate[0].lastUpdatedOn : []),
    datasets: [
      {
        label: 'Threshold',
        data: getNumbers(item.score, 5),
        backgroundColor: getNumbers(item.score, 5).map(score => {
          return getColor(score);
        }),
        barThickness: 25,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        min: 0,
        max: 100,
        beginAtZero: true,
        ticks: {
          precision: 0,
          stepSize: 20,
          max: 100,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Percentile',
      },
      legend: {
        display: false,
      },
    },
  };

  // Function to draw the horizontal line manually
  const drawThresholdLinePlugin = {
    id: 'drawThresholdLinePlugin',
    afterDatasetsDraw: chart => {
      const { ctx, scales, chartArea } = chart;
      const yScale = scales.y;
      const yPos = yScale.getPixelForValue(getThreshold(item.title)); // The y-axis value where you want to draw the line

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(chartArea.left, yPos);
      ctx.lineTo(chartArea.right, yPos);
      ctx.strokeStyle = getColor(getThreshold(item.title));
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.restore();
    },
  };

  return (
    <>
      <Modal isOpen toggle={handleClose} centered scrollable className="threshold-popup">
        <ModalHeader toggle={handleClose}>
          {getIcon(item.title)}
          {item.title}
        </ModalHeader>
        <ModalBody>
          <div className="modal_designPopup">
            <span>{smsDataUpdate[0] && formatDate(smsDataUpdate[0].lastUpdatedOn)}</span>
            <div className="modal_design1">
              <Input type="select" id="yearSelect" value={selectedYear} onChange={handleYearChange}>
                {Array.from({ length: 10 }, (_, index) => (
                  <option key={index} value={currentYear - index}>
                    {currentYear - index}
                  </option>
                ))}
              </Input>
              <Button color="primary" onClick={() => closeViewReportModal(true)}>
                View Report
              </Button>
            </div>
          </div>
          <ul className="listDesign">
            <li>
              <img src="content/images/pop2.png" alt="Logo" /> FMCSA Threshold{' '}
              <span className="text-right" style={{ color: getColor(getThreshold(item.title)) }}>
                {getThreshold(item.title)}%
              </span>
            </li>
            <li>
              <img src="content/images/pop2.png" alt="Logo" /> FMCSA Current Threshold{' '}
              <span className="text-right" style={{ color: getColor(item.score) }}>
                {isCoachingEnabled && <InformationBadge title="threshold" />}
                {item.score}%
              </span>
            </li>
            <li>
              <img src="content/images/pop2.png" alt="Logo" /> FMCSA Threshold with Coaching{' '}
              <span className="text-right" style={{ color: getColor(22) }}>
                22%
              </span>
            </li>
          </ul>
          <div style={{ width: '450px', height: '250px' }}>
            <Bar data={chartData} options={chartOptions} plugins={[drawThresholdLinePlugin]} />
            {isCoachingEnabled && <InformationBadge title="thresholdGraph" />}
          </div>
          <Button className="btn-popup" color="primary">
            View Updated Score
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ThresholdDialog;
