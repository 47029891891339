import {
  DmvPullNoticeIcon,
  DriverIssIcon,
  DrugConsortiumIcon,
  FmcsaClearinghouseIcon,
  PspIcon,
  SamsaraIconLogo,
  TelematicsIcon,
} from 'app/assets/svg-icon/icon';
import { Updoadsvg } from 'app/config/Icons';
import { AsfaleiaLogo } from 'app/assets/svg-icon/logo';
import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import '../../assets/theme-scss/dashboard-header.scss';
import '../../assets/theme-scss/dashboard-sidebar.scss';
import '../../assets/theme-scss/dashboard-inner-layout.scss';
import { AccountMenu } from 'app/shared/layout/menus';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ContrSubstMeasure } from 'app/config/Icons';
import FmcsaClearingHouseDialog from '../components/modals/FmcsaClearingHouseDialog';
import ComingSoonDialog from '../components/modals/ComingSoonDialog';
import UploadDialog from '../components/modals/UploadDialog';
import Dashboard from '../dashboard/Dashboard';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const LayoutMode = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const isAdmin = useAppSelector(state => state.authentication.account.login === 'admin');
  // console.log('>>>>>> appSelector:', isAdmin);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const stateData = useAppSelector(state => state);
  // console.log(stateData);

  const hrefDashboard = () => {
    if (stateData.smsData.entities.length) {
      const dotNumber = useAppSelector(state => state.smsData.entities[0].dotNumber);
      console.log(dotNumber);
      return `/dashboard/${dotNumber}`;
    } else return `/dashboard`;
  };

  const [showModal, setShowModal] = useState(false);
  const [showComingSoonModal, setShowComingSoonModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const showUploadCarrier = pathname && pathname.split('/') && !isNaN(parseInt(pathname.split('/')[2]));
  const dotNumber = pathname && pathname.split('/') && pathname.split('/')[2];

  const closeModal = val => {
    setShowModal(val);
  };

  const closeComingSoonModal = val => {
    setShowComingSoonModal(val);
  };

  const closeUploadModal = val => {
    setShowUploadModal(val);
  };

  return (
    <div className="wrap">
      <Row>
        <div id="sideBar_Nav">
          <Collapse isOpen={menuOpen} navbar>
            <Navbar>
              <Nav>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      // setShowModal(true);
                      setShowComingSoonModal(true);
                    }}
                  >
                    <FmcsaClearinghouseIcon />
                    <span>FMCSA Clearinghouse</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      setShowComingSoonModal(true);
                    }}
                  >
                    <DmvPullNoticeIcon />
                    <span>DMV Pull Notice</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      setShowComingSoonModal(true);
                    }}
                  >
                    <DrugConsortiumIcon />
                    <span>Drug Consortium</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      setShowComingSoonModal(true);
                    }}
                  >
                    <SamsaraIconLogo />
                    <span>Samsara</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      setShowComingSoonModal(true);
                    }}
                  >
                    <DriverIssIcon />
                    <span>Driver ISS</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => {
                      setShowComingSoonModal(true);
                    }}
                  >
                    <PspIcon />
                    <span>PSP</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Navbar>
          </Collapse>
        </div>

        <div className="inner-wrap">
          <header className="row">
            <Navbar data-cy="navbar" dark expand="md" fixed="top" className="jh-navbar">
              <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
              <NavbarBrand className="ms-auto logo-design" href="/dashboard/" id="UncontrolledTooltipExample">
                <UncontrolledTooltip
                  placement="right"
                  target="UncontrolledTooltipExample"
                  trigger="hover focus"
                  placementPrefix="bs-tooltip"
                  style={{ backgroundColor: '#00072d', color: '#ffc30b', fontWeight: 'bold' }}
                >
                  Enter New DOT
                </UncontrolledTooltip>
                <AsfaleiaLogo />
              </NavbarBrand>
              <Nav className="ms-auto" navbar>
                {isAuthenticated && showUploadCarrier && (
                  <Button
                    className="btn-yellow desktop_btn"
                    color="primary"
                    onClick={() => {
                      setShowUploadModal(true);
                    }}
                  >
                    <Updoadsvg />
                    &ensp;Upload Inspection
                  </Button>
                )}
                <AccountMenu isAdmin={isAdmin} isAuthenticated={isAuthenticated} path={pathname && pathname} />
              </Nav>
            </Navbar>
          </header>
          <div className="dashboarContent">
            <Outlet />
          </div>
        </div>
      </Row>
      {showModal && <FmcsaClearingHouseDialog closeModal={closeModal} pdfUrl={undefined} />}
      {showComingSoonModal && <ComingSoonDialog closeComingSoonModal={closeComingSoonModal} />}
      {showUploadModal && (
        <UploadDialog closeUploadModal={closeUploadModal} dotNumber={dotNumber} uploadType="INSPECTION" fileType={'xml'} />
      )}
      {/* {showUploadModal && (
        <UploadDialog closeUploadModal={closeUploadModal} dotNumber={pathname && pathname.split('/') && pathname.split('/')[2]} />
      )} */}
    </div>
  );

  // return (
  //   <Navbar data-cy="navbar" dark expand="md" fixed="top" className="jh-navbar">
  //     <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
  //     <Collapse isOpen={menuOpen} navbar>
  //       <Nav id="header-tabs" className="ms-auto" navbar>
  //         <AccountMenu isAuthenticated={isAuthenticated} />
  //       </Nav>
  //     </Collapse>
  //   </Navbar>
  // );
};
export default LayoutMode;
