export function getThreshold(item) {
  if (item === 'Unsafe Driving' || item === 'HOS Compliance' || item === 'Crash Indicator') {
    return 65;
  } else if (item === 'Driver Fitness' || item === 'Cont. Substances' || item === 'Vehicle Maintenance' || item === 'Hazardous Materials') {
    return 80;
  } else {
    return 65;
  }
}

export function getColor(score) {
  if (parseInt(score) >= 75) {
    return '#D80000';
  } else if (parseInt(score) >= 50) {
    return '#FFC703';
  } else {
    return '#01C520';
  }
}

export function getColorClass(score) {
  if (parseInt(score) >= 75) {
    return 'red';
  } else if (parseInt(score) >= 50) {
    return 'yellow';
  } else {
    return 'green';
  }
}

export function formatDate(date) {
  const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
}

export function getMonths(date) {
  const months: string[] = [];
  const dateFormatter = new Intl.DateTimeFormat('en', { month: 'short' });
  const currentDate = date instanceof Date ? date : new Date(date);
  for (let i = 0; i < 6; i++) {
    const currentMonth = new Date(currentDate);
    currentMonth.setMonth(currentDate.getMonth() + i);
    months.push(dateFormatter.format(currentMonth).toUpperCase());
  }
  return months;
}

export function getNumbers(startNumber, count) {
  if (startNumber === 0) return [0, 0, 0, 0, 0, 0];
  const result: number[] = [];
  var decrement = startNumber > 70 ? 10 : startNumber > 30 ? 5 : 2;
  for (let i = 0; i < count; i++) {
    result.push(startNumber - i * decrement);
  }
  result.push(22);
  return result;
}

export function generateRandomColor() {
  const minIntensity = 100;
  const maxIntensity = 200;
  const red = Math.floor(Math.random() * (maxIntensity - minIntensity) + minIntensity);
  const green = Math.floor(Math.random() * (maxIntensity - minIntensity) + minIntensity);
  const blue = Math.floor(Math.random() * (maxIntensity - minIntensity) + minIntensity);
  return `rgba(${red}, ${green}, ${blue}, 1)`;
}

// Within 6 month score is x3 | Over 6 to 1 year x2 | 1 year to 2 year x1 | After 2 years it falls off, we keep 2 years of data
export function getUpdatedViolScore(date, score) {
  const currentDate = new Date();
  const providedDate = new Date(date + 'T00:00:00');

  const timeDiff = currentDate.getTime() - providedDate.getTime();
  const monthsDiff = timeDiff / (1000 * 60 * 60 * 24 * 30); // approximate months

  let interval = '';
  let divisor = 1;

  if (monthsDiff <= 6) {
    interval = '6 months or less';
    divisor = 3;
  } else if (monthsDiff <= 12) {
    interval = 'between 6 months and 1 year';
    divisor = 2;
  } else if (monthsDiff <= 24) {
    interval = 'between 1 year and 2 years';
    divisor = 1;
  } else {
    interval = 'more than 2 years';
  }

  const updatedScore = score / divisor;
  const timeToAdd = monthsDiff <= 6 ? 6 : monthsDiff <= 12 ? 12 : monthsDiff <= 24 ? 24 : 0;
  const updatedDate = new Date(providedDate.getTime() + timeToAdd * 30 * 24 * 60 * 60 * 1000);
  const formattedUpdatedDate = updatedDate.toISOString().split('T')[0];

  return `${formattedUpdatedDate} : ${updatedScore}`;
}

export function getTwoMonthsAgoDate() {
  const today = new Date();
  const twoMonthsAgo = new Date(today);
  twoMonthsAgo.setMonth(today.getMonth() - 2);
  return twoMonthsAgo;
}

export function getDriverSmsData(inspections) {
  if (inspections && inspections.length > 0) {
    var smsDataTemp = {
      hosDrivPercentile: 0,
      drivFitPercentile: 0,
      unsafeDrivPercentile: 0,
      vehMaintPercentile: 0,
      crashIndictorPercentile: 0,
      hmcPercentile: 0,
      insurancePercentile: 0,
      issScore: 48,
      contrSubstPercentile: 0,
    };
    var inspsTemp = inspections;
    var violTemp = [];
    var violationPoint = 0;

    var response: any = {
      driverSmsData: smsDataTemp,
      violations: violTemp,
      selectedViolation: Array.from(new Set(violTemp.map(viol => viol.basicDesc)))[0],
      checkedViolations: violTemp,
      violationPoint: violationPoint,
    };

    inspsTemp.map(insp => {
      var severityWeight = 0;
      if (insp.violations && insp.violations.length > 0) {
        insp.violations.map(viol => {
          if (viol.basicDesc && viol.groupDesc) {
            var v = {
              ...viol,
              inspection: insp,
              point: viol.severityWeight * insp.timeWeight,
            };
            violTemp.push(v);
            if (v.basicDesc.toLowerCase().includes('hos compliance')) {
              smsDataTemp.hosDrivPercentile += v.point;
            } else if (v.basicDesc.toLowerCase().includes('driver fitness')) {
              smsDataTemp.drivFitPercentile += v.point;
            } else if (v.basicDesc.toLowerCase().includes('unsafe driving')) {
              smsDataTemp.unsafeDrivPercentile += v.point;
            } else if (v.basicDesc.toLowerCase().includes('vehicle maint.')) {
              smsDataTemp.vehMaintPercentile += v.point;
            } else if (v.basicDesc.toLowerCase().includes('crash')) {
              smsDataTemp.crashIndictorPercentile += v.point;
            } else if (v.basicDesc.includes('hazardous')) {
              smsDataTemp.hmcPercentile += v.point;
            }

            if (Object.values(smsDataTemp).some(value => value > 50)) smsDataTemp.issScore = 60;
          }
          if (viol.severityWeight != null) severityWeight += Number(viol.severityWeight);
        });
        violationPoint += severityWeight * Number(insp.timeWeight);
        response['driverSmsData'] = smsDataTemp;
      }
      response['violations'] = violTemp;
      response['selectedViolation'] = Array.from(new Set(violTemp.map(viol => viol.basicDesc)))[0];
      response['checkedViolations'] = violTemp;
    });
    response['violationPoint'] = violationPoint;
  }
  return response;
}
