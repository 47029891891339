import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import '../../../assets/theme-scss/popup.scss';
import { getlogoImage, uploadLogo } from 'app/entities/carrier/carrier.reducer';
import { getEntities as getCarriers } from 'app/entities/carrier/carrier.reducer';
import { toast } from 'react-toastify';
import { ViewDataIcon } from 'app/assets/svg-icon/icon';
import { uploadInspectionReportXML } from 'app/entities/carrier/carrier.reducer';

export const UploadDialog = ({ closeUploadModal, dotNumber, uploadType, fileType }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(undefined);
  const [carrier] = useAppSelector(state => state.carrier.entities);
  const logoUrl = useAppSelector(state => state.carrier.entity.logo);
  const updateSuccess = useAppSelector(state => state.carrier.updateSuccess);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    if (dotNumber) {
      dispatch(getCarriers({ query: `dotNumber.equals=${dotNumber}` }));
    }
  }, [dotNumber]);

  useEffect(() => {
    if (carrier && carrier.logo) {
      dispatch(getlogoImage(carrier.logo));
    }
  }, [carrier]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      toast.success('Upload Successful');
      // toast.success('Logo saved successfully!!');
      navigate('/dashboard/' + dotNumber);
    }
  }, [updateSuccess]);

  const handleClose = () => {
    closeUploadModal(false);
  };

  const handleChange = e => {
    let fileObj = e.target.files[0];
    console.log('type 1: ', fileObj.type);
    if (fileObj.type.indexOf('image/') === -1) {
      alert('Only Images are allowed.');
      return;
    } else {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadInspection = e => {
    let fileObj = e.target.files[0];
    console.log('type 2: ', fileObj.type);
    if (fileObj.type.indexOf('text/xml') !== -1 || fileObj.type.indexOf('application/') !== -1) {
      console.log('#### setFile');
      setFile(e.target.files[0]);
    } else {
      alert('Please choose a XML file.');
      return;
    }
  };

  const upload = () => {
    if (uploadType === 'CARRIER_LOGO') {
      console.log('file: ', file, 'carrierID: ', carrier.id);
      dispatch(uploadLogo({ file: file, carrierId: carrier && carrier.id }));
    } else if (uploadType === 'INSPECTION') {
      console.log('#### DISPATCHING INSPECTION XML');
      // console.log('file: ', file, 'carrierID: ', carrier.id);
      dispatch(uploadInspectionReportXML({ file: file }));
    } else {
      setShowResult(true);
    }
  };

  return (
    <Modal isOpen toggle={handleClose} centered size="lg" className="upload-popup">
      {uploadType === 'CARRIER_LOGO' ? (
        <ModalHeader toggle={handleClose}>
          {uploadType === 'CARRIER_LOGO' ? 'Upload Logo' : showResult ? 'Result' : 'Image Uploaded'}
        </ModalHeader>
      ) : (
        <ModalHeader toggle={handleClose}>
          {uploadType === 'INSPECTION' ? 'Upload Report' : showResult ? 'Result' : 'XML Uploaded'}
        </ModalHeader>
      )}
      <ModalBody>
        <div className="dashboarContent">
          <div className="main-dashboar-chart row">
            <div className="modal-design">
              {showResult ? (
                <div className="result-popup">
                  <ViewDataIcon />
                  Icon
                  <span>Click here to view impact on your BASIC Safety & Intervention Score</span>
                  <Button color="primary" class="btn-yellow">
                    View Updated Results
                  </Button>
                </div>
              ) : (
                <div>
                  {uploadType === 'CARRIER_LOGO' ? (
                    <>
                      <input type="file" onChange={handleChange} accept="image/*" />
                      {(file || logoUrl) && <img src={file ? URL.createObjectURL(file) : logoUrl} />}
                    </>
                  ) : (
                    <input type="file" onChange={handleUploadInspection} accept="text/xml" />
                  )}
                  <Button disabled={!file} color="primary" class="btn-yellow" onClick={upload}>
                    Upload
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UploadDialog;
