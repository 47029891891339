import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { FlagIcon } from 'app/assets/svg-icon/icon';
import ViolationDescDialog from '../components/modals/ViolationDescDialog';
import InspectionDescDialog from '../components/modals/InspectionDescDialog';
import InformationBadge from '../components/InformationBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { getSortState, JhiItemCount, JhiPagination, Translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { getEntitiesByFilter } from 'app/entities/inspection/inspection.reducer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const InspectionListView = ({ isCoachingEnabled, inspections }) => {
  const [inspectionsList, setInspectionsList] = useState([]);
  const [driverId, setDriverId] = useState(undefined);
  const [showViolDescModal, setShowViolDescModal] = useState(false);
  const loading = useAppSelector(state => state.inspection.loading);
  const [showInspDescModal, setShowInspDescModal] = useState(false);
  const [selectedInsp, setSelectedInsp] = useState(undefined);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'inspDate', DESC), location.search)
  );
  const dispatch = useAppDispatch();
  const { dotNumber } = useParams<'dotNumber'>();
  const [filterData, setFilterData] = useState({ startDate: '', endDate: '', driverName: '', dotNumber: dotNumber });

  const queryParams = {
    page: paginationState.activePage - 1,
    size: paginationState.itemsPerPage,
    sort: `${paginationState.sort},${paginationState.order}`,
  };

  const getAllEntities = () => {
    // dispatch(getSession());
    dispatch(getEntitiesByFilter({ queryParams, filterData }));
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    if (!showViolDescModal && inspections && inspections.length > 0) {
      const slicedInsps = inspections.slice(0, 7);
      var inspsTemp = [];
      slicedInsps.map(data => {
        var severityWeight = 0;
        var timeWeight = 0;
        if (data.violations && data.violations.length > 0) {
          data.violations.map(viol => {
            if (viol.severityWeight != null) severityWeight = severityWeight + Number(viol.severityWeight);
          });
        }
        if (data.timeWeight != null) timeWeight = Number(data.timeWeight);
        const modData = {
          ...data,
          violationPoint: severityWeight * timeWeight,
        };

        inspsTemp.push(modData);
      });
      setInspectionsList(inspsTemp);
    }
  }, [inspections]);

  const closeViolDescModal = val => {
    setShowViolDescModal(val);
  };

  const closeInspDescModal = val => {
    setShowInspDescModal(val);
  };

  const handleViolDescDisplay = val => {
    setDriverId(val);
    setShowViolDescModal(true);
  };

  const handleInspDescDisplay = val => {
    setSelectedInsp(val);
    setShowInspDescModal(true);
  };

  const sort = p => () => {
    // console.log('inside sort() -> ',p)
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  // console.log('paginationState -> ', paginationState);

  return (
    <div className="table-responsive">
      <div className="table-heading dash-design">
        <h3>
          Recent Inspection
          <span>{isCoachingEnabled && <InformationBadge title="recentInspections" />}</span>
        </h3>
        <Link to={'inspections'} state={{ isCoachingEnabled: isCoachingEnabled }}>
          View All{' '}
        </Link>
      </div>

      {inspectionsList ? (
        <Table className="full-table">
          {inspectionsList.length > 0 && (
            <>
              <thead>
                <tr>
                  <th className="hand">
                    <span style={{ cursor: 'pointer' }}></span>
                  </th>
                  <th className="hand">
                    <span style={{ cursor: 'pointer' }} onClick={sort('inspLevelId')}>
                      Insp. Level <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                  <th className="hand">
                    <span style={{ cursor: 'pointer' }} onClick={sort('inspDate')}>
                      Date <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                  <th className="hand">
                    <span style={{ cursor: 'pointer' }} onClick={sort('reportNumber')}>
                      Number <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                  <th className="hand">
                    <span style={{ cursor: 'pointer' }} onClick={sort('reportState')}>
                      State <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                  <th>
                    <span style={{ cursor: 'pointer' }} onClick={sort('firstName')}>
                      Driver Name <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                  <th>
                    <span style={{ cursor: 'pointer' }} onClick={sort('violationPoint')}>
                      Violation Point <FontAwesomeIcon icon="sort" />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {inspectionsList.map((inspection, i) => (
                  <tr key={`${inspection.inspDate}${inspection.reportNumber}`}>
                    <td>
                      <FlagIcon
                        fill={inspection.violationPoint === 0 ? '#01C520' : inspection.violationPoint > 10 ? '#D80000' : '#FFC703'}
                      />
                    </td>
                    <td>
                      {inspection.inspLevelId && (inspection.inspLevelId === '1' ? 'I' : inspection.inspLevelId === '2' ? 'II' : 'III')}
                    </td>
                    <td>{inspection.inspDate}</td>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleInspDescDisplay(inspection);
                      }}
                    >
                      {inspection.reportNumber}
                    </td>
                    <td>{inspection.reportState}</td>
                    <td
                      onClick={() => {
                        handleViolDescDisplay(
                          inspection.drivers &&
                            inspection.drivers.filter(d => d.driverType && d.driverType === 'Primary Driver').map(d => d.id)
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {inspection.drivers &&
                        inspection.drivers
                          .filter(d => d.driverType && d.driverType === 'Primary Driver')
                          .map(d => d.firstName + ' ' + d.lastName)}
                    </td>
                    {/* <td id={`viol-desc-${inspection.id}`}>
                      {inspection.drivers &&
                        inspection.drivers
                          .filter(d => d.driverType && d.driverType === 'Primary Driver')
                          .map(d => d.firstName + ' ' + d.lastName)}
                    </td>
                    <UncontrolledTooltip className="tooltip_design" placement="top" target={`viol-desc-${inspection.id}`}>
                      <ul>
                        {inspection.violations &&
                          inspection.violations.length > 0 &&
                          inspection.violations.map((viol, i) => {
                            return viol.groupDesc != null ? <li>{viol.groupDesc}`</li> : <li>N/A</li>;
                          })}
                      </ul>
                    </UncontrolledTooltip> */}
                    <td>{inspection.violationPoint}</td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </Table>
      ) : (
        !loading && <div className="alert alert-warning">No Inspections found</div>
      )}
      {showViolDescModal && (
        <ViolationDescDialog isCoachingEnabled={isCoachingEnabled} driver={driverId} closeViolDescModal={closeViolDescModal} />
      )}
      {showInspDescModal && (
        <InspectionDescDialog isCoachingEnabled={isCoachingEnabled} inspection={selectedInsp} closeInspDescModal={closeInspDescModal} />
      )}
    </div>
  );
};

export default InspectionListView;
