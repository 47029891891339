import React, { useState } from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import UploadDialog from 'app/modules/components/modals/UploadDialog';
import { Updoadsvg } from 'app/config/Icons';
import { useAppSelector } from 'app/config/store';
import DashboardForm from 'app/modules/dashboard/DashboardForm';
import { useNavigate } from 'react-router-dom';

export const AccountMenu = ({ isAdmin, isAuthenticated, path }) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUploadXMLModal, setShowUploadXMLModal] = useState(false);
  const showUploadCarrier = path && path.split('/') && !isNaN(parseInt(path.split('/')[2]));
  const dotNumber = path && path.split('/') && path.split('/')[2];
  const navigate = useNavigate();
  const UploadDialog1 = UploadDialog;
  const closeUploadModal = val => {
    setShowUploadModal(val);
    setShowUploadXMLModal(val);
    // navigate('/dashboard/form');
  };

  const accountMenuItemsAuthenticated = showUploadCarrier => (
    <>
      <MenuItem icon="wrench" to="/account/settings" data-cy="settings">
        <Translate contentKey="global.menu.account.settings">Settings</Translate>
      </MenuItem>
      <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
        <Translate contentKey="global.menu.account.password">Password</Translate>
      </MenuItem>
      {showUploadCarrier && (
        // <div className={showUploadCarrier ? 'dropdown-item' : ''}>
        <MenuItem
          icon="upload"
          to={path + ''}
          onClick={() => {
            setShowUploadModal(true);
          }}
        >
          Upload Carrier Logo
        </MenuItem>
        // </div>
      )}
      {showUploadCarrier && isAuthenticated && isAdmin && (
        <div className={showUploadCarrier ? 'inactive-menu-item' : ''}>
          <MenuItem
            icon="upload"
            to={path + ''}
            onClick={() => {
              setShowUploadXMLModal(true);
            }}
          >
            Update Carrier Report
          </MenuItem>
        </div>
      )}
      <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
        <Translate contentKey="global.menu.account.logout">Sign out</Translate>
      </MenuItem>
    </>
  );

  const accountMenuItems = () => (
    <>
      <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
        <Translate contentKey="global.menu.account.login">Sign in</Translate>
      </MenuItem>
      <MenuItem icon="user-plus" to="/account/register" data-cy="register">
        <Translate contentKey="global.menu.account.register">Register</Translate>
      </MenuItem>
    </>
  );

  return (
    <>
      <NavDropdown icon="user" name={translate('global.menu.account.main')} id="account-menu" data-cy="accountMenu">
        {/* {isAuthenticated ? accountMenuItemsAuthenticated() : accountMenuItems()} */}
        {accountMenuItemsAuthenticated(showUploadCarrier)}
      </NavDropdown>
      {showUploadModal && (
        <UploadDialog closeUploadModal={closeUploadModal} dotNumber={dotNumber} uploadType="CARRIER_LOGO" fileType={'image/'} />
      )}
      {showUploadXMLModal && (
        <UploadDialog closeUploadModal={closeUploadModal} dotNumber={dotNumber} uploadType="INSPECTION" fileType={'text/'} />
      )}
    </>
  );
};

export default AccountMenu;
