import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/inspection/inspection.reducer';
import { getDriverSmsData, getTwoMonthsAgoDate, getUpdatedViolScore } from 'app/shared/util/app-utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Col, Input, Row } from 'reactstrap';
import AnalysisCharts from '../../dashboard/AnalysisCharts';
import InformationBadge from '../InformationBadge';
import ScoreGauge from '../ScoreGauge';
import ViewReportGraph from '../ViewReportGraph';

export const ViolationDescDialog = ({ isCoachingEnabled, driver, closeViolDescModal }) => {
  const dispatch = useAppDispatch();
  const { dotNumber } = useParams<'dotNumber'>();
  const inspections = useAppSelector(state => state.inspection.entities);
  const [violationPoint, setViolationPoint] = useState(0);
  const [checkedViolations, setCheckedViolations] = useState([]);
  const [violations, setViolations] = useState([]);
  const [modifyPoints, setModifyPoints] = useState(undefined);
  const defaultSmsData = {
    hosDrivPercentile: 0,
    drivFitPercentile: 0,
    unsafeDrivPercentile: 0,
    vehMaintPercentile: 0,
    crashIndictorPercentile: 0,
    hmcPercentile: 0,
    insurancePercentile: 0,
    issScore: 48,
    contrSubstPercentile: 0,
  };
  const [driverSmsData, setDriverSmsData] = useState(defaultSmsData);
  const [previousDriverSmsData, setPreviousDriverSmsData] = useState(undefined);
  const [selectedViolation, setSelectedViolation] = useState(undefined);

  useEffect(() => {
    dispatch(
      getEntities({
        sort: 'inspDate,DESC',
        query: dotNumber ? `dotNumber.equals=${dotNumber}${driver ? `&driverId.equals=${driver}` : ''}` : '',
      })
    );
  }, []);

  useEffect(() => {
    if (inspections && inspections.length > 0) {
      var response = getDriverSmsData(inspections);
      setDriverSmsData(response?.driverSmsData);
      setViolations(response?.violations);
      setSelectedViolation(response?.selectedViolation);
      setCheckedViolations(response?.checkedViolations);
      setViolationPoint(response?.violationPoint);

      const twoMonthsAgoDate = getTwoMonthsAgoDate();
      const previousMonthInspections = inspections.filter(item => {
        const inspectDate = new Date(item.inspDate);
        return inspectDate.getMonth() <= twoMonthsAgoDate.getMonth() && inspectDate.getFullYear() <= twoMonthsAgoDate.getFullYear();
      });
      if (previousMonthInspections && previousMonthInspections.length > 0) {
        var previousMonthResponse = getDriverSmsData(previousMonthInspections);
        setPreviousDriverSmsData(previousMonthResponse?.driverSmsData);
      }
    }
  }, [inspections]);

  const handleClose = () => {
    closeViolDescModal(false);
    setDriverSmsData(defaultSmsData);
  };

  const onViolationCheckChange = viol => {
    var checkedViolationsTemp = [...checkedViolations];
    var inspection = viol.inspection;
    var violPoint = violationPoint;

    if (checkedViolationsTemp.length > 0) {
      const index = checkedViolationsTemp.indexOf(viol);
      if (index > -1) {
        checkedViolationsTemp.splice(index, 1);
        violPoint -= Number(viol.severityWeight) * Number(inspection.timeWeight);
        alterPoints(viol, 'subtract');
      } else {
        checkedViolationsTemp.push(viol);
        violPoint += Number(viol.severityWeight) * Number(inspection.timeWeight);
        alterPoints(viol, 'add');
      }
    } else {
      checkedViolationsTemp.push(viol);
      violPoint += Number(viol.severityWeight) * Number(inspection.timeWeight);
      alterPoints(viol, 'add');
    }
    setViolationPoint(violPoint);
    setCheckedViolations(checkedViolationsTemp);
  };

  const alterPoints = (viol, operation) => {
    setModifyPoints({
      id: viol.id,
      operation: operation,
      type: viol.basicDesc,
      point: Number(viol.severityWeight) * Number(viol.inspection.timeWeight),
    });
  };

  const isChecked = viol => {
    if (checkedViolations.length > 0) {
      if (checkedViolations.indexOf(viol) > -1) return true;
      else return false;
    } else {
      return false;
    }
  };

  const handleViolationChange = e => {
    setSelectedViolation(e.target.value);
  };

  return (
    <Modal isOpen toggle={handleClose} centered size="xl" scrollable>
      <ModalHeader toggle={handleClose}>Violations</ModalHeader>
      <ModalBody>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {violations && violations.length > 0 ? (
            <>
              <Row className="gap violations-modal">
                <Col sm={6} md={6} id="AnalysisChartsUi" className="chart_modal">
                  <AnalysisCharts
                    isCoachingEnabled={isCoachingEnabled}
                    type="DRIVER"
                    driverSmsData={driverSmsData && driverSmsData}
                    modifyPoints={modifyPoints}
                  />
                </Col>
                <Col sm={6} md={6} className="chart_modal">
                  <span>{isCoachingEnabled && <InformationBadge title="violationDropDown" />}</span>
                  <Input className="mb1-4" type="select" value={selectedViolation} onChange={handleViolationChange}>
                    {Array.from(new Set(violations.map(viol => viol.basicDesc))).map(viol => (
                      <option key={viol} value={viol}>
                        {viol}
                      </option>
                    ))}
                  </Input>
                  <ScoreGauge
                    fieldName={selectedViolation}
                    type="DRIVER"
                    driverSmsData={driverSmsData && driverSmsData}
                    previousDriverSmsData={previousDriverSmsData}
                  />
                  <ViewReportGraph
                    violationType={selectedViolation}
                    type="DRIVER"
                    isCoachingEnabled={isCoachingEnabled}
                    violations={violations && violations.filter(v => v.basicDesc === selectedViolation)}
                  />
                </Col>
              </Row>

              <ul className="violDesc">
                <span>{isCoachingEnabled && <InformationBadge title="violationsList" />}</span>
                {violations.map((viol, i) => {
                  return viol.groupDesc != null ? (
                    <li key={viol.id}>
                      <input type="checkbox" checked={isChecked(viol)} onChange={() => onViolationCheckChange(viol)}></input>
                      <b>{viol.basicDesc}</b> : {viol.groupDesc} : <b>{viol.severityWeight * viol.inspection?.timeWeight}</b> :{' '}
                      <b>{viol.inspection?.inspDate}</b> :{' '}
                      <b>{getUpdatedViolScore(viol.inspection?.inspDate, viol.severityWeight * viol.inspection?.timeWeight)}</b>
                    </li>
                  ) : (
                    <li>N/A</li>
                  );
                })}
              </ul>
              <ul className="violDesc">
                <li>
                  <b>Violation Point:</b> {violationPoint}
                </li>
              </ul>
            </>
          ) : (
            <ul className="violDesc">
              <li>No Violation Found.</li>
            </ul>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViolationDescDialog;
