import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICarrier } from 'app/shared/model/carrier.model';
import { getEntity, updateEntity, createEntity, reset } from './carrier.reducer';

export const CarrierUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const carrierEntity = useAppSelector(state => state.carrier.entity);
  const loading = useAppSelector(state => state.carrier.loading);
  const updating = useAppSelector(state => state.carrier.updating);
  const updateSuccess = useAppSelector(state => state.carrier.updateSuccess);

  const handleClose = () => {
    navigate('/carrier' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...carrierEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...carrierEntity,
          createdDate: convertDateTimeFromServer(carrierEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(carrierEntity.lastModifiedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="asfaleiaApp.carrier.home.createOrEditLabel" data-cy="CarrierCreateUpdateHeading">
            <Translate contentKey="asfaleiaApp.carrier.home.createOrEditLabel">Create or edit a Carrier</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="carrier-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('asfaleiaApp.carrier.legalName')}
                id="carrier-legalName"
                name="legalName"
                data-cy="legalName"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.dotNumber')}
                id="carrier-dotNumber"
                name="dotNumber"
                data-cy="dotNumber"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.dbaName')}
                id="carrier-dbaName"
                name="dbaName"
                data-cy="dbaName"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.telephone')}
                id="carrier-telephone"
                name="telephone"
                data-cy="telephone"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.recentMileage')}
                id="carrier-recentMileage"
                name="recentMileage"
                data-cy="recentMileage"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mailingCity')}
                id="carrier-mailingCity"
                name="mailingCity"
                data-cy="mailingCity"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mailingState')}
                id="carrier-mailingState"
                name="mailingState"
                data-cy="mailingState"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.federalGovernment')}
                id="carrier-federalGovernment"
                name="federalGovernment"
                data-cy="federalGovernment"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.carrierOperation')}
                id="carrier-carrierOperation"
                name="carrierOperation"
                data-cy="carrierOperation"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.hmFlag')}
                id="carrier-hmFlag"
                name="hmFlag"
                data-cy="hmFlag"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.vmtSourceId')}
                id="carrier-vmtSourceId"
                name="vmtSourceId"
                data-cy="vmtSourceId"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mailingStreet')}
                id="carrier-mailingStreet"
                name="mailingStreet"
                data-cy="mailingStreet"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mailingCountry')}
                id="carrier-mailingCountry"
                name="mailingCountry"
                data-cy="mailingCountry"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.privateProperty')}
                id="carrier-privateProperty"
                name="privateProperty"
                data-cy="privateProperty"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mcs150Mileage')}
                id="carrier-mcs150Mileage"
                name="mcs150Mileage"
                data-cy="mcs150Mileage"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.privateOnly')}
                id="carrier-privateOnly"
                name="privateOnly"
                data-cy="privateOnly"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mcs150MileageYear')}
                id="carrier-mcs150MileageYear"
                name="mcs150MileageYear"
                data-cy="mcs150MileageYear"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.indianTribe')}
                id="carrier-indianTribe"
                name="indianTribe"
                data-cy="indianTribe"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.privatePassengerNonbusiness')}
                id="carrier-privatePassengerNonbusiness"
                name="privatePassengerNonbusiness"
                data-cy="privatePassengerNonbusiness"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.oicState')}
                id="carrier-oicState"
                name="oicState"
                data-cy="oicState"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.opOther')}
                id="carrier-opOther"
                name="opOther"
                data-cy="opOther"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mailingZip')}
                id="carrier-mailingZip"
                name="mailingZip"
                data-cy="mailingZip"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.phyState')}
                id="carrier-phyState"
                name="phyState"
                data-cy="phyState"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.addDate')}
                id="carrier-addDate"
                name="addDate"
                data-cy="addDate"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.mcs150Date')}
                id="carrier-mcs150Date"
                name="mcs150Date"
                data-cy="mcs150Date"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.localGovernment')}
                id="carrier-localGovernment"
                name="localGovernment"
                data-cy="localGovernment"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.phyCountry')}
                id="carrier-phyCountry"
                name="phyCountry"
                data-cy="phyCountry"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.dotPin')}
                id="carrier-dotPin"
                name="dotPin"
                data-cy="dotPin"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.phyZip')}
                id="carrier-phyZip"
                name="phyZip"
                data-cy="phyZip"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.migrant')}
                id="carrier-migrant"
                name="migrant"
                data-cy="migrant"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.authorizedForHire')}
                id="carrier-authorizedForHire"
                name="authorizedForHire"
                data-cy="authorizedForHire"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.pcFlag')}
                id="carrier-pcFlag"
                name="pcFlag"
                data-cy="pcFlag"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.usMail')}
                id="carrier-usMail"
                name="usMail"
                data-cy="usMail"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.privatePassengerBusiness')}
                id="carrier-privatePassengerBusiness"
                name="privatePassengerBusiness"
                data-cy="privatePassengerBusiness"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.stateGovernment')}
                id="carrier-stateGovernment"
                name="stateGovernment"
                data-cy="stateGovernment"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.recentMileageYear')}
                id="carrier-recentMileageYear"
                name="recentMileageYear"
                data-cy="recentMileageYear"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.phyStreet')}
                id="carrier-phyStreet"
                name="phyStreet"
                data-cy="phyStreet"
                type="text"
              />
              <ValidatedField label={translate('asfaleiaApp.carrier.fax')} id="carrier-fax" name="fax" data-cy="fax" type="text" />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.exemptForHire')}
                id="carrier-exemptForHire"
                name="exemptForHire"
                data-cy="exemptForHire"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.driverTotal')}
                id="carrier-driverTotal"
                name="driverTotal"
                data-cy="driverTotal"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.phyCity')}
                id="carrier-phyCity"
                name="phyCity"
                data-cy="phyCity"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.nbrPowerUnit')}
                id="carrier-nbrPowerUnit"
                name="nbrPowerUnit"
                data-cy="nbrPowerUnit"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.createdBy')}
                id="carrier-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.createdDate')}
                id="carrier-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.lastModifiedBy')}
                id="carrier-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('asfaleiaApp.carrier.lastModifiedDate')}
                id="carrier-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/carrier" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CarrierUpdate;
