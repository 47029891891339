import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Carrier from './carrier';
import CarrierDetail from './carrier-detail';
import CarrierUpdate from './carrier-update';
import CarrierDeleteDialog from './carrier-delete-dialog';

const CarrierRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Carrier />} />
    <Route path="new" element={<CarrierUpdate />} />
    <Route path=":id">
      <Route index element={<CarrierDetail />} />
      <Route path="edit" element={<CarrierUpdate />} />
      <Route path="delete" element={<CarrierDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CarrierRoutes;
