import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { ICarrier } from 'app/shared/model/carrier.model';

export interface IUserProfile {
  id?: number;
  dotNumber?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  internalUser?: IUser | null;
  carrier?: ICarrier | null;
}

export const defaultValue: Readonly<IUserProfile> = {};
