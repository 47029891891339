import { getEntities } from 'app/entities/driver/driver.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, Translate } from 'react-jhipster';
import { useLocation, useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import InformationBadge from '../components/InformationBadge';

interface Props {}
export const DriverListView = ({ isCoachingEnabled }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { dotNumber } = useParams<'dotNumber'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [rankedDrivers, setRankedDrivers] = useState([]);
  const dataList = useAppSelector(state => state.driver.entities);
  const loading = useAppSelector(state => state.driver.loading);
  const updatedSmsData = useAppSelector(state => state.customReducer.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: 200,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: dotNumber ? `dotNumber.equals=${dotNumber}&driverType.equals=Primary Driver` : '',
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, dotNumber, updatedSmsData]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      var drivers = [];
      dataList.map(data => {
        var violationPoint = 0;
        if (data && data.inspections && data.inspections.length > 0) {
          var sumTotalWeight = 0;
          data.inspections.map(insp => {
            var severityWeight = 0;
            var timeWeight = 0;
            if (insp.violations && insp.violations.length > 0) {
              insp.violations.map(viol => {
                if (viol.severityWeight != null) severityWeight = severityWeight + Number(viol.severityWeight);
              });
            }
            if (insp.timeWeight != null) timeWeight = Number(insp.timeWeight);

            sumTotalWeight = sumTotalWeight + severityWeight * timeWeight;
          });
          violationPoint = (sumTotalWeight / 5.1) | 0;
        }

        const modData = {
          ...data,
          violationPoint: violationPoint,
        };
        drivers.push(modData);
      });

      drivers.sort(function (a, b) {
        return a.violationPoint - b.violationPoint;
      });
      var d = drivers;
      drivers.length = 7;
      setRankedDrivers(drivers);
    }
  }, [dataList]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
    });
  };

  return (
    <div className="table-responsive">
      <div className="table-heading">
        <h3>
          Top Rank Drivers
          <span>{isCoachingEnabled && <InformationBadge title="topRankDrivers" />}</span>
        </h3>
      </div>
      {rankedDrivers ? (
        <Table>
          {rankedDrivers.length > 0 && (
            <>
              <thead>
                <tr>
                  <th className="hand">
                    <div>Driver Name</div>
                  </th>
                  <th className="hand">
                    <div>Score</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rankedDrivers.map((data, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td className="display-align">
                      <span className="count">{Number(i) + 1}.</span>
                      <div className="profile_pic">
                        {i === 2 || i === 6 || i === 7 ? (
                          data.firstName && data.firstName.charAt(0).toUpperCase()
                        ) : (
                          <img src={`content/images/driver-images/driver-image-${i + 1}.jpg`} alt=""></img>
                        )}
                      </div>
                      {data.firstName} {data.lastName}
                    </td>
                    <td>{data.issScore}</td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </Table>
      ) : (
        !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="conductorApp.product.home.notFound">No Products found</Translate>
          </div>
        )
      )}
    </div>
  );
};

export default DriverListView;
