import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CoachingItem from './coaching-item';
import CoachingItemDetail from './coaching-item-detail';
import CoachingItemUpdate from './coaching-item-update';
import CoachingItemDeleteDialog from './coaching-item-delete-dialog';

const CoachingItemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CoachingItem />} />
    <Route path="new" element={<CoachingItemUpdate />} />
    <Route path=":id">
      <Route index element={<CoachingItemDetail />} />
      <Route path="edit" element={<CoachingItemUpdate />} />
      <Route path="delete" element={<CoachingItemDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CoachingItemRoutes;
