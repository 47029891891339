import InspectionListView from './InspectionListView';
import CoachingItemListView from './CoachingItemListView';
import DriverListView from './DriverListView';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Button, Col, Input, Label, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import AnalysisCharts from './AnalysisCharts';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import '../../assets/theme-scss/analysis-charts-ui.scss';
import { getSortState, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { UpdateSmsData } from 'app/shared/reducers/custom-reducer';
import DashboardForm from './DashboardForm';
import { ViewDataIcon } from 'app/assets/svg-icon/icon';
import { toast } from 'react-toastify';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { DESC } from 'app/shared/util/pagination.constants';
import FmcsaClearingHouseDialog from '../components/modals/FmcsaClearingHouseDialog';
import { Toolsvg, Inspectionsvg } from 'app/config/Icons';
import InformationBadge from '../components/InformationBadge';
import CoachingPerformanceOverview from './CoachingPerformanceOverview';
import { getEntities } from 'app/entities/inspection/inspection.reducer';
import { getEntities as getLastUpdated } from '../../entities/sms-data-update/sms-data-update.reducer';
import { getEntities as getSmsData } from '../../entities/sms-data/sms-data.reducer';
import ScoreGauge from '../components/ScoreGauge';

interface Props {}
export const Dashboard = (props: Props) => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.authentication.account);
  const updateSuccess = useAppSelector(state => state.customReducer.updateSuccess);
  const errorMessage = useAppSelector(state => state.customReducer.errorMessage);
  const [inital, setInitial] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 1, 'lastUpdatedOn', DESC), location.search)
  );
  const [showModal, setShowModal] = useState(false);
  const [isCoachingEnabled, setIsCoachingEnabled] = useState(undefined);
  const { dotNumber } = useParams<'dotNumber'>();
  const inspections = useAppSelector(state => state.inspection.entities);
  const smsDataUpdate = useAppSelector(state => state.smsDataUpdate.entities);
  const [smsDataEntity] = useAppSelector(state => state.smsData.entities);
  const [showModal2, setShowModal2] = useState(false);
  const stateData = useAppSelector(state => state);
  useEffect(() => {
    dispatch(
      getEntities({
        sort: `inspDate,${DESC}`,
        query: dotNumber ? `dotNumber.equals=${dotNumber}` : '',
      })
    );
    dispatch(getSmsData({ query: `dotNumber.equals=${dotNumber}` }));
    dispatch(
      getLastUpdated({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
    if (localStorage.getItem('isCoachingEnabled') === null) {
      localStorage.setItem('isCoachingEnabled', JSON.stringify(true));
      setIsCoachingEnabled(true);
    } else {
      localStorage.setItem('isCoachingEnabled', JSON.parse(localStorage.getItem('isCoachingEnabled')));
      setIsCoachingEnabled(JSON.parse(localStorage.getItem('isCoachingEnabled')));
    }
  }, []);

  useEffect(() => {
    if (!inital && updateSuccess) {
      setIsSubmitted(false);
      toast.success('Data updated successfully!!');
    } else if (!inital && updateSuccess === false && errorMessage != null) {
      setIsSubmitted(false);
      return;
    }
  }, [updateSuccess]);

  const closeModal = val => {
    setShowModal(val);
  };

  const closeModal2 = val => {
    setShowModal2(val);
  };

  const handleScroll = () => {
    const section = document.getElementById('vizContainer');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll2 = () => {
    const section = document.getElementById('vizContainer2');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleSwitch = () => {
    var isEnabled = !isCoachingEnabled;
    setIsCoachingEnabled(isEnabled);
    localStorage.setItem('isCoachingEnabled', JSON.stringify(isEnabled));
  };

  /*
  const [carrierData, setCarrierData] = useState(null);
  const [error, setError] = useState(null);
  const fetchData = async () => {
    console.log('Fetching data');
    try {
      console.log('Fetching data try');
      const response = await fetch('https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=3168248');
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      const data = await response.text(); // Assuming response is text-based
      // You might need to parse JSON data using JSON.parse(data) if the response is JSON
      console.log(data);
      setCarrierData(data);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []); 
  */

  return (
    <>
      <Row className="main-dashboar-chart">
        <Col sm={6} md={3} className="dashboardInforChart">
          <Button className="btn-yellow inspections-btn" color="primary" onClick={handleScroll}>
            <Inspectionsvg /> FMCA Roadside Inspection Data
          </Button>
          <span>{isCoachingEnabled && <InformationBadge title="inspectionsVisualization" />}</span>
          <Button className="bg-white border border-white mt-2">
            <img src="content/images/theme-images/FMCSA-logo.png" alt="Logo" onClick={() => setShowModal(true)} />
          </Button>
          <br />
          {/* <Button className='bg-white border border-white mt-2'> */}
          <img src="content/images/theme-images/A&I-logo.png" alt="Logo" onClick={() => setShowModal2(false)} />
          {/* </Button> */}
          <div className="scoreContent">
            <h6>Are you at risk for a safety audit from the FMCSA?</h6>
            <ul className="basicScoreList">
              <li>
                <div className="ScoreType">
                  <div className="ScoreCount">
                    <span>
                      <span className="colorInfo" style={{ backgroundColor: '#F40101' }}></span>Inspect
                      {isCoachingEnabled && <InformationBadge title="red" />}
                    </span>
                    <span>-</span>
                    <span>75-100</span>
                  </div>
                  <p>Inspection Warranted - Top Priority </p>
                </div>
              </li>
              <li>
                <div className="ScoreType">
                  <div className="ScoreCount">
                    <span>
                      <span className="colorInfo" style={{ backgroundColor: '#FFC703' }}></span>Optional
                      {isCoachingEnabled && <InformationBadge title="yellow" />}
                    </span>
                    <span>-</span>
                    <span>50-74</span>
                  </div>
                  <p>Inspection Warranted - Next Level Priority</p>
                </div>
              </li>
              <li>
                <div className="ScoreType">
                  <div className="ScoreCount">
                    <span>
                      <span className="colorInfo" style={{ backgroundColor: '#01C520' }}></span>Pass
                      {isCoachingEnabled && <InformationBadge title="green" />}
                    </span>
                    <span>-</span>
                    <span>1-49</span>
                  </div>
                  <p>Inspection not Warranted</p>
                </div>
              </li>
            </ul>
          </div>
          {/* <ScoreGauge fieldName={'issScore'} type="CARRIER" driverSmsData={undefined} previousDriverSmsData={undefined} /> */}
        </Col>
        <Col sm={6} md={6} id="AnalysisChartsUi">
          {/* {companyName? <Row className="md bg-warning rounded px-3 py-2 fs-5" >Company Name</Row> : null} */}

          <AnalysisCharts isCoachingEnabled={isCoachingEnabled} type="CARRIER" driverSmsData={undefined} modifyPoints={undefined} />
        </Col>
        <Col sm={12} md={3} className="ChartDotNumber sectionthird">
          <div className="coaching-switch" style={{ marginTop: loggedInUser.authorities.includes('ROLE_ADMIN') ? '-4px' : '-33px' }}>
            <Input type="checkbox" checked={isCoachingEnabled} onClick={toggleSwitch} />
            <Label id="enable-coaching" style={{ cursor: 'pointer' }}>
              Enable Coaching
            </Label>
            <UncontrolledTooltip className="tooltip_design" placement="top" target={`enable-coaching`}>
              <span>
                With coaching enabled, the Asfaleia system will help show case violations, driver risks and your companies and drivers trend
                along with helping you fix violations and trend in the right direction by offering suggestions on improvements and steps you
                can take to improve. It is important to be on top of violations and trend levels as it will not only help your company but
                also helps keep our roads safe.
              </span>
            </UncontrolledTooltip>
          </div>
          <CoachingPerformanceOverview isCoachingEnabled={isCoachingEnabled} />
          {loggedInUser.authorities.includes('ROLE_ADMIN') && (
            <div className="inner">
              <h5>Department of Transportation</h5>
              <DashboardForm {...{ isAdmin: true }} />
            </div>
          )}
        </Col>
      </Row>
      <Row className="main-dashbord-table-content">
        <Col md={3}>
          {smsDataUpdate && smsDataEntity && (
            <CoachingItemListView isCoachingEnabled={isCoachingEnabled} smsDataUpdate={smsDataUpdate} smsData={smsDataEntity} />
          )}
        </Col>
        <Col md={6}>
          <InspectionListView isCoachingEnabled={isCoachingEnabled} inspections={inspections} />
        </Col>
        <Col md={3}>
          <DriverListView isCoachingEnabled={isCoachingEnabled} />
        </Col>
      </Row>
      {isSubmitted && (
        <div className="spinner-overlay">
          <Spinner animation="border" />
        </div>
      )}
      {/* <ScraperForm setInitial={setInitial} setIsSubmitted={setIsSubmitted} /> */}
      <div id="vizContainer">
        <Button color="primary" className="btn-yellow use_tool mt-5 mb-3" onClick={() => setShowModal(true)}>
          <Toolsvg /> Learn How To Use The Tool
        </Button>
        {/* <span>{isCoachingEnabled && <InformationBadge title="inspectionsVisualization" />}</span> */}
        <iframe
          allowTransparency={true}
          allowFullScreen={true}
          title="Data Visualisation"
          marginHeight={0}
          marginWidth={0}
          scrolling="no"
          style={{ display: 'block', width: '100%', height: '850px', visibility: 'visible' }}
          src="https://explore.dot.gov/views/Inspections_AI_Summary/InspectionsDB?:embed=y&amp;:isGuestRedirectFromVizportal=y&amp;:showVizHome=n&amp;:tabs=y&amp;:toolbar=false&amp;:apiID=host0&amp;:showShareOptions=false&amp;Traffic Enforcement=All"
        ></iframe>
      </div>
      {showModal && (
        <FmcsaClearingHouseDialog
          closeModal={closeModal}
          // pdfUrl={'https://ai.fmcsa.dot.gov/downloadfile.axd/Roadside_Inspections_and_Traffic_Enforcements_Job_Aid_2.pdf'}
          pdfUrl="../../../content/pdfs/Roadside_Inspections_and_Traffic_Enforcements_Job_Aid_2.pdf"
        />
      )}
      {showModal2 && (
        <FmcsaClearingHouseDialog
          closeModal={closeModal2}
          pdfUrl={'https://ai.fmcsa.dot.gov/'}
          // pdfUrl="../../../content/pdfs/Roadside_Inspections_and_Traffic_Enforcements_Job_Aid_2.pdf"
        />
      )}
    </>
  );
};

interface IProps {
  setInitial: Dispatch<SetStateAction<boolean>>;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
}
const ScraperForm = (props: IProps) => {
  const { dotNumber } = useParams();
  const dispatch = useAppDispatch();
  const onSubmit = data => {
    props.setIsSubmitted(true);
    dispatch(UpdateSmsData({ dotNumber: dotNumber, ...data }));
    props.setInitial(false);
  };
  return (
    <Row className="bottomDashDots">
      <Col md={4}>
        <ValidatedForm className="dotForm" onSubmit={data => onSubmit(data)}>
          <ValidatedField required label={'Please enter your Department of Transportation (Dot) Pin to update SMS data'} name="dotPin" />
          <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
            <ViewDataIcon /> Update
          </Button>
        </ValidatedForm>
      </Col>
    </Row>
  );
};

export default Dashboard;
