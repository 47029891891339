import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AsfaleiaLogo } from 'app/assets/svg-icon/logo';

export const PasswordResetFinishPage = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');

  const [password, setPassword] = useState('');

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const handleValidSubmit = ({ newPassword }) => dispatch(handlePasswordResetFinish({ key, newPassword }));

  const updatePassword = event => setPassword(event.target.value);

  const getResetForm = () => {
    return (
      <Row>
        <Col md={3} sm={12} className="login-sidebar">
          <AsfaleiaLogo />
        </Col>
        <Col md={9} sm={12}>
          <div className="account-form-ui">
            <div className="inner-account-form-wrap">
              <div className="form-title">
                <h2>
                  <Translate contentKey="reset.finish.title">Reset password</Translate>
                </h2>
              </div>
              <ValidatedForm onSubmit={handleValidSubmit}>
                <ValidatedField
                  name="newPassword"
                  label={translate('global.form.newpassword.label')}
                  placeholder={translate('global.form.newpassword.placeholder')}
                  type="password"
                  validate={{
                    required: { value: true, message: translate('global.messages.validate.newpassword.required') },
                    minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
                    maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
                  }}
                  onChange={updatePassword}
                  data-cy="resetPassword"
                />
                <PasswordStrengthBar password={password} />
                <ValidatedField
                  name="confirmPassword"
                  label={translate('global.form.confirmpassword.label')}
                  placeholder={translate('global.form.confirmpassword.placeholder')}
                  type="password"
                  validate={{
                    required: { value: true, message: translate('global.messages.validate.confirmpassword.required') },
                    minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
                    maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
                    validate: v => v === password || translate('global.messages.error.dontmatch'),
                  }}
                  data-cy="confirmResetPassword"
                />
                <Button color="success" type="submit" data-cy="submit">
                  <Translate contentKey="reset.finish.form.button">Validate new password</Translate>
                </Button>
              </ValidatedForm>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  return <div>{key ? getResetForm() : null}</div>;
};

export default PasswordResetFinishPage;
