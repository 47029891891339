import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SmsDataUpdate from './sms-data-update';
import SmsDataUpdateDetail from './sms-data-update-detail';
import SmsDataUpdateUpdate from './sms-data-update-update';
import SmsDataUpdateDeleteDialog from './sms-data-update-delete-dialog';

const SmsDataUpdateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SmsDataUpdate />} />
    <Route path="new" element={<SmsDataUpdateUpdate />} />
    <Route path=":id">
      <Route index element={<SmsDataUpdateDetail />} />
      <Route path="edit" element={<SmsDataUpdateUpdate />} />
      <Route path="delete" element={<SmsDataUpdateDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SmsDataUpdateRoutes;
