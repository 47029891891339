import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Inspection from './inspection';
import InspectionDetail from './inspection-detail';
import InspectionUpdate from './inspection-update';
import InspectionDeleteDialog from './inspection-delete-dialog';

const InspectionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Inspection />} />
    <Route path="new" element={<InspectionUpdate />} />
    <Route path=":id">
      <Route index element={<InspectionDetail />} />
      <Route path="edit" element={<InspectionUpdate />} />
      <Route path="delete" element={<InspectionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InspectionRoutes;
