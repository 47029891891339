import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { pathname } = useLocation();
  if (!pathname.includes('dashboard')) return null;
  <div className="footer page-content">
    <Row>
      <Col md="12">
        <p>
          <Translate contentKey="footer">Your footer</Translate>
        </p>
      </Col>
    </Row>
  </div>;
};

export default Footer;
