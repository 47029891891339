import dayjs from 'dayjs';

export interface ICoachingItem {
  id?: number;
  title?: string | null;
  month?: string | null;
  completed?: string | null;
  dotNumber?: string | null;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export const defaultValue: Readonly<ICoachingItem> = {};
