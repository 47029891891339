import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CoachingItem from './coaching-item';
import Carrier from './carrier';
import Violation from './violation';
import Crash from './crash';
import SmsData from './sms-data';
import Vehicle from './vehicle';
import Inspection from './inspection';
import Driver from './driver';
import UserProfile from './user-profile';
import DataQueue from './data-queue';
import SmsDataUpdate from './sms-data-update';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="coaching-item/*" element={<CoachingItem />} />
        <Route path="carrier/*" element={<Carrier />} />
        <Route path="violation/*" element={<Violation />} />
        <Route path="crash/*" element={<Crash />} />
        <Route path="sms-data/*" element={<SmsData />} />
        <Route path="vehicle/*" element={<Vehicle />} />
        <Route path="inspection/*" element={<Inspection />} />
        <Route path="driver/*" element={<Driver />} />
        <Route path="user-profile/*" element={<UserProfile />} />
        <Route path="data-queue/*" element={<DataQueue />} />
        <Route path="sms-data-update/*" element={<SmsDataUpdate />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
