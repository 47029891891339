import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { getEntities } from 'app/entities/sms-data/sms-data.reducer';
import { reset, UpdateSmsData } from 'app/shared/reducers/custom-reducer';
import { ViewDataIcon } from 'app/assets/svg-icon/icon';
import { toast } from 'react-toastify';
type Props = { isAdmin: React.ReactNode };

const DashboardForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const smsDataEntity = useAppSelector(state => state.smsData);
  const smsData = useAppSelector(state => state.customReducer.entity);
  const [error, setError] = useState('');
  const [inital, setIntital] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const updateSuccess = useAppSelector(state => state.customReducer.updateSuccess);
  const errorMessage = useAppSelector(state => state.customReducer.errorMessage);

  const onSubmit = values => {
    setIntital(true);
    setIsSubmitted(true);
    setError('');
    dispatch(getEntities({ query: `dotNumber.equals=${values.dotNumber}` }));
    setIntital(false);
  };

  const validateCarrier = values => {
    setIntital(true);
    setIsSubmitted(true);
    setError('');
    dispatch(UpdateSmsData(values));
    setIntital(false);
  };

  useEffect(() => {
    if (!props.isAdmin) {
      if (!inital && updateSuccess === true) {
        toast.success('Carrier validated successfully!!');
        smsData && navigate(`/dashboard/${smsData.dotNumber}`);
      } else if (!inital && updateSuccess === false && errorMessage != null) {
        // toast.error('Invalid DOT Number and DOT Pin!!');
        setIsSubmitted(false);
        return;
      }
    } else {
      if (inital || !!smsDataEntity.loading) {
        setIsSubmitted(false);
        return;
      }
      if (!smsDataEntity.entities.length) {
        setError('Invalid DOT Number!!');
        setIsSubmitted(false);
        return;
      }
      const [getItemInfo] = smsDataEntity.entities;
      getItemInfo && toast.success('Data loaded successfully!!');
      getItemInfo && navigate(`/dashboard/${getItemInfo.dotNumber}`);
    }
    setIsSubmitted(false);

    return () => {
      console.log('Unmounting dashboard form');
      setIntital(true);
      dispatch(reset());
    };
  }, [smsDataEntity.loading, updateSuccess]);

  return (
    <Row>
      <Col md={12}>
        {isSubmitted && (
          <div className="spinner-overlay">
            <Spinner animation="border" />
          </div>
        )}
        {!!error && <>{error}</>}
        {props.isAdmin ? (
          <ValidatedForm className="dotForm" onSubmit={data => onSubmit(data)}>
            <ValidatedField
              required
              label={'Please enter your Department of Transportation (Dot) number to view data'}
              name="dotNumber"
              placeholder="Enter Your Dot Number"
            />
            <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
              <ViewDataIcon /> View SMS data
            </Button>
          </ValidatedForm>
        ) : (
          <ValidatedForm className="dotForm" onSubmit={data => validateCarrier(data)}>
            <ValidatedField
              required
              label={'Please enter your Department of Transportation (Dot) number and (Dot) Pin to claim carrier'}
              name="dotNumber"
              placeholder="Enter Your Dot Number"
            />
            <ValidatedField required name="dotPin" placeholder="Enter Your Dot Pin" />
            <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
              <ViewDataIcon /> Validate
            </Button>
          </ValidatedForm>
        )}
      </Col>
    </Row>
  );
};

export default DashboardForm;
