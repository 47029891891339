import React from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import './login.scss';
import { AsfaleiaLogo } from 'app/assets/svg-icon/logo';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

const LoginModal = (props: ILoginModalProps) => {
  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const { loginError, handleClose } = props;

  const handleLoginSubmit = e => {
    handleSubmit(login)(e);
  };

  return (
    <Row>
      <Col md={3} sm={12} className="login-sidebar">
        <AsfaleiaLogo />
      </Col>
      <Col md={9} sm={12}>
        <div className="account-form-ui">
          <div className="inner-account-form-wrap">
            <Form onSubmit={handleLoginSubmit}>
              <div className="form-title">
                <h2>
                  <Translate contentKey="login.title">Login</Translate>
                </h2>
                <p>Welcome back! Please enter your details</p>
              </div>
              <Row>
                {loginError ? (
                  <Col md="12">
                    <Alert color="danger" data-cy="loginError">
                      <Translate contentKey="login.messages.error.authentication">
                        <strong>Failed to sign in!</strong> Please check your credentials and try again.
                      </Translate>
                    </Alert>
                  </Col>
                ) : null}

                <ValidatedField
                  name="username"
                  label={translate('global.form.username.label')}
                  placeholder={translate('global.form.username.placeholder')}
                  required
                  autoFocus
                  data-cy="username"
                  validate={{ required: 'Username cannot be empty!' }}
                  register={register}
                  error={errors.username}
                  isTouched={touchedFields.username}
                  className="col-md-12"
                />
                <ValidatedField
                  name="password"
                  type="password"
                  label={translate('login.form.password')}
                  placeholder={translate('login.form.password.placeholder')}
                  required
                  data-cy="password"
                  validate={{ required: 'Password cannot be empty!' }}
                  register={register}
                  error={errors.password}
                  isTouched={touchedFields.password}
                  className="col-md-12"
                />
                <Col md={12} className="remeberMe d-flex justify-content-between">
                  <ValidatedField
                    name="rememberMe"
                    type="checkbox"
                    check
                    label={translate('login.form.rememberme')}
                    value={true}
                    register={register}
                  />
                  <Link to="/account/reset/request" data-cy="forgetYourPasswordSelector">
                    {/* <Translate contentKey="login.password.forgot">Forget Password?</Translate> */}
                    Forget Password?
                  </Link>
                </Col>
                <Col md={12}>
                  <Button color="primary" type="submit" data-cy="submit">
                    <Translate contentKey="login.form.button">Sign in</Translate>
                  </Button>
                </Col>
              </Row>
            </Form>
            <Col md={12} className="bottomContent">
              <Translate contentKey="global.messages.info.register.noaccount">You don&apos;t have an account yet?</Translate>
              <Link to="/account/register">
                <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
              </Link>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LoginModal;
