import React from 'react';

export const DrugConsortiumIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 56 56" style={{ width: '56px', height: '56px' }}>
    <g>
      <circle style={{ fill: '#0D205B' }} cx="28" cy="28" r="28" />

      <radialGradient
        id="SVGID_1_"
        cx="-244.2638"
        cy="984.0413"
        r="1"
        gradientTransform="matrix(23.8688 35.3443 35.3443 -23.8688 -28932.0156 32132.2461)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop offset="0.6979" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
      </radialGradient>
      <circle style={{ fill: 'url(#SVGID_1_)', fillOpacity: '0.6' }} cx="28" cy="28" r="28" />
    </g>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M32.9,18.1c-1.2-1.2-2.7-1.8-4.1-1.8s-3,0.6-4.1,1.8L18,24.6c-2.3,2.3-2.3,6,0,8.3c2.3,2.3,5.9,2.3,8.3,0
l6.7-6.7C35.1,24,35.1,20.3,32.9,18.1z M31.3,24.6L28,27.9l-4.2-4.1l-5.1,5.1c0-0.9,0.2-2,1.1-2.7l6.6-6.6c0.6-0.6,1.5-0.9,2.3-0.9
s1.8,0.4,2.5,0.9C32.5,21.1,32.5,23.2,31.3,24.6z M36.9,22.3c0,0.9-0.2,1.8-0.5,2.7c1.2,1.4,1.2,3.5-0.1,4.8L33,33l-1.8-1.8L28,34.5
c-1.5,1.5-3.6,2.3-5.6,2.3c0.2,0.4,0.5,0.7,0.8,1c2.3,2.3,5.9,2.3,8.3,0l6.7-6.6c2.3-2.3,2.3-6,0-8.3C37.6,22.8,37.2,22.5,36.9,22.3
z"
    />
  </svg>
);

export const DriverIssIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 56 56" style={{ width: '56px', height: '56px' }}>
    <g>
      <circle style={{ fill: '#0D205B' }} cx="28" cy="28" r="28" />

      <radialGradient
        id="SVGID_DSS_1_"
        cx="-244.2638"
        cy="984.0413"
        r="1"
        gradientTransform="matrix(23.8688 35.3443 35.3443 -23.8688 -28932.0156 32132.2461)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop offset="0.6979" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
      </radialGradient>
      <circle style={{ fill: 'url(#SVGID_DSS_1_)', fillOpacity: '0.6' }} cx="28" cy="28" r="28" />
    </g>
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }}
      d="M22.4,18.9c0-0.3,2.8-2.2,5.6-2.2s5.6,1.9,5.6,2.2c0,1-0.1,1.6-0.2,1.9c-0.1,0.2-0.3,0.3-0.5,0.3H23
    c-0.2,0-0.4-0.1-0.5-0.3C22.5,20.5,22.4,19.9,22.4,18.9z M27.1,18.6c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
    s0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2h1.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4s-0.1-0.3-0.2-0.4
    c-0.1-0.1-0.3-0.2-0.4-0.2H27.1z M23,25c-2.1-0.6-1.6-1.8-0.8-2.6h11.7c0.6,0.8,1,1.9-0.9,2.6c0.1,0.7,0,1.4-0.2,2.1
    c-0.2,0.7-0.6,1.3-1,1.8c-0.5,0.5-1,0.9-1.7,1.2c-0.6,0.3-1.3,0.4-2,0.4c-0.7,0-1.4-0.1-2-0.4c-0.6-0.3-1.2-0.7-1.7-1.2
    c-0.5-0.5-0.8-1.1-1.1-1.8C23,26.4,23,25.7,23,25L23,25z M27.8,25.5c1.7,0,2.9-0.1,3.9-0.2c0,0.5,0,1-0.2,1.5
    c-0.2,0.5-0.4,0.9-0.8,1.3c-0.3,0.4-0.8,0.7-1.2,0.9c-0.5,0.2-1,0.3-1.5,0.3c-0.5,0-1-0.1-1.5-0.3c-0.5-0.2-0.9-0.5-1.2-0.9
    c-0.4-0.4-0.6-0.8-0.8-1.3c-0.2-0.5-0.3-1-0.2-1.5C25.2,25.4,26.3,25.5,27.8,25.5z M33.3,34.6c-0.6-0.9-1.3-1.6-2.3-2.1
    c-0.9-0.5-2-0.8-3-0.8c-1,0-2.1,0.3-3,0.8c-0.9,0.5-1.7,1.2-2.3,2.1c-0.2-0.3-0.5-0.6-0.9-0.7l-1.2-0.3c-0.4-0.1-0.8-0.1-1.2,0.2
    c-0.4,0.2-0.6,0.5-0.7,0.9l-0.5,1.8c-0.1,0.2-0.1,0.4,0,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.1,0.3,0.2,0.5,0.3
    l1.2,0.3c0.2,0.1,0.4,0.1,0.6,0c0.2,0,0.4-0.1,0.6-0.2c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
    c0.1-0.1,0.2-0.3,0.2-0.4V38c0-0.2,0-0.5,0-0.7l3.3,0.9c0.2,0.3,0.4,0.6,0.7,0.8c0.3,0.2,0.7,0.3,1,0.3s0.7-0.1,1-0.3
    c0.3-0.2,0.5-0.5,0.7-0.8l3.3-0.9c0,0.2,0,0.5,0,0.7v0.6c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
    c0.1-0.1,0.2-0.3,0.2-0.4c0.3,0.2,0.8,0.3,1.2,0.2l1.2-0.3c0.2-0.1,0.4-0.1,0.5-0.3c0.2-0.1,0.3-0.3,0.4-0.5
    c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.4,0-0.6l-0.5-1.8c-0.1-0.4-0.4-0.7-0.7-0.9c-0.4-0.2-0.8-0.3-1.2-0.2L34.2,34
    C33.8,34.1,33.5,34.3,33.3,34.6L33.3,34.6z M23.4,36.1c0.3-0.8,0.9-1.5,1.6-2.1c0.7-0.5,1.5-0.9,2.4-1v2.6c-0.3,0.1-0.5,0.3-0.7,0.5
    c-0.2,0.2-0.4,0.5-0.4,0.8L23.4,36.1L23.4,36.1z M32.6,36.1c-0.3-0.8-0.9-1.5-1.6-2.1c-0.7-0.5-1.5-0.9-2.4-1v2.6
    c0.6,0.2,1,0.7,1.2,1.2L32.6,36.1L32.6,36.1z M28,38c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4s-0.1-0.3-0.2-0.4
    c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4s0.1,0.3,0.2,0.4C27.7,37.9,27.8,38,28,38z"
    />
  </svg>
);

export const DmvPullNoticeIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 56 56" style={{ width: '56px', height: '56px' }}>
    <g>
      <circle style={{ fill: '#0D205B' }} cx="28" cy="28" r="28" />

      <radialGradient
        id="SVGID_DPNI_"
        cx="-244.2638"
        cy="984.0413"
        r="1"
        gradientTransform="matrix(23.8688 35.3443 35.3443 -23.8688 -28932.0156 32132.2461)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop offset="0.6979" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
      </radialGradient>
      <circle style={{ fill: 'url(#SVGID_DPNI_)', fillOpacity: '0.6' }} cx="28" cy="28" r="28" />
    </g>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M37.4,23.6l-5.9-5.9c-0.2-0.2-0.4-0.2-0.6-0.2H20.9c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2v18.5
	c0,0.4,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5H36c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2V24.2
	C37.7,24,37.6,23.8,37.4,23.6z M31,24.2v-4.6l4.6,4.6H31z"
    />
  </svg>
);

export const TelematicsIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 56 56" style={{ width: '56px', height: '56px' }}>
    <g>
      <circle style={{ fill: '#0D205B' }} cx="28" cy="28" r="28" />

      <radialGradient
        id="SVGID_Telem_"
        cx="-244.2638"
        cy="984.0413"
        r="1"
        gradientTransform="matrix(23.8688 35.3443 35.3443 -23.8688 -28932.0156 32132.2461)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop offset="0.6979" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
      </radialGradient>
      <circle style={{ fill: 'url(#SVGID_Telem_)', fillOpacity: '0.6' }} cx="28" cy="28" r="28" />
    </g>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M28,30.3c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6c0.5-0.5,1-0.7,1.6-0.7
	s1.2,0.2,1.6,0.7c0.5,0.5,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6C29.2,30.1,28.6,30.3,28,30.3z M19,35.4c-0.8-1-1.5-2.1-1.9-3.4
	c-0.5-1.3-0.7-2.6-0.7-4c0-1.4,0.2-2.8,0.7-4c0.5-1.2,1.1-2.4,1.9-3.4c0.2-0.3,0.5-0.4,0.8-0.4c0.3,0,0.6,0.1,0.8,0.4
	c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.9c-0.6,0.8-1.1,1.7-1.5,2.6c-0.3,1-0.5,2-0.5,3.1s0.2,2.1,0.5,3.1
	c0.3,1,0.8,1.8,1.4,2.6c0.2,0.3,0.3,0.6,0.3,0.9c0,0.3-0.1,0.6-0.4,0.8s-0.5,0.3-0.8,0.3C19.4,35.8,19.2,35.6,19,35.4z M22.3,32.1
	c-0.4-0.6-0.7-1.2-0.9-1.9C21.1,29.5,21,28.8,21,28s0.1-1.5,0.3-2.2c0.2-0.7,0.5-1.3,0.9-1.9c0.2-0.3,0.4-0.4,0.7-0.4
	c0.3,0,0.6,0.1,0.9,0.4c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.4-0.2,0.8-0.2,1.3
	s0.1,0.9,0.2,1.3c0.1,0.4,0.3,0.8,0.5,1.1c0.2,0.3,0.3,0.6,0.2,0.9c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.8,0.3
	C22.7,32.4,22.5,32.3,22.3,32.1z M32.1,32.1c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.2-0.9c0.2-0.4,0.4-0.7,0.5-1.1
	c0.1-0.4,0.2-0.8,0.2-1.3s-0.1-0.9-0.2-1.3c-0.1-0.4-0.3-0.8-0.5-1.1c-0.2-0.3-0.3-0.6-0.2-0.9c0-0.3,0.1-0.6,0.4-0.8
	c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.4c0.4,0.6,0.7,1.2,0.9,1.9c0.2,0.7,0.3,1.4,0.3,2.2s-0.1,1.5-0.3,2.2
	c-0.2,0.7-0.5,1.3-0.9,1.9c-0.2,0.3-0.4,0.4-0.8,0.4C32.6,32.5,32.3,32.3,32.1,32.1z M35.4,35.4c-0.2-0.2-0.3-0.5-0.4-0.8
	c0-0.3,0.1-0.6,0.3-0.9c0.6-0.8,1.1-1.7,1.5-2.6c0.3-1,0.5-2,0.5-3.1s-0.2-2.1-0.5-3.1c-0.3-1-0.8-1.8-1.4-2.6
	c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.3,0.1-0.6,0.4-0.8s0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.4c0.8,1,1.5,2.1,1.9,3.4
	c0.5,1.2,0.7,2.6,0.7,4c0,1.4-0.2,2.7-0.7,4c-0.5,1.3-1.1,2.4-1.9,3.4c-0.2,0.3-0.5,0.4-0.8,0.4C35.9,35.8,35.7,35.7,35.4,35.4z"
    />
  </svg>
);

export const PspIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 56 56" style={{ width: '56px', height: '56px' }}>
    <g>
      <circle style={{ fill: '#0D205B' }} cx="28" cy="28" r="28" />

      <radialGradient
        id="SVGID_psp_"
        cx="-244.2638"
        cy="984.0413"
        r="1"
        gradientTransform="matrix(23.8688 35.3443 35.3443 -23.8688 -28932.0156 32132.2461)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop offset="0.6979" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
      </radialGradient>
      <circle style={{ fill: 'url(#SVGID_psp_)', fillOpacity: '0.6' }} cx="28" cy="28" r="28" />
    </g>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M37.3,23l-6.1-6.1c-0.2-0.2-0.4-0.3-0.6-0.3H20.1c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.2v19.2
	c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5h15.7c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2V23.6
	C37.5,23.4,37.4,23.1,37.3,23z M31.4,33.2h-7c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.6
	c0.2-0.2,0.4-0.3,0.6-0.3h7c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6S31.7,33.2,31.4,33.2z M31.4,29.7
	h-7c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3h7c0.2,0,0.5,0.1,0.6,0.3
	c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6S31.7,29.7,31.4,29.7z M30.6,23.6v-4.8l4.8,4.8H30.6z"
    />
  </svg>
);

export const FmcsaClearinghouseIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 56 56" style={{ width: '56px', height: '56px' }}>
    <g>
      <circle style={{ fill: '#0D205B' }} cx="28" cy="28" r="28" />

      <radialGradient
        id="SVGID_FCI_"
        cx="-244.2638"
        cy="984.0413"
        r="1"
        gradientTransform="matrix(23.8688 35.3443 35.3443 -23.8688 -28932.0156 32132.2461)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop offset="0.6979" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
      </radialGradient>
      <circle style={{ fill: 'url(#SVGID_FCI_)', fillOpacity: '0.6' }} cx="28" cy="28" r="28" />
    </g>
    <path
      style={{ fill: '#FFFFFF' }}
      d="M28.8,40.9c-1.4,0-2.7-0.3-3.9-0.8c-1.2-0.5-2.2-1.2-3-2c-0.8-0.8-1.5-1.8-2-3c-0.5-1.1-0.7-2.3-0.7-3.5V20.4
	c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v7h1.2V17.1c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v9.2h1.2V15.4c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,1,0.4
	c0.3,0.3,0.4,0.6,0.4,1v10.9h1.2v-8.7c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v12.1
	c-1.2,0.1-2.2,0.6-2.9,1.5c-0.8,0.8-1.1,1.8-1.1,3h1.2c0-0.9,0.3-1.7,1-2.4c0.7-0.7,1.5-1,2.5-1h0.6v-6.4c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v7.3c0,1.2-0.2,2.3-0.7,3.5c-0.5,1.1-1.1,2.1-1.9,3
	c-0.8,0.9-1.8,1.5-3,2.1C31.5,40.6,30.2,40.9,28.8,40.9z"
    />
  </svg>
);

export const BellIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 18.2 23.4" style={{ width: '56px', height: '56px' }}>
    <path
      style={{ fill: '#C3CAD9', fillRule: 'evenodd', clipRule: 'evenodd' }}
      d="M9.1,23.4c1.3,0,2.4-1.1,2.4-2.4H6.7C6.7,22.3,7.8,23.4,9.1,23.4z M16.3,16.2v-6c0-3.7-2-6.8-5.4-7.6V1.8
	c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v0.8c-3.4,0.8-5.4,3.9-5.4,7.6v6l-1.5,1.5c-0.8,0.8-0.2,2.1,0.8,2.1H17
	c1.1,0,1.6-1.3,0.9-2.1L16.3,16.2z"
    />
  </svg>
);

export const UserIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24" style={{ width: '56px', height: '56px' }}>
    <path
      style={{ fill: '#C3CAD9', fillRule: 'evenodd', clipRule: 'evenodd' }}
      d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M12,3.6
	c2,0,3.6,1.6,3.6,3.6S14,10.8,12,10.8S8.4,9.2,8.4,7.2S10,3.6,12,3.6z M12,20.6c-3,0-5.7-1.5-7.2-3.9c0-2.4,4.8-3.7,7.2-3.7
	c2.4,0,7.2,1.3,7.2,3.7C17.7,19.1,15,20.6,12,20.6z"
    />
  </svg>
);

export const ViewDataIcon = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" style={{ width: '20px', height: '20px' }}>
    <path
      style={{ fill: '#ffffff' }}
      d="M402.2,224.9c-94.8-129.3-197.6-129.3-292.5,0c-4.1,5.7-4.1,13.3,0,19c47.4,64.6,96.6,97.4,146.2,97.4s98.8-32.7,146.2-97.4
	C406.3,238.3,406.3,230.6,402.2,224.9z M256,277.3c-23.6,0-42.7-19.1-42.7-42.7S232.4,192,256,192s42.7,19.1,42.7,42.7
	S279.6,277.3,256,277.3z M475.1,219.1c-8.8,0-16,7.2-16,16c0,95.7-66.6,175.9-155.8,197.3v-19.9c0-14.2-17.2-21.4-27.3-11.3
	l-41.7,41.7c-6.3,6.3-6.3,16.4,0,22.6l41.7,41.7c10,10,27.3,3,27.3-11.3v-30.5c107.1-22,187.8-116.9,187.8-230.3
	C491.1,226.3,484,219.1,475.1,219.1L475.1,219.1z M188.2,426.4c-81-28.4-135.4-105.2-135.4-191.3c0-24.4,4.4-48,12.7-70.3l19.2,11.1
	c12.3,7.1,27.1-4.2,23.5-18L91.9,97.5C89.6,89,81.1,83.9,72.3,86.2l-60.5,16.2C-1.9,106-4.3,124.6,8,131.7l29.4,17
	c-10.8,27.2-16.5,56.4-16.5,86.5c0,99.6,63,188.6,156.8,221.5c8.5,2.9,17.5-1.6,20.4-9.8C201,438.5,196.6,429.4,188.2,426.4z
	 M491.8,104.9l-34.4,9.2C415.6,44.9,339.2,0,256,0c-53.6,0-103.8,17.6-145.3,50.9c-6.9,5.5-8,15.6-2.5,22.5c5.6,6.9,15.6,8,22.5,2.5
	C166.5,47.2,209.8,32,256,32c68.7,0,131.9,35.5,169,90.8l-18.6,5c-13.7,3.7-16.2,22.2-3.9,29.3l54.2,31.3c3.7,2.1,8,2.7,12.1,1.6
	c4.1-1.1,7.6-3.8,9.7-7.5l31.3-54.2C516.9,116.1,505.7,101.2,491.8,104.9L491.8,104.9z"
    />
  </svg>
);

export const SamsaraIconLogo = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 56 56" style={{ width: '56px', height: '56px' }}>
    <g>
      <circle cx="28" cy="28" r="28" style={{ fill: '#0D205B' }} />

      <radialGradient
        id="SVGID_sm-l"
        cx="-244.2638"
        cy="984.0413"
        r="1"
        gradientTransform="matrix(23.8688 35.3443 35.3443 -23.8688 -28932.0156 32132.2461)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop offset="0.6979" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
        <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
      </radialGradient>
      <circle style={{ fill: 'url(#SVGID_sm-l)', fillOpacity: 0.6 }} cx="28" cy="28" r="28" />
    </g>
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#ffffff' }}
      d="M29.6,15.2c3.2,0,6,3.5,6,7.5c0,4.5-3.3,8.6-8,9.8c-0.1,0-0.2,0-0.3,0c-4.7-1.2-8-5.2-8-9.8
 c0-4.1,2.7-7.5,6-7.5c0.6,0,1.1,0.1,1.7,0.3c0.3,0.1,0.5,0.1,0.8,0C28.5,15.3,29,15.2,29.6,15.2z M33.4,22.3
 c0.2-0.5,0.2-1.1-0.1-1.6c-0.3-0.5-0.8-0.8-1.3-0.8c-0.8-0.1-1.5,0.2-2.1,0.8c-0.8,0.8-1.1,2.1-1.2,2.7c-0.1,0.3-0.8,3.5-1.2,5.6
 c-0.5-2.1-1.2-5.3-1.2-5.6c-0.1-0.6-0.4-1.9-1.2-2.7c-0.5-0.6-1.2-0.9-2.1-0.8c-0.5,0-1,0.3-1.3,0.8c-0.3,0.5-0.3,1.1-0.1,1.6
 c0.3,0.6,0.6,1.1,1.2,1.4c0.5,0.3,1,0.5,1.5,0.5c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.5-0.4-0.5c-0.4,0-0.8-0.1-1.1-0.4
 c-0.4-0.2-0.7-0.6-0.8-1c-0.1-0.3-0.1-0.6,0.1-0.8c0.1-0.2,0.4-0.4,0.6-0.4c0.6,0,1,0.1,1.4,0.5c0.6,0.6,0.8,1.8,0.9,2.3
 c0.1,0.4,0.9,4,1.3,6.1l0.1,0.7c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0,0.5-0.2,0.6-0.5c0.4-1.8,1.4-6.3,1.5-6.7c0.1-0.5,0.3-1.6,0.9-2.3
 c0.4-0.4,0.8-0.6,1.4-0.5c0.3,0,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.5,0.1,0.8c-0.2,0.4-0.5,0.8-0.8,1c-0.3,0.2-0.7,0.3-1.1,0.4
 c-0.2,0-0.4,0.2-0.4,0.5c0,0.2,0.2,0.4,0.4,0.4c0.5,0,1.1-0.2,1.5-0.5C32.7,23.4,33.1,22.9,33.4,22.3z M27.5,12
 C33.3,12,38,17,38,23.2v9.6c0,6.2-4.7,11.2-10.5,11.2c-5.8,0-10.5-5-10.5-11.2v-9.6C17,17,21.7,12,27.5,12z M27.8,33.4
 c5.1-1.3,8.6-5.7,8.6-10.6c0-4.6-3.1-8.4-6.8-8.4c-0.7,0-1.3,0.1-2,0.4c-0.1,0-0.2,0-0.3,0c-0.7-0.2-1.3-0.4-2-0.4
 c-3.7,0-6.8,3.9-6.8,8.4c0,5,3.5,9.3,8.6,10.6c0.1,0,0.2,0,0.3,0C27.6,33.4,27.7,33.4,27.8,33.4z M35.9,34.3c0-0.2-0.2-0.4-0.4-0.4
 s-0.4,0.2-0.4,0.4V35c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V34.3z M33.3,35.8c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.6
 c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V35.8z M33.3,38.6c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4
 s0.4-0.2,0.4-0.4V38.6z M30.6,37.2c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4
 V37.2z M30.6,40c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V40z M27.9,35.8
 c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V35.8z M27.9,38.6
 c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V38.6z M25.2,37.2
 c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V37.2z M25.2,40
 c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V40z M22.6,35.8
 c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V35.8z M22.6,38.6
 c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V38.6z M19.9,31.5
 c0-0.2-0.2-0.4-0.4-0.4S19,31.3,19,31.5v0.6c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V31.5z M19.9,34.3c0-0.2-0.2-0.4-0.4-0.4
 S19,34.1,19,34.3V35c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V34.3z M35.9,31.5c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.6
 c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V31.5z"
    />
  </svg>
);

export const FlagIcon = props => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 11 17" style={{ width: '11px', height: '18px' }}>
    <path
      style={{ fill: props.fill }}
      d="M0.2,0.1C0.3,0,0.4,0,0.5,0s0.2,0,0.3,0.1c0,0,7.6,4.3,10,6c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0,0.2
      s0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.2C8.5,8.6,1,11.8,1,11.8V17H0V0.5c0-0.1,0-0.2,0.1-0.3C0.1,0.2,0.1,0.1,0.2,0.1z"
    />
  </svg>
);
export const DownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7.03 13.92h4V5l2.01-.03v8.95h3.99l-5 5Z" />
  </svg>
);

export const UpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M16.97 10.08H12.97V19L10.96 19.03V10.08L6.96997 10.08L11.97 5.08003L16.97 10.08Z" fill="black" />
  </svg>
);
