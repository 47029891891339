import React from 'react';
export const AsfaleiaLogo = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 167.5 60.9" style={{ width: '170px', height: '61px' }}>
    <path
      style={{ fill: '#FFD400' }}
      d="M167.2,57l-8.7-13.9c-0.2,1.5-1.2,2.7-2.5,3.4l6,9.7h-61.6l5.6-9.3c-0.6-0.3-1.1-0.9-1.5-1.5
	c-0.4-0.7-0.7-1.5-0.8-2.3L95.2,57c-0.4,0.6-0.4,1.4,0,2c0.3,0.6,1,1,1.7,1h68.7c0.7,0,1.4-0.4,1.7-1
	C167.6,58.3,167.6,57.6,167.2,57z"
    />
    <path
      style={{ fill: '#FFD400' }}
      d="M115.6,29.7c0.1,0.1,0.3,0.2,0.4,0.2l14.5-24.2L145.7,30c0.6-0.3,1.3-0.5,2.1-0.5c1,0,1.9,0.2,2.6,0.6
	L132.1,0.9c-0.3-0.6-1-0.9-1.6-0.9c0,0,0,0,0,0c-0.7,0-1.3,0.4-1.7,1l-17,28.5C112.9,29,114.5,29,115.6,29.7z"
    />
    <path style={{ fill: '#FFD400' }} d="M19.3,33.6L15,43.3h9.7L19.3,33.6z" />
    <path
      style={{ fill: '#FFD400' }}
      d="M130.5,25.9c1,0,1.9-0.3,2.6-1.1c0.7-0.7,1.1-1.6,1.1-2.5c0-1-0.3-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1
	c-1,0-1.8,0.4-2.5,1.1c-0.7,0.7-1.1,1.6-1.1,2.6s0.3,1.8,1.1,2.5C128.6,25.6,129.5,25.9,130.5,25.9z"
    />
    <path
      style={{ fill: '#FFD400' }}
      d="M130.5,50.6c2.6,0,4.5-2.3,4.2-4.8l-2.3-15.5c-0.1-0.9-0.9-1.6-1.9-1.6c-0.9,0-1.7,0.7-1.9,1.6l-2.3,15.5
	C126,48.3,127.9,50.6,130.5,50.6z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M45.4,37.4c-1.1-0.4-2.3-0.8-3.4-1.1c-1.1-0.3-2.1-0.6-2.9-1c-0.8-0.4-1.2-0.9-1.2-1.5c0-0.6,0.2-1,0.8-1.3
	c0.5-0.3,1.1-0.5,1.9-0.5c1.7,0,2.9,0.8,3.6,2.3l4.9-2.7c-0.9-1.5-2.1-2.7-3.6-3.5c-1.5-0.8-3.2-1.2-5-1.2c-2.4,0-4.5,0.7-6.1,1.9
	c-1.6,1.3-2.4,3-2.4,5.3c0,1.5,0.4,2.7,1.2,3.7c0.8,1,1.8,1.7,2.9,2.1c1.1,0.4,2.3,0.8,3.4,1.1c1.1,0.3,2.1,0.7,2.9,1
	c0.8,0.4,1.2,0.9,1.2,1.5c0,1.3-1,1.9-3.1,1.9c-2.3,0-3.7-1-4.4-2.9L31,45.3c1.7,3.6,4.8,5.4,9.4,5.4c2.6,0,4.7-0.6,6.5-1.9
	c1.7-1.2,2.6-3,2.6-5.3c0-1.5-0.4-2.8-1.2-3.8C47.5,38.5,46.5,37.8,45.4,37.4z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M54.9,19.9c-1.8,1.6-2.6,4.1-2.6,7.3v22.7h5.8V34.3c2.6,0,4.8-2.1,4.8-4.8v-0.8h-4.8v-1.6
	c0-2.8,1.6-4.1,4.8-3.8v-5.6C59.4,17.6,56.7,18.3,54.9,19.9z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M81.8,30.1c-1.8-2.2-4.2-3.3-7.4-3.3c-3,0-5.6,1.1-7.7,3.4c-2.2,2.3-3.2,5.1-3.2,8.5c0,3.3,1.1,6.2,3.2,8.5
	s4.7,3.4,7.7,3.4c3.2,0,5.6-1.1,7.4-3.3v2.6h5.8V27.4h-5.8V30.1z M80,43.3c-1.2,1.2-2.7,1.8-4.5,1.8c-1.8,0-3.3-0.6-4.5-1.8
	c-1.2-1.2-1.8-2.7-1.8-4.6c0-1.9,0.6-3.4,1.8-4.6c1.2-1.2,2.6-1.8,4.5-1.8c1.8,0,3.3,0.6,4.5,1.8c1.2,1.2,1.8,2.7,1.8,4.6
	C81.8,40.5,81.2,42.1,80,43.3z"
    />
    <path style={{ fill: '#FFFFFF' }} d="M97.8,17.8H92v32.2h5.8V17.8z" />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M113,26.8c-3.5,0-6.3,1.1-8.6,3.4c-2.2,2.3-3.3,5.1-3.3,8.5c0,3.4,1.1,6.3,3.4,8.5c2.2,2.3,5.3,3.4,9,3.4
	c4.3,0,7.6-1.6,9.8-4.8l-4.7-2.7c-1.1,1.5-2.8,2.3-5.1,2.3c-3.5,0-5.6-1.4-6.4-4.2h17c0.1-0.8,0.2-1.6,0.2-2.4
	c0-3.4-1.1-6.2-3.2-8.5C119,27.9,116.3,26.8,113,26.8z M107.1,36.6c0.3-1.4,1-2.6,2-3.4c1-0.8,2.3-1.2,3.9-1.2
	c1.3,0,2.5,0.4,3.5,1.1c1,0.8,1.7,1.9,2.1,3.4H107.1z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M155,27.4v2.6c-1.8-2.2-4.2-3.3-7.4-3.3c-3,0-5.6,1.1-7.7,3.4c-2.2,2.3-3.2,5.1-3.2,8.5c0,3.3,1.1,6.2,3.2,8.5
	c2.2,2.3,4.7,3.4,7.7,3.4c3.2,0,5.6-1.1,7.4-3.3v2.6h5.8V27.4L155,27.4L155,27.4z M153.2,43.3c-1.2,1.2-2.7,1.8-4.5,1.8
	c-1.8,0-3.3-0.6-4.5-1.8c-1.2-1.2-1.8-2.7-1.8-4.6c0-1.9,0.6-3.4,1.8-4.6c1.2-1.2,2.6-1.8,4.5-1.8c1.8,0,3.3,0.6,4.5,1.8
	c1.2,1.2,1.8,2.7,1.8,4.6C155,40.5,154.4,42.1,153.2,43.3z"
    />
    <path style={{ fill: '#FFFFFF' }} d="M29.9,49.9h-6.3L15,25.5L6.7,49.9H0L9.8,22c0.8-2.2,2.8-3.6,5.1-3.6s4.4,1.4,5.1,3.6L29.9,49.9z" />
    <radialGradient
      id="SVGID_logo_af_1_"
      cx="-208.027"
      cy="951.1944"
      r="1"
      gradientTransform="matrix(30.8331 0 0 -30.8331 6432.4097 29343.6426)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#939598' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
    </radialGradient>
    <path style={{ fill: 'url(#SVGID_logo_af_1_)' }} d="M9.8,22L0.1,49.9h6.7L15,25.5C15,25.6,12.4,18.7,9.8,22z" />
    <radialGradient
      id="SVGID_logo_af_2_"
      cx="-203.833"
      cy="933.2675"
      r="1"
      gradientTransform="matrix(19.2531 0 0 -19.2531 3973.5657 18007.3789)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#939598' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
    </radialGradient>
    <path
      style={{ fill: 'url(#SVGID_logo_af_2_)' }}
      d="M42.3,41.9c0.8,0.4,1.2,0.9,1.2,1.5c0,1.3-1,1.9-3.1,1.9c-2.3,0-3.7-1-4.4-2.9L31,45.2c1.7,3.6,4.8,5.4,9.4,5.4
	c2.6,0,4.7-0.6,6.5-1.9C49.7,44.4,42.3,41.9,42.3,41.9z"
    />
    <radialGradient
      id="SVGID_logo_af_3_"
      cx="-202.6203"
      cy="928.0842"
      r="1"
      gradientTransform="matrix(17.3672 0 0 -17.3672 3549.448 16154.4961)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#939598' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
    </radialGradient>
    <path
      style={{ fill: 'url(#SVGID_logo_af_3_)' }}
      d="M39,35.3c-0.8-0.4-1.2-0.9-1.2-1.5c0-0.6,0.2-1,0.8-1.3c0.5-0.3,1.1-0.5,1.9-0.5c1.7,0,2.9,0.8,3.6,2.3l4.9-2.7
	c-0.9-1.5-2.1-2.7-3.6-3.5c-1.5-0.8-3.2-1.2-5-1.2c-2.4,0-4.5,0.7-6.1,1.9C31.7,32.7,39,35.3,39,35.3z"
    />
    <radialGradient
      id="SVGID_logo_af_4_"
      cx="-205.36"
      cy="939.7944"
      r="1"
      gradientTransform="matrix(22.3028 0 0 -22.3028 4632.0195 20988.2559)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#939598' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
    </radialGradient>
    <path style={{ fill: 'url(#SVGID_logo_af_4_)' }} d="M58.1,34.3v15.6h-5.8V39.2c0-2.7,2.2-4.9,4.9-4.9H58.1z" />
    <radialGradient
      id="SVGID_logo_af_5_"
      cx="-206.5499"
      cy="944.8809"
      r="1"
      gradientTransform="matrix(25.4436 0 0 -25.4436 5341.9189 24079.6777)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#939598' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
    </radialGradient>
    <path
      style={{ fill: 'url(#SVGID_logo_af_5_)' }}
      d="M81.8,38.7c0,1.9-0.6,3.4-1.8,4.6c-1.2,1.2-2.7,1.8-4.5,1.8c-1.8,0-3.3-0.6-4.5-1.8c-1.2-1.2-1.8-2.7-1.8-4.6
	c0-1.9,0.6-3.4,1.8-4.6c1.2-1.2,2.6-1.8,4.5-1.8c1.8,0,3.3,0.6,4.5,1.8C81.2,35.3,81.8,36.8,81.8,38.7V30c-1.8-2.2-4.2-3.3-7.4-3.3
	c-3,0-5.6,1.1-7.7,3.4c-2.2,2.3-3.2,5.1-3.2,8.5s1.1,6.2,3.2,8.5s4.7,3.4,7.7,3.4c3.2,0,5.6-1.1,7.4-3.3V38.7z"
    />
    <radialGradient
      id="SVGID_logo_af_6_"
      cx="-206.5499"
      cy="944.8809"
      r="1"
      gradientTransform="matrix(25.4436 0 0 -25.4436 5415.0791 24079.6777)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#939598' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
    </radialGradient>
    <path
      style={{ fill: 'url(#SVGID_logo_af_6_)' }}
      d="M155,38.7c0,1.9-0.6,3.4-1.8,4.6c-1.2,1.2-2.7,1.8-4.5,1.8c-1.8,0-3.3-0.6-4.5-1.8c-1.2-1.2-1.8-2.7-1.8-4.6
	c0-1.9,0.6-3.4,1.8-4.6c1.2-1.2,2.6-1.8,4.5-1.8c1.8,0,3.3,0.6,4.5,1.8C154.4,35.3,155,36.8,155,38.7V30c-1.8-2.2-4.2-3.3-7.4-3.3
	c-3,0-5.6,1.1-7.7,3.4c-2.2,2.3-3.2,5.1-3.2,8.5s1.1,6.2,3.2,8.5c2.2,2.3,4.7,3.4,7.7,3.4c3.2,0,5.6-1.1,7.4-3.3V38.7z"
    />
    <radialGradient
      id="SVGID_logo_af_7_"
      cx="-205.899"
      cy="942.0987"
      r="0.9999"
      gradientTransform="matrix(23.6238 0 0 -23.6238 4968.2432 22309.9473)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: '#939598' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
    </radialGradient>
    <path
      style={{ fill: 'url(#SVGID_logo_af_7_)' }}
      d="M123.3,45.7C123.3,45.8,123.3,45.8,123.3,45.7c-2.4,3.5-6.9,4.8-10.9,3.3c-5.6-2.2-5.2-8-5.2-8
	c0.8,2.8,2.9,4.2,6.4,4.2c2.2,0,3.9-0.8,5.1-2.3L123.3,45.7z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M5.3,54.9c-0.9,0-1.3,0.5-1.3,1.4v1.8c0,0.9,0.4,1.4,1.3,1.4c0.9,0,1.3-0.5,1.3-1.4v-1.8
	C6.6,55.4,6.1,54.9,5.3,54.9z M5.9,58.2c0,0.5-0.2,0.8-0.7,0.8s-0.7-0.3-0.7-0.8v-1.9c0-0.5,0.2-0.8,0.7-0.8c0.4,0,0.7,0.2,0.7,0.8
	V58.2z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M10.8,54.9c-0.4,0-0.8,0.2-1,0.7c-0.1-0.4-0.4-0.7-0.9-0.7c-0.4,0-0.8,0.2-1,0.7V55H7.3v4.5H8v-3
	c0-0.7,0.3-1,0.7-1c0.3,0,0.5,0.2,0.5,0.7v3.3h0.7v-3c0-0.7,0.3-1,0.7-1c0.3,0,0.5,0.2,0.5,0.7v3.3h0.7v-3.3
	C11.8,55.4,11.4,54.9,10.8,54.9z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M14.2,54.9c-0.5,0-0.8,0.2-1,0.7V55h-0.6v5.8h0.7V59c0.2,0.3,0.5,0.5,0.9,0.5c0.7,0,1-0.4,1-1.3v-2.1
	C15.2,55.4,14.8,54.9,14.2,54.9z M14.5,58.2c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.7-0.3-0.7-1v-1.5c0-0.7,0.3-1,0.7-1
	c0.4,0,0.6,0.2,0.6,0.8V58.2z"
    />
    <path style={{ fill: '#FFFFFF' }} d="M16.6,53.3H16v6.2h0.7V53.3z" />
    <path style={{ fill: '#FFFFFF' }} d="M18.1,55h-0.7v4.5h0.7V55z" />
    <path style={{ fill: '#FFFFFF' }} d="M18.1,53.4h-0.7v1h0.7V53.4z" />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M21.4,56.3c0-0.9-0.4-1.3-1.2-1.3c-0.8,0-1.3,0.5-1.3,1.3v0.1h0.6v-0.1c0-0.4,0.2-0.7,0.6-0.7s0.6,0.2,0.6,0.7
	v0.1c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.2-0.7,0.4c-0.8,0.4-1.1,0.8-1.1,1.5c0,0.7,0.4,1.1,1,1.1c0.5,0,0.9-0.2,1-0.7
	c0,0.3,0,0.5,0.1,0.6h0.6c-0.1-0.3-0.1-0.7-0.1-1.2V56.3z M20.8,58c0,0.7-0.3,1-0.7,1c-0.4,0-0.5-0.2-0.5-0.6c0-0.4,0.2-0.7,0.6-0.9
	c0.4-0.2,0.6-0.3,0.7-0.4V58z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M23.8,54.9c-0.5,0-0.8,0.2-1,0.7V55h-0.6v4.5h0.7v-3c0-0.7,0.3-1,0.7-1c0.4,0,0.5,0.2,0.5,0.8v3.2h0.7v-3.3
	C24.9,55.4,24.5,54.9,23.8,54.9z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M26.9,54.9c-0.9,0-1.3,0.5-1.3,1.4v1.8c0,0.9,0.4,1.4,1.3,1.4c0.9,0,1.3-0.5,1.3-1.4v-0.3h-0.7v0.3
	c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.6-0.2-0.6-0.8v-1.9c0-0.5,0.2-0.8,0.6-0.8c0.4,0,0.6,0.2,0.6,0.8v0.2h0.7v-0.2
	C28.2,55.5,27.7,54.9,26.9,54.9z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M30.1,54.9c-0.9,0-1.3,0.5-1.3,1.4v1.8c0,0.9,0.5,1.4,1.3,1.4c0.8,0,1.3-0.5,1.3-1.4V58h-0.7v0.1
	c0,0.5-0.2,0.8-0.7,0.8c-0.4,0-0.7-0.3-0.7-0.8v-0.8h2v-1C31.4,55.4,31,54.9,30.1,54.9z M30.8,56.8h-1.3v-0.5c0-0.5,0.2-0.8,0.7-0.8
	c0.4,0,0.7,0.2,0.7,0.8V56.8z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M39.8,56.3c0-0.9-0.4-1.3-1.2-1.3c-0.8,0-1.3,0.5-1.3,1.3v0.1h0.6v-0.1c0-0.4,0.2-0.7,0.6-0.7s0.6,0.2,0.6,0.7
	v0.1c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.2-0.7,0.4c-0.8,0.4-1.1,0.8-1.1,1.5c0,0.7,0.4,1.1,1,1.1c0.5,0,0.9-0.2,1-0.7
	c0,0.3,0,0.5,0.1,0.6h0.6c-0.1-0.3-0.1-0.7-0.1-1.2V56.3z M39.1,58c0,0.7-0.3,1-0.7,1c-0.4,0-0.5-0.2-0.5-0.6c0-0.4,0.2-0.7,0.6-0.9
	c0.4-0.2,0.6-0.3,0.7-0.4V58z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M40.9,54.2V55h-0.6v0.6h0.6v3.9h0.7v-3.9h0.8V55h-0.8v-0.8c0-0.4,0.1-0.5,0.4-0.5c0.1,0,0.2,0,0.4,0.1v-0.6
	c-0.1-0.1-0.3-0.1-0.5-0.1C41.2,53.2,40.9,53.5,40.9,54.2z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M44.1,54.9c-0.9,0-1.3,0.5-1.3,1.4v1.8c0,0.9,0.5,1.4,1.3,1.4c0.9,0,1.3-0.5,1.3-1.4V58h-0.7v0.1
	c0,0.5-0.2,0.8-0.7,0.8c-0.4,0-0.7-0.3-0.7-0.8v-0.8h2v-1C45.4,55.4,44.9,54.9,44.1,54.9z M44.7,56.8h-1.3v-0.5
	c0-0.5,0.2-0.8,0.7-0.8c0.4,0,0.7,0.2,0.7,0.8V56.8z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M47.1,53.7h-0.6L46.4,55h-0.6v0.6h0.5v3c0,0.7,0.3,1,1,1c0.2,0,0.4,0,0.5-0.1v-0.6c-0.1,0-0.2,0.1-0.4,0.1
	c-0.3,0-0.4-0.1-0.4-0.5v-2.9h0.8V55H47L47.1,53.7L47.1,53.7z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M49.7,58.4L48.9,55h-0.7l1.1,4.5l-0.1,0.3c-0.1,0.3-0.2,0.4-0.5,0.4c-0.1,0-0.2,0-0.3-0.1v0.6
	c0.1,0.1,0.2,0.1,0.4,0.1c0.6,0,0.9-0.3,1.1-1l0.2-0.8L51,55h-0.6L49.7,58.4z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M58.3,54.9c-0.9,0-1.3,0.5-1.3,1.4v1.8c0,0.9,0.4,1.4,1.3,1.4c0.9,0,1.3-0.5,1.3-1.4v-0.3h-0.7v0.3
	c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.6-0.2-0.6-0.8v-1.9c0-0.5,0.2-0.8,0.6-0.8c0.4,0,0.6,0.2,0.6,0.8v0.2h0.7v-0.2
	C59.5,55.5,59.1,54.9,58.3,54.9z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M61.5,54.9c-0.9,0-1.3,0.5-1.3,1.4v1.8c0,0.9,0.4,1.4,1.3,1.4c0.9,0,1.3-0.5,1.3-1.4v-0.3h-0.7v0.3
	c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.6-0.2-0.6-0.8v-1.9c0-0.5,0.2-0.8,0.6-0.8c0.4,0,0.6,0.2,0.6,0.8v0.2h0.7v-0.2
	C62.7,55.5,62.3,54.9,61.5,54.9z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M64.7,54.9c-0.9,0-1.3,0.5-1.3,1.4v1.8c0,0.9,0.4,1.4,1.3,1.4c0.9,0,1.3-0.5,1.3-1.4v-1.8
	C66,55.4,65.5,54.9,64.7,54.9z M65.3,58.2c0,0.5-0.2,0.8-0.7,0.8S64,58.7,64,58.2v-1.9c0-0.5,0.2-0.8,0.7-0.8c0.4,0,0.7,0.2,0.7,0.8
	V58.2z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M68.6,58c0,0.7-0.3,1-0.7,1c-0.4,0-0.5-0.2-0.5-0.8V55h-0.7v3.3c0,0.9,0.3,1.3,1,1.3c0.5,0,0.8-0.2,1-0.7v0.6
	h0.6V55h-0.7L68.6,58L68.6,58z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M71.7,54.9c-0.5,0-0.8,0.2-1,0.7V55h-0.6v4.5h0.7v-3c0-0.7,0.3-1,0.7-1c0.4,0,0.5,0.2,0.5,0.8v3.2h0.7v-3.3
	C72.7,55.4,72.4,54.9,71.7,54.9z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M74.5,53.7h-0.6L73.8,55h-0.6v0.6h0.5v3c0,0.7,0.3,1,1,1c0.2,0,0.4,0,0.5-0.1v-0.6c-0.1,0-0.2,0.1-0.4,0.1
	c-0.3,0-0.4-0.1-0.4-0.5v-2.9h0.8V55h-0.8V53.7z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M78.3,56.3c0-0.9-0.4-1.3-1.2-1.3c-0.8,0-1.3,0.5-1.3,1.3v0.1h0.6v-0.1c0-0.4,0.2-0.7,0.6-0.7
	c0.4,0,0.6,0.2,0.6,0.7v0.1c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.2-0.7,0.4c-0.8,0.4-1.1,0.8-1.1,1.5c0,0.7,0.4,1.1,1,1.1
	c0.5,0,0.9-0.2,1-0.7c0,0.3,0,0.5,0.1,0.6h0.6c-0.1-0.3-0.1-0.7-0.1-1.2V56.3z M77.7,58c0,0.7-0.3,1-0.7,1c-0.4,0-0.5-0.2-0.5-0.6
	c0-0.4,0.2-0.7,0.6-0.9c0.4-0.2,0.6-0.3,0.7-0.4V58z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M80.8,54.9c-0.4,0-0.7,0.2-0.9,0.5v-2.2h-0.7v6.2h0.5l0.1-0.6c0.2,0.5,0.5,0.7,1,0.7c0.7,0,1-0.4,1-1.3v-2.1
	C81.8,55.4,81.4,54.9,80.8,54.9z M81.1,58.2c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.7-0.3-0.7-1v-1.5c0-0.7,0.3-1,0.7-1
	c0.4,0,0.6,0.2,0.6,0.8V58.2z"
    />
    <path style={{ fill: '#FFFFFF' }} d="M83.2,53.4h-0.7v1h0.7V53.4z" />
    <path style={{ fill: '#FFFFFF' }} d="M83.2,55h-0.7v4.5h0.7V55z" />
    <path style={{ fill: '#FFFFFF' }} d="M84.7,53.3h-0.7v6.2h0.7V53.3z" />
    <path style={{ fill: '#FFFFFF' }} d="M86.2,53.4h-0.7v1h0.7V53.4z" />
    <path style={{ fill: '#FFFFFF' }} d="M86.2,55h-0.7v4.5h0.7V55z" />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M88.1,53.7h-0.6L87.4,55h-0.6v0.6h0.5v3c0,0.7,0.3,1,1,1c0.2,0,0.4,0,0.5-0.1v-0.6c-0.1,0-0.2,0.1-0.4,0.1
	c-0.3,0-0.4-0.1-0.4-0.5v-2.9h0.8V55H88L88.1,53.7L88.1,53.7z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M91.4,55l-0.7,3.4L89.9,55h-0.7l1.1,4.5l-0.1,0.3c-0.1,0.3-0.2,0.4-0.5,0.4c-0.1,0-0.2,0-0.3-0.1v0.6
	c0.1,0.1,0.2,0.1,0.4,0.1c0.6,0,0.9-0.3,1.1-1l0.2-0.8L92,55L91.4,55L91.4,55z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M1.5,53.1c-1,0-1.5,0.6-1.5,1.6v3.4c0,1,0.5,1.6,1.5,1.6s1.5-0.6,1.5-1.6v-1H2.1v1c0,0.4-0.2,0.7-0.6,0.7
	S1,58.6,1,58.1v-3.5c0-0.4,0.2-0.7,0.6-0.7s0.6,0.2,0.6,0.7v0.8h0.9v-0.8C3.1,53.6,2.5,53.1,1.5,53.1z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M1.5,59.8c-1,0-1.5-0.6-1.5-1.6v-3.4c0-1,0.6-1.6,1.5-1.6c1,0,1.5,0.6,1.5,1.6v0.8h-1v-0.9
	C2.1,54.4,2,54,1.5,54S1,54.4,1,54.6v3.5c0,0.3,0.1,0.6,0.5,0.6s0.5-0.3,0.5-0.6v-1.1h1v1C3.1,59.1,2.5,59.8,1.5,59.8z M1.5,53.1
	c-0.9,0-1.4,0.6-1.4,1.5v3.4c0,1,0.5,1.5,1.4,1.5C2.5,59.6,3,59,3,58.1v-0.9H2.2v1c0,0.4-0.2,0.7-0.6,0.7s-0.6-0.3-0.6-0.7v-3.5
	c0-0.4,0.2-0.7,0.6-0.7s0.6,0.3,0.6,0.7v0.8H3v-0.7C3,53.7,2.5,53.1,1.5,53.1z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M35.2,56c-0.3-0.3-0.6-0.5-0.8-0.8c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.5,0.2-0.7,0.6-0.7c0.2,0,0.6,0.1,0.6,0.7V55
	h0.9v-0.4c0-1-0.5-1.6-1.5-1.6c-1,0-1.5,0.6-1.5,1.6c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.6,0.9,1c0.3,0.3,0.6,0.5,0.7,0.8
	c0.1,0.2,0.2,0.4,0.2,0.7c0,0.5-0.2,0.7-0.6,0.7c-0.4,0-0.6-0.2-0.6-0.7v-0.5h-0.9v0.5c0,1,0.5,1.6,1.5,1.6c1,0,1.5-0.6,1.5-1.6
	c0-0.4-0.1-0.8-0.3-1.1C35.9,56.7,35.6,56.4,35.2,56z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M34.8,59.8c-1,0-1.5-0.6-1.5-1.7v-0.5h1v0.6c0,0.4,0.2,0.7,0.5,0.7s0.5-0.2,0.5-0.7c0-0.2-0.1-0.4-0.2-0.7
	c-0.1-0.2-0.3-0.4-0.7-0.7c-0.4-0.3-0.8-0.7-0.9-1c-0.2-0.3-0.3-0.7-0.3-1.1c0-1,0.6-1.6,1.6-1.6c1,0,1.5,0.6,1.5,1.7v0.4h-1v-0.5
	c0-0.6-0.3-0.7-0.5-0.7c-0.4,0-0.5,0.2-0.5,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.4,0.4,0.7,0.7c0.4,0.4,0.8,0.7,0.9,1
	c0.2,0.3,0.3,0.7,0.3,1.1C36.4,59.1,35.8,59.8,34.8,59.8z M33.4,57.6V58c0,1,0.5,1.6,1.4,1.6c0.9,0,1.5-0.6,1.5-1.6
	c0-0.4-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.6-0.9-1c-0.4-0.3-0.6-0.5-0.8-0.8c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.5,0.2-0.7,0.6-0.7
	c0.4,0,0.6,0.2,0.6,0.8V55h0.8v-0.3c0-1-0.5-1.6-1.4-1.6c-0.9,0-1.5,0.6-1.5,1.5c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.6,0.9,1
	c0.4,0.3,0.6,0.5,0.7,0.8c0.1,0.2,0.2,0.4,0.2,0.7c0,0.5-0.2,0.8-0.7,0.8c-0.4,0-0.7-0.2-0.7-0.8v-0.5H33.4z"
    />
    <path style={{ fill: '#FFFFFF' }} d="M53.8,53.1l-1.2,6.5h0.9l0.2-1.3H55l0.2,1.3h1L55,53.1H53.8z M53.9,57.4l0.4-2.6l0.5,2.6H53.9z" />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M56.3,59.7h-1.1l-0.2-1.3h-1.1l-0.2,1.3h-1v-0.1l1.2-6.5H55L56.3,59.7z M55.3,59.5h0.9l-1.2-6.4h-1.1l-1.2,6.4
	h0.8l0.2-1.3H55L55.3,59.5z M54.9,57.5h-1l0.5-3L54.9,57.5z M54,57.4h0.8l-0.4-2.3L54,57.4z"
    />
  </svg>
);
