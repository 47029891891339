import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICrash } from 'app/shared/model/crash.model';
import { getEntities } from './crash.reducer';

export const Crash = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const crashList = useAppSelector(state => state.crash.entities);
  const loading = useAppSelector(state => state.crash.loading);
  const totalItems = useAppSelector(state => state.crash.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="crash-heading" data-cy="CrashHeading">
        <Translate contentKey="asfaleiaApp.crash.home.title">Crashes</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="asfaleiaApp.crash.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/crash/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="asfaleiaApp.crash.home.createLabel">Create new Crash</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {crashList && crashList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="asfaleiaApp.crash.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dotNumber')}>
                  <Translate contentKey="asfaleiaApp.crash.dotNumber">Dot Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hazmatReleased')}>
                  <Translate contentKey="asfaleiaApp.crash.hazmatReleased">Hazmat Released</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reportNumber')}>
                  <Translate contentKey="asfaleiaApp.crash.reportNumber">Report Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleLicenseSate')}>
                  <Translate contentKey="asfaleiaApp.crash.vehicleLicenseSate">Vehicle License Sate</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleIdNumber')}>
                  <Translate contentKey="asfaleiaApp.crash.vehicleIdNumber">Vehicle Id Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('notPreventable')}>
                  <Translate contentKey="asfaleiaApp.crash.notPreventable">Not Preventable</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lightConditionDesc')}>
                  <Translate contentKey="asfaleiaApp.crash.lightConditionDesc">Light Condition Desc</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('timeWeight')}>
                  <Translate contentKey="asfaleiaApp.crash.timeWeight">Time Weight</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('severityWeight')}>
                  <Translate contentKey="asfaleiaApp.crash.severityWeight">Severity Weight</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('towAway')}>
                  <Translate contentKey="asfaleiaApp.crash.towAway">Tow Away</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reportDate')}>
                  <Translate contentKey="asfaleiaApp.crash.reportDate">Report Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('roadSurfaceConditionDesc')}>
                  <Translate contentKey="asfaleiaApp.crash.roadSurfaceConditionDesc">Road Surface Condition Desc</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reportState')}>
                  <Translate contentKey="asfaleiaApp.crash.reportState">Report State</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('weatherConditionDesc')}>
                  <Translate contentKey="asfaleiaApp.crash.weatherConditionDesc">Weather Condition Desc</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fatalities')}>
                  <Translate contentKey="asfaleiaApp.crash.fatalities">Fatalities</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleLicenseNumber')}>
                  <Translate contentKey="asfaleiaApp.crash.vehicleLicenseNumber">Vehicle License Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('severityTimeWeight')}>
                  <Translate contentKey="asfaleiaApp.crash.severityTimeWeight">Severity Time Weight</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('citationIssuedDesc')}>
                  <Translate contentKey="asfaleiaApp.crash.citationIssuedDesc">Citation Issued Desc</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('injuries')}>
                  <Translate contentKey="asfaleiaApp.crash.injuries">Injuries</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('accessControlDesc')}>
                  <Translate contentKey="asfaleiaApp.crash.accessControlDesc">Access Control Desc</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('seqNum')}>
                  <Translate contentKey="asfaleiaApp.crash.seqNum">Seq Num</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('trafficwayDesc')}>
                  <Translate contentKey="asfaleiaApp.crash.trafficwayDesc">Trafficway Desc</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="asfaleiaApp.crash.driver">Driver</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="asfaleiaApp.crash.vehicle">Vehicle</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="asfaleiaApp.crash.carrier">Carrier</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {crashList.map((crash, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/crash/${crash.id}`} color="link" size="sm">
                      {crash.id}
                    </Button>
                  </td>
                  <td>{crash.dotNumber}</td>
                  <td>{crash.hazmatReleased}</td>
                  <td>{crash.reportNumber}</td>
                  <td>{crash.vehicleLicenseSate}</td>
                  <td>{crash.vehicleIdNumber}</td>
                  <td>{crash.notPreventable}</td>
                  <td>{crash.lightConditionDesc}</td>
                  <td>{crash.timeWeight}</td>
                  <td>{crash.severityWeight}</td>
                  <td>{crash.towAway}</td>
                  <td>{crash.reportDate}</td>
                  <td>{crash.roadSurfaceConditionDesc}</td>
                  <td>{crash.reportState}</td>
                  <td>{crash.weatherConditionDesc}</td>
                  <td>{crash.fatalities}</td>
                  <td>{crash.vehicleLicenseNumber}</td>
                  <td>{crash.severityTimeWeight}</td>
                  <td>{crash.citationIssuedDesc}</td>
                  <td>{crash.injuries}</td>
                  <td>{crash.accessControlDesc}</td>
                  <td>{crash.seqNum}</td>
                  <td>{crash.trafficwayDesc}</td>
                  <td>{crash.driver ? <Link to={`/driver/${crash.driver.id}`}>{crash.driver.id}</Link> : ''}</td>
                  <td>{crash.vehicle ? <Link to={`/vehicle/${crash.vehicle.id}`}>{crash.vehicle.id}</Link> : ''}</td>
                  <td>{crash.carrier ? <Link to={`/carrier/${crash.carrier.id}`}>{crash.carrier.dotNumber}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/crash/${crash.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/crash/${crash.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/crash/${crash.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="asfaleiaApp.crash.home.notFound">No Crashes found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={crashList && crashList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Crash;
